<template>
  <div class="form_element" :class="'div_' + id">
    <label v-if="label" :for="name" class="form-label">
      {{ label }}
      <a
        tabindex="-1"
        v-if="textoAyuda != ''"
        :id="'tooltip_' + id"
        href="#"
        @click.prevent
        data-bs-toggle="tooltip"
        :title="textoAyuda"
      >
        <i class="bi bi-info-square"></i>
      </a>
    </label>

    <datepicker
      v-if="type == 'date'"
      :id="id"
      :name="name"
      :locale="datepickerEsLocale"
      :modelValue="value"
      @update:modelValue="setDateValue"
      inputFormat="dd/MM/yyyy"
      class="form-control"
      :class="{
        'is-invalid': !!errorMessage,
        'is-valid': meta.valid && inputValue != '',
      }"
      @input="handleChange"
      @blur="handleBlur"
    />

    <input
      v-if="type != 'select' && type != 'date'"
      :name="name"
      :id="name"
      :type="type"
      :disabled="disabled"
      :autocomplete="autocompleteCalc"
      :placeholder="placeholder"
      class="form-control"
      :class="{
        'is-invalid': !!errorMessage,
        'is-valid': meta.valid && inputValue != '',
      }"
      @input="handleChange"
      @blur="handleBlur"
      v-model="model"
    />

    <div :id="id" v-if="type == 'select'">
      <vue-select
        :id="id"
        :options="options"
        label-by="label"
        value-by="value"
        :model-value="value || filtered"
        :placeholder="placeholder != '' ? placeholder : 'Seleccionar...'"
        search-placeholder="Buscar..."
        :searchable="options.length > 5"
        :clear-on-select="false"
        :clear-on-close="true"
        :close-on-select="true"
        @blur="handleBlur"
        @selected="emitInput"
        @input="setValue"
      ></vue-select>
    </div>

    <div class="message_container" v-show="!hideValidationMessage">
      <p
        :class="{
          'is-invalid': !!errorMessage,
          'is-valid': meta.valid && inputValue != '',
        }"
        class="help-message"
        v-show="errorMessage || meta.valid"
      >
        {{ errorMessage || successMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";

import { useField } from "vee-validate";
import { createTooltip } from "@/lib/bootstrap-fx";

import VueNextSelect from "vue-next-select";
import "vue-next-select/dist/index.min.css";

import Settings from "@/settings";
// import Fx from "@/lib/functions";

import Datepicker from "vue3-datepicker";
import { es as datepickerEsLocale } from "date-fns/locale";

export default {
  name: "FormElement",

  emits: ["selectValueChanged", "update:modelValue"],

  components: {
    "vue-select": VueNextSelect,
    Datepicker,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
      default: " ",
    },
    textoAyuda: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    hideValidationMessage: {
      type: Boolean,
      default: false,
    },
    arrOptions: {
      type: Array,
      default: () => [
        {
          label: "---",
          value: "",
        },
      ],
    },
  },
  setup(props, { emit }) {
    const model = computed({
      get: () => {
        return props.modelValue;
      },
      set: (newValue) => {
        emit("update:modelValue", newValue);
      },
    });

    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: model,
    });

    let filtered = ref(props.type == "select" ? 0 : "");

    // específico de select
    let options =
      props.arrOptions[0].value != undefined
        ? props.arrOptions
        : [Settings.EMPTY_SELECT_OBJ];

    if (props.type == "select") {
      let filteredObj = options.find((item) => {
        if (item == undefined || item.value == undefined) {
          return false;
        }

        if (typeof item.value == "string") {
          return item.value == item.value.toString();
        }

        // return item.value == initVal.value;
        return props.value;
      });

      filtered.value =
        filteredObj != undefined
          ? filteredObj.value
          : Settings.EMPTY_SELECT_OBJ.value;
    }

    function emitInput(value) {
      emit("selectValueChanged", {
        id: props.id,
        value: value.value,
      });

      handleChange();
    }

    function setValue(val) {
      if (val && val.target) {
        // al escribir
        return true;
      }

      filtered.value = val;
      emit("update:modelValue", val);
    }

    function setDateValue(val) {
      emit("update:modelValue", val);
    }

    function getAutocomplete() {
      if (props.autocomplete && props.autocomplete != "off") {
        return props.autocomplete;
      }

      if (props.type == "password") {
        return "current-password";
      }

      // prevent Chrome autocomplete bug
      var isChrome =
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor);

      if (isChrome) {
        return props.id || "offff";
      }

      return "off";
    }

    onMounted(() => {
      if (props.textoAyuda != "") {
        createTooltip(props.id);
      }
    });

    const autocompleteCalc = getAutocomplete();

    return {
      filtered,
      options,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      emitInput,
      model,
      setValue,
      setDateValue,
      datepickerEsLocale,
      autocompleteCalc,
    };
  },
};
</script>

<style>
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(25, 135, 84)' class='bi bi-check-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z'/%3E%3C/svg%3E");
  border-color: #eee;
}

.form_element .v3dp__datepicker input {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(19,194,194)' class='bi bi-calendar3' viewBox='0 0 16 16'%3E%3Cpath d='M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z'/%3E%3Cpath d='M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z'/%3E%3C/svg%3E");
}

.form_element {
  width: 100%;
  padding-bottom: 0px;
}

.form_element label {
  text-align: left;
  width: 100%;
  margin-bottom: 5px;
}

.form_element label a {
  text-align: right;
  float: right;
  margin: 0 5px 0 0;
  color: var(--tunuve);
}

.form_element input {
  padding: 12px 30px;
  border-radius: 0;
  width: 100%;
  background-color: #fff !important;
}

.form_element p {
  text-align: right;
  width: 100%;
  padding: 2px 5px;
  font-size: 10px;
}

.form_element p.help-message.is-valid {
  color: var(--alert-success);
}

.form_element p.help-message.is-invalid {
  color: var(--alert-danger-strong);
}

.form_element .vs__search::placeholder,
.form_element .vs__dropdown-toggle,
.form_element .vs__dropdown-menu {
  background: var(--tunuve);
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.form_element .vs__clear,
.form_element .vs__open-indicator {
  fill: var(--tunuve) !important;
}

.message_container {
  min-height: 30px;
}
</style>
