import Settings from "@/settings";
import Fx from "@/lib/functions";

import { arrPoblaciones } from "@/data/poblaciones";
import { arrProvincias } from "@/data/provincias";

const provincia_txt = function (id_provincia) {
  let matchObj = arrProvincias.find((item) => item.value == id_provincia);

  if (typeof matchObj != "object" || !matchObj.hasOwnProperty("value")) {
    return "";
  }

  return matchObj.value;
};

const id_provincia_cp = function (cp) {
  if (cp == undefined) {
    return 0;
  }

  if (cp.length == 4) {
    cp = "0" + cp;
  }

  if (cp.length != 5) {
    return 0;
  }

  let matchObj = arrProvincias.find(
    (item) => item.value == parseInt(cp.substr(0, 2))
  );

  if (Fx.is_object(matchObj) && matchObj.value) {
    return matchObj.value;
  }

  return 0;
};

const id_poblacion = function (poblacion) {
  if (poblacion == undefined) {
    return 0;
  }

  const findObj = arrPoblaciones.find(
    (item) => item.label.toLowerCase() == poblacion.toLowerCase()
  );

  if (findObj == null || !Fx.is_object(findObj)) {
    return 0;
  }

  return parseInt(findObj.value);
};

const id_provincia = function (provincia = "", cp = "") {
  if (provincia == "" && cp == "") {
    // console.log("No existe provincia ni cp");
    return 0;
  }

  if (provincia != "") {
    const findObj = arrProvincias.find(
      (item) => item.value != undefined && item.value == provincia
    );

    if (
      findObj == null ||
      !Fx.is_object(findObj) ||
      findObj.value == undefined
    ) {
      return 0;
    }

    return parseInt(findObj.value);
  }

  if (cp.length != "") {
    return id_provincia_cp(cp);
  }

  return 0;
};

function ruta_archivo(nombre_archivo) {
  if (nombre_archivo == null || nombre_archivo == "") {
    return "";
  }

  return (
    Settings.BASE_API.replace("/v2", "") + "uploads/logos/" + nombre_archivo
  );
}

function setDisplayVideoaskAntonio(displayValue) {
  var button = document.querySelector(
    'button[class^="videoask-embed__button"]'
  );

  if (button) {
    button.style.display = displayValue;
  }
}

export {
  id_poblacion,
  id_provincia,
  ruta_archivo,
  id_provincia_cp,
  provincia_txt,
  setDisplayVideoaskAntonio,
};
