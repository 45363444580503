const arrIRPF = [
  {
    label: "0%",
    value: 0,
  },
  {
    label: "1.5%",
    value: 1.5,
  },
  {
    label: "7%",
    value: 7,
  },
  {
    label: "15%",
    value: 15,
  },
  {
    label: "19%",
    value: 19,
  },
];

export { arrIRPF };
