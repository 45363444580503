<template>
  <section id="section_datatables">
    <div class="row">
      <div class="col-12">
        <div v-if="loading">
          <Spinner />
        </div>

        <div v-show="!loading" id="datatables-container">
          <table
            @click="emitClick"
            @reloadTable="reloadTable"
            id="datatables"
            class="dataTable table table-striped display"
            width="100%"
          ></table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, onUnmounted, watch } from "vue";
import { useRouter } from "vue-router";

import $ from "jquery";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5";

import Spinner from "@/components/Spinner.vue";

export default {
  name: "DatatablesVue3",

  components: {
    spinner: Spinner,
  },

  props: {
    options: {
      type: Object,
      default() {
        return {
          responsive: true,
          sPaginationType: "full_numbers",
          order: [], // Previene sorting inicial
          bDestroy: true,
          lengthMenu: [
            [10, 25, 50, 100, -1],
            [10, 25, 50, 100, "Todas"],
          ],
          pageLength: 10,

          // stateSave: true
        };
      },
    },
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const router = useRouter();

    let table = {};

    let objInit = {
      options: props.options,
      columns: props.columns,
      data: props.data,
      oLanguage: {
        sProcessing: "Procesando...",
        sLengthMenu: "Mostrar _MENU_ registros",
        sZeroRecords: "No se encontraron resultados",
        sEmptyTable: "Ningún dato disponible en esta tabla",
        sInfo:
          "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        sInfoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
        sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
        sInfoPostFix: "",
        sSearch: "Buscar:",
        sUrl: "",
        sInfoThousands: ",",
        sLoadingRecords: "Cargando...",
        oPaginate: {
          sFirst: "Primero",
          sLast: "Último",
          sNext: "Siguiente",
          sPrevious: "Anterior",
        },
        oAria: {
          sSortAscending:
            ": Activar para ordenar la columna de manera ascendente",
          sSortDescending:
            ": Activar para ordenar la columna de manera descendente",
        },
        buttons: {
          copy: "Copiar",
          colvis: "Visibilidad",
        },
      },
    };

    function emitClick(e) {
      e.preventDefault();

      let href = e.target.attributes["href"];

      if (!href) {
        return false;
      }

      let path = href.nodeValue || "";

      if (path != "") {
        path = path.replace(process.env.VUE_APP_BASE_APP, "/");
        router.push({ path });
      }
    }

    onMounted(() => {
      drawTable();
    });

    onUnmounted(() => {
      table.destroy();
    });

    function drawTable() {
      addPlugins();
      table = $("table#datatables").DataTable(objInit).columns.adjust();
    }

    function reloadTable(data) {
      objInit.data = data;
      drawTable();
    }

    function addPlugins() {
      $.extend($.fn.dataTableExt.oSort, {
        "numeric-comma-pre": function (a) {
          a = a.replace("€", "");
          a = a.replace(".", "");
          a = a.replace(".", "");

          var x = a == "-" ? 0 : a.replace(/,/, ".");
          return parseFloat(x);
        },

        "numeric-comma-asc": function (a, b) {
          return a < b ? -1 : a > b ? 1 : 0;
        },

        "numeric-comma-desc": function (a, b) {
          a = a.replace("€", "");

          return a < b ? 1 : a > b ? -1 : 0;
        },
      });
    } // fin addPlugins

    watch(
      () => props.data,
      (newData) => {
        table.destroy();
        reloadTable(newData);
      }
    );

    watch(
      () => props.loading,
      () => {
        table.destroy();
        reloadTable(props.data);
      }
    );

    return {
      reloadTable,
      emitClick,
    };
  },
};
</script>
