<template>
  <Mensaje msg="Has cerrado tu sesión" image="not_found.png">
    <p>Tu sesión ha sido cerrada.</p>

    <p>
      <router-link class="btn btn-primary" to="/">
        <i class="bi bi-house-door"></i>
        Ir a inicio
      </router-link>
      &nbsp;

      <a class="btn btn-secondary" @click.prevent="abrirAcceso" href="#">
        <i class="bi bi-lock"></i>
        Acceder
      </a>
    </p>
  </Mensaje>
</template>

<script>
import Mensaje from "@/components/Mensaje.vue";

import Fx from "@/lib/functions";

export default {
  name: "Logout",
  components: {
    Mensaje,
  },

  setup() {
    function abrirAcceso() {
      Fx.simulateClick("#btn_acceso");
    }

    return {
      abrirAcceso,
    };
  },
};
</script>
