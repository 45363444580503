import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import { store } from "./store/store";

import { makeServer } from "./server/server";

import Settings from "./settings";

if (process.env.NODE_ENV === "development" && Settings.USE_MIRAGEJS === true) {
  makeServer();
}

const app = createApp(App);

app.use(router).use(store).mount("#app");

app.config.warnHandler = function () {
  return null;
};
