<template>
  <div class="main">
    <section-header
      title="Modificar mis datos"
      icon="person-badge"
      link_to="/"
      link_text="Ir a inicio"
    />

    <div class="container view_container cuerpo_factura">
      <div v-if="loading">
        <Spinner />
      </div>

      <div v-if="formData.nif == '' || formData.nif == null" class="row">
        <div class="col-12 mb-3">
          <BSAlert class="mt-3" bsClass="warning" icon="exclamation-diamond">
            Para poder generar facturas, es necesario
            <b>rellenar estos datos</b>.
          </BSAlert>
        </div>
      </div>

      <div class="row">

        <div class="col-12 col-md-6 form_empresa">
          <BSAlert
            :hide="!serverError || loading"
            bsClass="danger"
            icon="exclamation-diamond"
          >
            Error en el servidor. {{ serverErrorTxt }}
          </BSAlert>

          <EmpresaForm
            v-if="!loading && !serverError"
            :formSchema="formSchema"
            :formData="formData"
            @formSubmitted="saveFormData"
            :saveButtonLoading="saveButtonLoading"
          />

          <BSAlert
            class="mt-3"
            :hide="serverError || loading || !dataSaved"
            bsClass="success"
            icon="check-circle"
          >
            Los datos se guardaron con éxito.
          </BSAlert>
        </div>

        <div class="col-12 col-md-6 logo_empresa">
          <LogoEmpresa />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import { setLocale, object, string } from "yup";
import es from "yup-es";

import SectionHeader from "@/components/SectionHeader.vue";
import Spinner from "@/components/Spinner.vue";
import BSAlert from "@/components/BSAlert.vue";

import Settings from "@/settings";
import Fx from "@/lib/functions";
import { id_poblacion, id_provincia } from "@/lib/tunuveFx";

import EmpresaForm from "@/views/empresas/EmpresaForm.vue";
import LogoEmpresa from "@/views/empresas/LogoEmpresa.vue";

export default {
  name: "Empresa",

  components: {
    Spinner,
    BSAlert,
    EmpresaForm,
    SectionHeader,
    LogoEmpresa,
  },

  setup() {
    const store = useStore();

    const loading = ref(true),
      serverError = ref(false),
      serverErrorTxt = ref(""),
      saveButtonLoading = ref(false),
      dataSaved = ref(false);

    const formData = {
      nombre: "",
      nif: "",
      direccion: "",
      id_poblacion: "",
      id_provincia: "0",
      cp: "",
      email: "",
      telefono: "",
      id_empresa: store.state.id_empresa,
    };

    // Using yup to generate a validation schema
    // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
    setLocale(es);

    const formSchema = object().shape({
      nombre: string().min(3).required(),
      nif: string().min(6).required(),
      direccion: string().min(6).required(),
      cp: string().length(5).required(),
      email: string().min(6),
      telefono: string().min(6),
    });

    async function loadData() {
      loading.value = true;
      setError(false);

      const url = Settings.BASE_API + "empresas/",
        params = {
          hash: store.state.token,
          accion: "consultar_empresas",
          id_empresa: store.state.id_empresa,
        };

      const data = await Fx.get(url, params, Fx.callback);

      resolveLoadData(data);
    }

    function setError(value, error = "") {
      serverError.value = value;
      serverErrorTxt.value = error;
    }

    function resolveLoadData(data) {
      loading.value = false;

      if (data.status == "ok") {
        let objEmpresa =
          Array.isArray(data.data) && data.data.length == 1
            ? data.data[0]
            : data.data;

        console.log(objEmpresa, "objEmpresa");
        formData.nombre = objEmpresa.nombre;
        formData.nif = objEmpresa.nif;
        formData.direccion = objEmpresa.direccion;
        formData.cp = objEmpresa.cp;
        formData.email = objEmpresa.email;
        formData.telefono = objEmpresa.telefono;

        formData.id_poblacion = id_poblacion(objEmpresa.poblacion);
        formData.id_provincia = id_provincia(
          objEmpresa.provincia,
          objEmpresa.cp
        );
      } else {
        setError(true, data.error);
      }
    }

    async function saveFormData(values) {
      saveButtonLoading.value = true;
      dataSaved.value = false;

      setError(false);

      let objEmpresa = {
        ...values,
        id_empresa: store.state.id_empresa,
      };

      objEmpresa.id_poblacion = id_poblacion(objEmpresa.poblacion);
      objEmpresa.id_provincia = id_provincia(
        objEmpresa.provincia,
        objEmpresa.cp
      );
      objEmpresa.provincia = objEmpresa.id_provincia;
      objEmpresa.telefono1 = objEmpresa.telefono;

      const url = Settings.BASE_API + "empresas/",
        params = {
          hash: store.state.token,
          accion: "guardar",
          id_empresa: store.state.id_empresa,
          obj_configuracion: objEmpresa,
        };

      const data = await Fx.put(url, params, Fx.callback);

      resolveSaveData(data);
    }

    function resolveSaveData(data) {
      if (data.status != "ok") {
        setError(true, data.error);
      }

      saveButtonLoading.value = false;
      dataSaved.value = true;
    }

    onMounted(() => {
      loadData();
    });

    return {
      formData,
      saveFormData,
      formSchema,
      loading,
      serverError,
      serverErrorTxt,
      saveButtonLoading,
      dataSaved,
    };
  },
};
</script>
