<template>
  <form @submit.prevent="onSubmit" novalidate>
    <FormElement
      id="nombre"
      name="nombre"
      type="text"
      label="Nombre o razón social"
      placeholder="Nombre"
      success-message=""
      v-model="data.nombre"
    />
    <FormElement
      id="nif"
      name="nif"
      type="text"
      label="NIF"
      placeholder="Introduzca el NIF"
      success-message=""
      v-model="data.nif"
    />
    <FormElement
      id="direccion"
      name="direccion"
      type="text"
      label="Dirección"
      placeholder="Introduzca la dirección"
      success-message=""
      v-model="data.direccion"
    />

    <FormElement
      id="cp"
      name="cp"
      type="text"
      label="Código Postal"
      placeholder="Introduce el código postal del cliente"
      v-model="data.cp"
    />

    <FormElement
      type="select"
      id="id_provincia"
      name="id_provincia"
      label="Provincia"
      :arrOptions="arrProvincias"
      v-model="data.id_provincia"
      :value="data.id_provincia"
      :multiple="false"
      @selectValueChanged="selectValueChanged"
      @update:modelValue="selectValueChanged"
    />

    <FormElement
      :key="poblacionesKey"
      type="select"
      id="id_poblacion"
      name="id_poblacion"
      label="Población"
      :arrOptions="arrPoblacionesFiltered"
      v-model="data.id_poblacion"
      :value="data.id_poblacion"
      :multiple="false"
      @selectValueChanged="selectValueChanged"
      @update:modelValue="selectValueChanged"
    />

    <FormButton
      clases="btn btn-primary submit-btn"
      icon="save"
      label="Guardar"
      @click="onSubmit"
      :disabled="!meta.valid"
      :loading="saveButtonLoading"
    />
  </form>
</template>

<script>
import { useForm } from "vee-validate";
import FormElement from "@/components/Forms/FormElement.vue";
import FormButton from "@/components/Forms/FormButton.vue";

import { ref, reactive } from "vue";

import { arrPoblaciones } from "@/data/poblaciones";
import { arrProvincias } from "@/data/provincias";

import Fx from "@/lib/functions";
import { id_provincia } from "@/lib/tunuveFx";

import { setLocale, object, string } from "yup";
import es from "yup-es";

export default {
  name: "ContactoForm",

  emits: ["formSubmitted"],

  components: {
    FormElement,
    FormButton,
  },

  props: {
    formData: {
      type: Object,
      required: true,
    },
    formSchema: {
      type: Object,
      required: true,
    },

    saveButtonLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const data = reactive(props.formData);

    // Using yup to generate a validation schema
    // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
    setLocale(es);

    let arrPoblacionesFiltered = ref(arrPoblaciones),
      poblacionesKey = ref("poblacionesKey");

    const formSchema = object().shape({
      nombre: string().min(3).required(),
      nif: string().min(6).required(),
      direccion: string().min(6).required(),
      cp: string().length(5).required(),
    });

    const { meta, values } = useForm({
      validateOnValueUpdate: true,
      initialValues: props.formData,
      validationSchema: formSchema,
    });

    function onSubmit() {
      let poblacion = Fx.getAttr("#id_poblacion input", "placeholder"),
        provincia = Fx.getAttr("#id_provincia input", "placeholder");

      Object.keys(data).map((id) => (values[id] = data[id]));

      values.poblacion = poblacion || "";
      values.provincia =
        data.id_provincia || id_provincia(provincia, values.cp);

      emit("formSubmitted", values);
    }

    function actualizarPoblaciones(objValue) {
      arrPoblacionesFiltered.value = arrPoblaciones.filter(
        (item) => item.id_provincia == objValue.value || item.value == 0
      );

      data.id_poblacion = 0;

      poblacionesKey.value = "poblacionesKey" + Fx.getTime();
    }

    function selectValueChanged(objValue) {
      let { id, value } = objValue;

      if (typeof value == "object" && value.value) {
        value = value.value;
      }

      if (data[id] !== undefined) {
        data[id] = value;
      }

      if (id == "id_provincia") {
        actualizarPoblaciones(objValue);
      }
    }

    return {
      data,
      arrPoblacionesFiltered,
      arrProvincias,
      meta,
      onSubmit,
      actualizarPoblaciones,
      poblacionesKey,
      selectValueChanged,
    };
  },
};
</script>
