export default [
  {
    activo: "1",
    agricola: "0",
    arr_etiquetas: [
      {
        etiqueta: "hosting",
        f_alta_autom: "2021-02-02 17:30:32",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "1",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmobiliaria",
        f_alta_autom: "2021-02-02 17:30:32",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "2",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "tienda online",
        f_alta_autom: "2021-02-02 17:31:24",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "3",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "hosting",
        f_alta_autom: "2021-02-02 17:46:33",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "4",
        id_tipo_etiqueta: "1",
      },
      {
        etiqueta: "mensual",
        f_alta_autom: "2021-02-02 17:46:33",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "5",
        id_tipo_etiqueta: "1",
      },
      {
        etiqueta: "anual",
        f_alta_autom: "2021-02-02 17:47:24",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "6",
        id_tipo_etiqueta: "1",
      },
      {
        etiqueta: "gasolina",
        f_alta_autom: "2021-02-02 17:49:34",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "7",
        id_tipo_etiqueta: "1",
      },
      {
        etiqueta: "licencias",
        f_alta_autom: "2021-02-02 17:50:42",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "8",
        id_tipo_etiqueta: "1",
      },
      {
        etiqueta: "trimestral",
        f_alta_autom: "2021-02-02 17:52:44",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "9",
        id_tipo_etiqueta: "1",
      },
      {
        etiqueta: "diario",
        f_alta_autom: "2021-02-02 17:59:43",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "11",
        id_tipo_etiqueta: "1",
      },
      {
        etiqueta: "catalua",
        f_alta_autom: "2021-02-03 11:34:26",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "89",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "catalua",
        f_alta_autom: "2021-02-03 11:36:39",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "90",
        id_tipo_etiqueta: "1",
      },
      {
        etiqueta: "ortopediaalcantarilla.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "364",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "investdenia.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "365",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Elke",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "366",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "pedroripolgroup.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "367",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "valadouroinmobiliaria.es",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "368",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "expansioncar.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "369",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "fincas-toscano.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "370",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Roberto",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "371",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "viviendasenasturias.es",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "372",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "solbisco.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "373",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "megaproperty.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "374",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Alexander",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "375",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "blaullar.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "376",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "ortegalaestaca.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "377",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Manuel Pena",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "378",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "deltaimmogestio.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "379",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Birgit",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "380",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Janine",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "381",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "prigar.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "382",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "okendo.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "383",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Ulrich",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "384",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Olga",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "385",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "monteagudoballesteros.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "386",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Hector",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "387",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Jr-inmobiliara.es",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "388",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "gabinetedegestiones.es",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "389",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "caletaproperty.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "390",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "elguardamuebles.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "391",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "fuerteventuradirect.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "392",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "madriddirect.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "393",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "grancanariadirect.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "394",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "housinginmobiliaria.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "395",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmobiliariatropico.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "396",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Yolanda",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "397",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmorv.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "398",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmoplayavictoria.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "399",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "iberfutur.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "400",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Salvador",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "401",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "iscorga.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "402",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "ortopedialamilagrosa.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "403",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmobiliariamondragon.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "404",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "cazasucasa.es",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "405",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Veronica",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "406",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Diego Gonzalez",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "407",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "casaberenguer.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "408",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmomarto.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "409",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Susana",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "410",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "credycasa.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "411",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Juan Evora",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "412",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "ipropietarios.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "413",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Esther Mateo",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "414",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "eigentum-mallorca.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "415",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Xisca",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "416",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Felipe",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "417",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "lacremedelacrepe.es",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "418",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "torreviejabeach.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "419",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "intermaresme.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "420",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Toni",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "421",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Antonio Domingo",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "422",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmoarquisur.es",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "423",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "almerimar",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "424",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmokitai.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "425",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "pisosentorrevieja.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "426",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "tucasaperfecta.es",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "427",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "cocumproperties.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "428",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Francisco de Loro",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "429",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "221bpalaustreet.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "430",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "servicoconstrucciones.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "431",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "magerithomes.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "432",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Fernando",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "433",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Mario",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "434",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Oscar",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "435",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "balonred",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "436",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "marbellimmo.es",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "437",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "jurfisa.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "438",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Miguel Angel Sorribas",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "439",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "spanishome.net",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "440",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "viviendasihg.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "441",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "martinblanch.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "442",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "rojasinmobiliaria.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "443",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Monica Rueda",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "444",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Sandra Rueda",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "445",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmomaer.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "446",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Marisol",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "447",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "silvia navarro",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "448",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "arquisur",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "449",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Maria Jose",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "450",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Antonio",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "451",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "blaullar",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "452",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "ferrisa",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "453",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmobiliariamodragon.com",
        f_alta_autom: "2021-02-03 16:35:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "454",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "ortopediaalcantarilla.com",
        f_alta_autom: "2021-02-03 18:41:30",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "618",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "investdenia.com",
        f_alta_autom: "2021-02-03 18:41:30",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "619",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Elke",
        f_alta_autom: "2021-02-03 18:41:30",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "620",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "pedroripolgroup.com",
        f_alta_autom: "2021-02-03 18:41:31",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "621",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "valadouroinmobiliaria.es",
        f_alta_autom: "2021-02-03 18:41:31",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "622",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "expansioncar.com",
        f_alta_autom: "2021-02-03 18:41:31",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "623",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "fincas-toscano.com",
        f_alta_autom: "2021-02-03 18:41:32",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "624",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Roberto",
        f_alta_autom: "2021-02-03 18:41:32",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "625",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "viviendasenasturias.es",
        f_alta_autom: "2021-02-03 18:41:32",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "626",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "solbisco.com",
        f_alta_autom: "2021-02-03 18:41:32",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "627",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "megaproperty.com",
        f_alta_autom: "2021-02-03 18:41:32",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "628",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Alexander",
        f_alta_autom: "2021-02-03 18:41:32",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "629",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "blaullar.com",
        f_alta_autom: "2021-02-03 18:41:33",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "630",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "ortegalaestaca.com",
        f_alta_autom: "2021-02-03 18:41:33",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "631",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Manuel Pena",
        f_alta_autom: "2021-02-03 18:41:33",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "632",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "deltaimmogestio.com",
        f_alta_autom: "2021-02-03 18:41:34",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "633",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Birgit",
        f_alta_autom: "2021-02-03 18:41:34",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "634",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Janine",
        f_alta_autom: "2021-02-03 18:41:34",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "635",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "prigar.com",
        f_alta_autom: "2021-02-03 18:41:35",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "636",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "okendo.com",
        f_alta_autom: "2021-02-03 18:41:35",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "637",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Ulrich",
        f_alta_autom: "2021-02-03 18:41:35",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "638",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Olga",
        f_alta_autom: "2021-02-03 18:41:35",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "639",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "monteagudoballesteros.com",
        f_alta_autom: "2021-02-03 18:41:36",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "640",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Hector",
        f_alta_autom: "2021-02-03 18:41:36",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "641",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Jr-inmobiliara.es",
        f_alta_autom: "2021-02-03 18:41:37",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "642",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "gabinetedegestiones.es",
        f_alta_autom: "2021-02-03 18:41:37",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "643",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "caletaproperty.com",
        f_alta_autom: "2021-02-03 18:41:37",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "644",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "elguardamuebles.com",
        f_alta_autom: "2021-02-03 18:41:37",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "645",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "fuerteventuradirect.com",
        f_alta_autom: "2021-02-03 18:41:37",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "646",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "madriddirect.com",
        f_alta_autom: "2021-02-03 18:41:37",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "647",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "grancanariadirect.com",
        f_alta_autom: "2021-02-03 18:41:37",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "648",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "housinginmobiliaria.com",
        f_alta_autom: "2021-02-03 18:41:39",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "649",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmobiliariatropico.com",
        f_alta_autom: "2021-02-03 18:41:39",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "650",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Yolanda",
        f_alta_autom: "2021-02-03 18:41:39",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "651",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmorv.com",
        f_alta_autom: "2021-02-03 18:41:39",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "652",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmoplayavictoria.com",
        f_alta_autom: "2021-02-03 18:41:40",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "653",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "iberfutur.com",
        f_alta_autom: "2021-02-03 18:41:40",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "654",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Salvador",
        f_alta_autom: "2021-02-03 18:41:40",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "655",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "iscorga.com",
        f_alta_autom: "2021-02-03 18:41:41",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "656",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "ortopedialamilagrosa.com",
        f_alta_autom: "2021-02-03 18:41:41",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "658",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmobiliariamondragon.com",
        f_alta_autom: "2021-02-03 18:41:41",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "659",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "cazasucasa.es",
        f_alta_autom: "2021-02-03 18:41:42",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "660",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Veronica",
        f_alta_autom: "2021-02-03 18:41:42",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "661",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Diego Gonzalez",
        f_alta_autom: "2021-02-03 18:41:42",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "662",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "casaberenguer.com",
        f_alta_autom: "2021-02-03 18:41:42",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "663",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmomarto.com",
        f_alta_autom: "2021-02-03 18:41:43",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "664",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Susana",
        f_alta_autom: "2021-02-03 18:41:43",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "665",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "credycasa.com",
        f_alta_autom: "2021-02-03 18:41:44",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "666",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Juan Evora",
        f_alta_autom: "2021-02-03 18:41:44",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "667",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "ipropietarios.com",
        f_alta_autom: "2021-02-03 18:41:44",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "668",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Esther Mateo",
        f_alta_autom: "2021-02-03 18:41:44",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "669",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "eigentum-mallorca.com",
        f_alta_autom: "2021-02-03 18:41:45",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "670",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Xisca",
        f_alta_autom: "2021-02-03 18:41:45",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "671",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Felipe",
        f_alta_autom: "2021-02-03 18:41:45",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "672",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "lacremedelacrepe.es",
        f_alta_autom: "2021-02-03 18:41:45",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "673",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "torreviejabeach.com",
        f_alta_autom: "2021-02-03 18:41:46",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "676",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmoarquisur.es",
        f_alta_autom: "2021-02-03 18:41:50",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "690",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "almerimar",
        f_alta_autom: "2021-02-03 18:41:50",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "691",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmokitai.com",
        f_alta_autom: "2021-02-03 18:41:51",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "693",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "pisosentorrevieja.com",
        f_alta_autom: "2021-02-03 18:41:51",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "694",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "tucasaperfecta.es",
        f_alta_autom: "2021-02-03 18:41:52",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "695",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "cocumproperties.com",
        f_alta_autom: "2021-02-03 18:41:52",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "696",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Francisco de Loro",
        f_alta_autom: "2021-02-03 18:41:52",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "697",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "221bpalaustreet.com",
        f_alta_autom: "2021-02-03 18:41:52",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "698",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "servicoconstrucciones.com",
        f_alta_autom: "2021-02-03 18:41:53",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "699",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "magerithomes.com",
        f_alta_autom: "2021-02-03 18:41:54",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "702",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Fernando",
        f_alta_autom: "2021-02-03 18:41:54",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "703",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Mario",
        f_alta_autom: "2021-02-03 18:41:54",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "704",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Oscar",
        f_alta_autom: "2021-02-03 18:41:54",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "705",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "balonred",
        f_alta_autom: "2021-02-03 18:41:55",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "706",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "marbellimmo.es",
        f_alta_autom: "2021-02-03 18:41:57",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "714",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "jurfisa.com",
        f_alta_autom: "2021-02-03 18:42:20",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "794",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Miguel Angel Sorribas",
        f_alta_autom: "2021-02-03 18:42:20",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "795",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "spanishome.net",
        f_alta_autom: "2021-02-03 18:42:22",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "800",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "viviendasihg.com",
        f_alta_autom: "2021-02-03 18:42:28",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "822",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "martinblanch.com",
        f_alta_autom: "2021-02-03 18:42:45",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "880",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "rojasinmobiliaria.com",
        f_alta_autom: "2021-02-03 18:42:49",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "896",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Monica Rueda",
        f_alta_autom: "2021-02-03 18:42:50",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "898",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Sandra Rueda",
        f_alta_autom: "2021-02-03 18:42:50",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "899",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmomaer.com",
        f_alta_autom: "2021-02-03 18:42:51",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "901",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Marisol",
        f_alta_autom: "2021-02-03 18:42:51",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "902",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "silvia navarro",
        f_alta_autom: "2021-02-03 18:43:00",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "932",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "arquisur",
        f_alta_autom: "2021-02-03 18:43:00",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "933",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Maria Jose",
        f_alta_autom: "2021-02-03 18:43:02",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "937",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Antonio",
        f_alta_autom: "2021-02-03 18:43:02",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "938",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "blaullar",
        f_alta_autom: "2021-02-03 18:44:51",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "1295",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "ferrisa",
        f_alta_autom: "2021-02-03 18:44:51",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "1296",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmobiliariamodragon.com",
        f_alta_autom: "2021-02-03 18:45:27",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "1420",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "inmobiliaria",
        f_alta_autom: "2021-02-10 11:13:00",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "1477",
        id_tipo_etiqueta: "1",
      },
      {
        etiqueta: "Barbara",
        f_alta_autom: "2021-02-15 11:10:17",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "1493",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Eden Jardin",
        f_alta_autom: "2021-02-15 11:10:17",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "1494",
        id_tipo_etiqueta: "0",
      },
      {
        etiqueta: "Licencias Zendesk",
        f_alta_autom: "2021-02-15 11:12:59",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "1495",
        id_tipo_etiqueta: "1",
      },
      {
        etiqueta: "recibo",
        f_alta_autom: "2021-02-26 15:57:29",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "1537",
        id_tipo_etiqueta: "1",
      },
      {
        etiqueta: "aaa",
        f_alta_autom: "2021-05-13 13:54:31",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "1674",
        id_tipo_etiqueta: "1",
      },
      {
        etiqueta: "Proyecto Zendesk",
        f_alta_autom: "2021-04-23 13:07:56",
        f_modificacion_autom: "0000-00-00 00:00:00",
        id_empresa: "69",
        id_empresa_etiqueta: "1700",
        id_tipo_etiqueta: "1",
      },
    ],
    arr_pf_cuentas_bancarias: [
      {
        account_id_afb: "-1",
        canal: "5720009",
        canal_sage: "",
        cod_banco_afb: "bbva_emp",
        cod_producto_afb: "ES2401824022180201655351",
        eliminada: "0",
        es_cuenta_facturas: "0",
        es_cuenta_gastos: "0",
        f_modificacion_autom: "2020-11-06 11:56:49",
        f_primer_mvto: "2019-01-01",
        f_ultima_llamada: "2020-11-06 11:56:49",
        f_ultimo_saldo: "2020-11-06 00:00:00",
        iban: "ES2401824022180201655351",
        id_banco_checkit: "0",
        id_categoria_pf: "5",
        id_cod_analitica: "0",
        id_cuenta_bancaria_checkit: "0",
        id_empresa: "69",
        id_pf_cuenta_bancaria: "54",
        id_tipo_acceso_checkit: "0",
        id_tipo_cuenta_checkit: "1",
        no_voltear: "0",
        nombre_cuenta_bancaria: "Impuestos y Nominas 55351",
        num_dias_ignorar: "2",
        num_tarjeta: "",
        perc_obj_pf: "0",
        saldo: "22366.3",
        user_id_checkit: "20561634",
        user_password_checkit: "MARICARM",
        user_pin_checkit: "RESP1490",
      },
      {
        account_id_afb: "-1",
        canal: "5720010",
        canal_sage: "",
        cod_banco_afb: "bbva_emp",
        cod_producto_afb: "ES2501824022130201655481",
        eliminada: "0",
        es_cuenta_facturas: "0",
        es_cuenta_gastos: "0",
        f_modificacion_autom: "2020-11-11 11:04:41",
        f_primer_mvto: "0000-00-00",
        f_ultima_llamada: "2021-03-24 12:21:42",
        f_ultimo_saldo: "2020-10-13 00:00:00",
        iban: "ES2501824022130201655481",
        id_banco_checkit: "0",
        id_categoria_pf: "2",
        id_cod_analitica: "0",
        id_cuenta_bancaria_checkit: "0",
        id_empresa: "69",
        id_pf_cuenta_bancaria: "55",
        id_tipo_acceso_checkit: "0",
        id_tipo_cuenta_checkit: "1",
        no_voltear: "0",
        nombre_cuenta_bancaria: "Gastos Generales 55481",
        num_dias_ignorar: "0",
        num_tarjeta: "",
        perc_obj_pf: "0",
        saldo: "23452.94",
        user_id_checkit: "20561634",
        user_password_checkit: "MARICARM",
        user_pin_checkit: "RESP1490",
      },
      {
        account_id_afb: "-1",
        canal: "5720011",
        canal_sage: "",
        cod_banco_afb: "bbva_emp",
        cod_producto_afb: "ES3201824022170201655450",
        eliminada: "0",
        es_cuenta_facturas: "0",
        es_cuenta_gastos: "0",
        f_modificacion_autom: "2020-11-05 21:18:20",
        f_primer_mvto: "2019-01-01",
        f_ultima_llamada: "2020-11-05 21:18:20",
        f_ultimo_saldo: "2020-10-05 00:00:00",
        iban: "ES3201824022170201655450",
        id_banco_checkit: "0",
        id_categoria_pf: "6",
        id_cod_analitica: "0",
        id_cuenta_bancaria_checkit: "0",
        id_empresa: "69",
        id_pf_cuenta_bancaria: "56",
        id_tipo_acceso_checkit: "0",
        id_tipo_cuenta_checkit: "1",
        no_voltear: "0",
        nombre_cuenta_bancaria: "Beneficio 55450",
        num_dias_ignorar: "0",
        num_tarjeta: "",
        perc_obj_pf: "0",
        saldo: "21629.35",
        user_id_checkit: "20561634",
        user_password_checkit: "MARICARM",
        user_pin_checkit: "RESP1490",
      },
      {
        account_id_afb: "-1",
        canal: "5720012",
        canal_sage: "",
        cod_banco_afb: "bbva_emp",
        cod_producto_afb: "ES4701824022110201655474",
        eliminada: "0",
        es_cuenta_facturas: "0",
        es_cuenta_gastos: "0",
        f_modificacion_autom: "2020-11-05 21:30:46",
        f_primer_mvto: "2019-01-01",
        f_ultima_llamada: "2020-11-05 21:30:46",
        f_ultimo_saldo: "2020-10-05 00:00:00",
        iban: "ES4701824022110201655474",
        id_banco_checkit: "0",
        id_categoria_pf: "7",
        id_cod_analitica: "0",
        id_cuenta_bancaria_checkit: "0",
        id_empresa: "69",
        id_pf_cuenta_bancaria: "57",
        id_tipo_acceso_checkit: "0",
        id_tipo_cuenta_checkit: "1",
        no_voltear: "0",
        nombre_cuenta_bancaria: "Reserva 55474",
        num_dias_ignorar: "0",
        num_tarjeta: "",
        perc_obj_pf: "0",
        saldo: "21629.35",
        user_id_checkit: "20561634",
        user_password_checkit: "MARICARM",
        user_pin_checkit: "RESP1490",
      },
    ],
    arr_vacaciones: {
      dias_festivos: null,
      dias_semana_laborables: null,
      etiquetas_trabajadores: null,
    },
    canales: "Caja,Caja,Caja, moneda extranjera",
    canarias: "0",
    cod_checkit: "",
    cp: "03184",
    email: "cesar@respirainternet.com",
    entidad_a3: "",
    entidad_contaplus: "",
    entidad_ekon: "1490",
    entidad_geyce: "",
    entidad_sage: "",
    es_afterbanks: "1",
    es_asesoria: "0",
    es_bancos_tunuve: "1",
    es_profit_first: "1",
    id_empresa: "69",
    iva_asumido_perc_clientes: "21",
    iva_asumido_perc_proveedores: "10",
    json_opciones_facturacion:
      "{'irpf_perc':'true','id_serie':'true','cantidad':'true','dto':'false','tiene_recargo':'false','es_suplido':'false','id_cod_analitica':'false','id_pf_cuenta_bancaria':'false','id_tipo_vto':'0'}",
    logo: "1616597790_logo_respira_transparente.png",
    medios_pago:
      "[{'medio_pago':'BBVA','num_cuenta':'ES24 0182 4022 1802 0165 5351'},{'medio_pago':'Qonto','num_cuenta':'ES18 6888 0001 6240 0767 8326'}]",
    modelo_factura: "pdf2",
    mostrar_columnas_facturas:
      "direccion,contrapartida,f_vto,importe_vto_1,f_vto_2,importe_vto_2,f_vto_3,importe_vto_3,f_vto_4,importe_vto_4",
    nif: "B54657259",
    nombre: "Respira Internet, SLU",
    poblacion: "Torrevieja",
    posiciones_columnas_facturas:
      "0,1,2,3,4,5,6,7,8,12,9,10,11,30,13,31,32,33,34,35,36,37,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37",
    provincia: "3",
    telefono: "",
    tipo_iva_caja_diaria: "21",
    trabaja_con_rec_equiv: "0",
    direccion: "Avda Nenúfares sn",
  },
];
