<template>
  <form @submit.prevent="onSubmit" novalidate>
    <FormElement
      id="nombre"
      name="nombre"
      type="text"
      label="Nombre o razón social"
      placeholder="Nombre"
      success-message=""
      v-model="data.nombre"
    />
    <FormElement
      id="nif"
      name="nif"
      type="text"
      label="NIF"
      placeholder="Introduzca el NIF"
      success-message=""
      v-model="data.nif"
    />
    <FormElement
      id="direccion"
      name="direccion"
      type="text"
      label="Dirección"
      placeholder="Introduzca la dirección"
      success-message=""
      v-model="data.direccion"
    />

    <FormElement
      id="cp"
      name="cp"
      type="text"
      label="Código Postal"
      placeholder="Introduce tu código postal"
      v-model="data.cp"
      :value="data.cp"
    />

    <FormElement
      type="select"
      id="id_provincia"
      name="id_provincia"
      label="Provincia"
      :arrOptions="arrProvincias"
      v-model="data.id_provincia"
      :value="data.id_provincia"
      :multiple="false"
    />

    <FormElement
      type="select"
      id="id_poblacion"
      name="id_poblacion"
      label="Población"
      :arrOptions="arrPoblaciones"
      v-model="data.id_poblacion"
      :value="data.id_poblacion"
      :multiple="false"
    />

    <FormElement
      id="email"
      name="email"
      type="text"
      label="Email"
      placeholder="Introduce tu email"
      v-model="data.email"
      :value="data.email"
    />

    <FormElement
      id="telefono"
      name="telefono"
      type="text"
      label="Teléfono"
      placeholder="Introduce tu teléfono"
      v-model="data.telefono"
      :value="data.telefono"
    />

    <FormButton
      clases="btn btn-primary submit-btn"
      icon="save"
      label="Guardar datos"
      @click="onSubmit"
      :disabled="!meta.valid"
      :loading="saveButtonLoading"
    />

    &nbsp;

    <router-link class="btn btn-secondary" to="/factura/0">
      <i class="bi bi-plus-circle"></i> Crear Factura
    </router-link>
  </form>
</template>

<script>
import { useForm } from "vee-validate";
import FormElement from "@/components/Forms/FormElement.vue";
import FormButton from "@/components/Forms/FormButton.vue";

import { reactive } from "vue";

import { arrPoblaciones } from "@/data/poblaciones";
import { arrProvincias } from "@/data/provincias";

import Fx from "@/lib/functions";
import { id_provincia } from "@/lib/tunuveFx";

export default {
  name: "EmpresaForm",

  emits: ["formSubmitted"],

  components: {
    FormElement,
    FormButton,
  },

  props: {
    formData: {
      type: Object,
      required: true,
    },
    formSchema: {
      type: Object,
      required: true,
    },

    saveButtonLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const data = reactive(props.formData);

    const { meta, values } = useForm({
      validationSchema: props.schema,
      initialValues: props.formData,
      validateOnMount: false,
    });

    function onSubmit() {
      let poblacion = Fx.getAttr("#id_poblacion input", "placeholder"),
        provincia = Fx.getAttr("#id_provincia input", "placeholder");

      Object.keys(data).map((id) => (values[id] = data[id]));

      values.poblacion = poblacion || "";
      values.provincia =
        data.id_provincia || id_provincia(provincia, values.cp);

      emit("formSubmitted", values);
    }

    return {
      data,
      arrPoblaciones,
      arrProvincias,
      meta,
      onSubmit,
    };
  },
};
</script>
