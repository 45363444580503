<template>
  <div class="image-uploader">
    <h4>Logotipo</h4>
    <form-image-upload
      @imageUploaded="onImageUploaded"
      @imageDeleted="onImageDeleted"
      :uploadUrl="uploadUrl"
      :uploadData="uploadData"
      :defaultImgSrc="defaultImgSrc"
    ></form-image-upload>

    <div v-if="defaultImgSrc == ''" class="mt-2">
      <BSAlert bsClass="info" icon="info-circle">
        Si no subes un logotipo, usaremos tu nombre o razón social en su lugar.
      </BSAlert>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import { useStore } from "vuex";

import Fx from "@/lib/functions";
import Settings from "@/settings";

import FormImageUpload from "@/components/Forms/FormImageUpload";
import BSAlert from "@/components/BSAlert";

import { ruta_archivo } from "@/lib/tunuveFx";

export default {
  name: "LogoEmpresa",

  components: {
    "form-image-upload": FormImageUpload,
    BSAlert: BSAlert,
  },

  setup() {
    const store = useStore();

    const defaultImgSrc = ref(store.state.logo || "");

    const uploadUrl = Settings.BASE_API.replace("/v2", "") + "upload.php",
      uploadData = {
        id_empresa: store.state.id_empresa,
        hash: store.state.token,
        opcion: "logotipo",
      };

    function onImageUploaded(uploadResp) {
      let url = ruta_archivo(uploadResp.nombre_archivo);
      defaultImgSrc.value = url;

      updateLogoStore(url);
    }

    function onImageDeleted() {
      const url = Settings.BASE_API.replace("/v2", "") + "empresas.php",
        dataObj = {
          accion: "eliminar_logo",
          hash: store.state.token,
          id_empresa: store.state.id_empresa,
        };

      Fx.post(url, dataObj, "POST", Fx.callback).then((data) => {
        if (data.status == "ok") {
          updateLogoStore("");
        }
      });
    }

    function updateLogoStore(url) {
      store.dispatch("changeLogo", url);
    }

    return {
      defaultImgSrc,
      uploadUrl,
      uploadData,
      onImageUploaded,
      onImageDeleted,
    };
  },
};
</script>

<style scoped>
.image-uploader h4 {
  font-weight: bold;
  color: var(--tunuve);
  width: 100%;
  text-align: center;
}
</style>
