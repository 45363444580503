<template>
  <div class="container seccion_header">
    <div class="row">
      <div class="col-9">
        <h1>
          <i v-if="icon != ''" :class="iconClass"></i>
          <img
            v-if="image != ''"
            alt="title"
            height="40"
            :src="getImgUrl()"
            class="pull-left"
          />
          {{ title }}
        </h1>
      </div>

      <div class="col-3">
        <div v-if="link_text != ''" class="div_enlace">
          <router-link :to="link_to" class="nav_link active">
            {{ link_text }} <span> > </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",

  props: {
    title: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: "",
    },

    image: {
      type: String,
      default: "",
    },

    link_to: {
      type: String,
      default: "",
    },

    link_text: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    const iconClass = props.icon == "" ? "" : "bi bi-" + props.icon;

    let getImgUrl = function () {
      return require("../assets/img/" + props.image);
    };

    return {
      iconClass,
      getImgUrl,
    };
  },
};
</script>

<style>
.seccion_header {
  background: #fff;
  padding: 30px 30px 0 30px;
  margin-bottom: 1rem;
  margin-top: 0;
}

.seccion_header i {
  font-size: 24px;
  display: block;
  float: left;
  margin: 0px 10px 20px;
}

.seccion_header h1 {
  margin: 0;
  padding: 0 0 20px 0;
  font-size: 24px;
  padding-left: 10px;
  font-weight: bold;
  color: var(--tunuve);
}

.seccion_header h1 img {
  margin-top: -5px;
  height: auto;
}

.seccion_header .div_enlace {
  text-align: right;
}

.seccion_header .div_enlace a {
  text-decoration: none;
  color: var(--text-light);
  font-weight: bold;
}

.seccion_header .div_enlace a span {
  font-size: 18px;
  padding-left: 5px;
}

.seccion_header .div_enlace a:hover {
  text-decoration: underline;
  color: var(--tunuve);
}
</style>
