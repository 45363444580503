<template>
  <form
    id="form_factura"
    @submit.prevent="onSubmit"
    novalidate
    autocomplete="off"
  >
    <div class="row">
      <div class="col-12 col-md-5">
        <div v-if="loggedIn">
          <LogoEmpresa />
        </div>
      </div>

      <div class="col-md-2 d-none d-md-block"></div>

      <div class="col-12 col-md-5 text-end">
        <FormElement
          id="num_factura"
          name="num_factura"
          type="text"
          label="Número de factura"
          placeholder="Introduce el número de factura"
          v-model="formData.num_factura"
          @input="inputValueChanged"
        />

        <FormElement
          id="f_factura"
          name="f_factura"
          type="date"
          label="Fecha de factura"
          v-model="formData.f_factura"
          :value="formData.f_factura"
        />
      </div>
    </div>

    <hr class="sep mb-3" />

    <div class="row">
      <div class="col-12">
        <!-- No puede ir con display none -->
        <div style="height: 0px; overflow: hidden">
          <FormElement
            id="empresa"
            name="empresa"
            type="text"
            label="Emisor"
            placeholder="Introduce el nombre del emisor"
            textoAyuda="Si el emisor es una persona física, introduce aquí nombre y apellidos. Si es una empresa u organización, el nombre de la empresa con siglas (SL, SA, etc.)"
            v-model="formData.empresa"
            :value="formData.empresa"
            @input="inputValueChanged"
            :disabled="loggedIn"
          />
        </div>

        <div v-if="loggedIn">
          <FormElement
            type="select"
            id="id_contacto"
            name="id_contacto"
            label="Cliente"
            :arrOptions="arrClientes"
            :multiple="false"
            v-model="formData.id_contacto"
            :value="formData.id_contacto"
            @selectValueChanged="selectValueChanged"
            @update:modelValue="selectValueChanged"
          />
          <p class="modificar_contacto">
            <a @click.prevent="showContacto = !showContacto" href="#">
              <i
                class="bi"
                :class="
                  showContacto
                    ? 'bi-arrow-bar-up'
                    : formData.id_contacto > 0
                    ? 'bi-pencil-square'
                    : 'bi-plus-circle'
                "
              ></i>
              {{
                showContacto
                  ? "Cerrar"
                  : formData.id_contacto > 0
                  ? "Modificar cliente"
                  : "Añadir cliente"
              }}
            </a>
          </p>
        </div>
      </div>

      <div class="col-12">
        <div
          id="div_contacto"
          v-show="showContacto"
          class="row position-relative animate__animated animate__slideInRight"
        >
          <div class="col-12 col-md-5">
            <FormElement
              id="nombre"
              name="nombre"
              type="text"
              label="Nombre o Razón social"
              placeholder="Introduce el nombre o razón social del cliente"
              textoAyuda="Si el receptor es una persona física, introduce aquí nombre y apellidos. Si es una empresa u organización, el nombre de la empresa con siglas (SL, SA, etc.)"
              v-model="formData.nombre"
              @update:modelValue="inputValueChanged"
            />

            <FormElement
              id="nif"
              name="nif"
              type="text"
              label="NIF del cliente"
              placeholder="Introduce el CIF, NIF o NIE del cliente"
              textoAyuda="Al guardar, eliminaremos guiones, espacios y demás para un mejor procesamiento."
              v-model="formData.nif"
              @update:modelValue="inputValueChanged"
            />

            <FormElement
              id="direccion"
              name="direccion"
              type="text"
              label="Dirección del cliente"
              placeholder="Introduce la dirección completadel cliente"
              v-model="formData.direccion"
              @input="inputValueChanged"
            />
          </div>

          <div class="col-md-2 d-none d-md-block"></div>

          <div class="col-12 col-md-5 text-end">
            <FormElement
              id="cp"
              name="cp"
              type="text"
              label="Código Postal del cliente"
              placeholder="Introduce el código postal del cliente"
              v-model="formData.cp"
              @input="inputValueChanged"
            />

            <FormElement
              type="select"
              id="id_provincia"
              name="id_provincia"
              label="Provincia del cliente"
              :arrOptions="arrProvincias"
              :multiple="false"
              v-model="formData.id_provincia"
              :value="formData.id_provincia"
              @selectValueChanged="selectValueChanged"
              @update:modelValue="selectValueChanged"
            />

            <FormElement
              :key="poblacionesKey"
              type="select"
              id="id_poblacion"
              name="id_poblacion"
              label="Población del cliente"
              :arrOptions="arrPoblacionesFiltered"
              :multiple="false"
              v-model="formData.id_poblacion"
              :value="formData.id_poblacion"
              @update:modelValue="selectValueChanged"
            />
          </div>
        </div>
      </div>
    </div>

    <hr class="sep mb-3" />

    <div class="row titulos_conceptos d-none d-md-flex">
      <div class="col-12 col-md-6">
        <p class="mb-1">Conceptos de la factura</p>
      </div>
      <div class="col-12 col-md-2">
        <p class="mb-1">Importe</p>
      </div>
      <div class="col-12 col-md-2">
        <p class="mb-1">IVA</p>
      </div>
      <div class="col-12 col-md-2">
        <p class="mb-1">Total</p>
      </div>
    </div>

    <div class="row fila_concepto detalle_concepto">
      <div class="col-12 col-md-6">
        <div class="input-group mb-1">
          <label class="d-block d-md-none">Concepto</label>
          <FormElement
            id="concepto1"
            name="concepto1"
            type="text"
            placeholder="Introduce el concepto..."
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[0].concepto"
            :value="formData.arr_conceptos[0].concepto"
            @input="inputValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div class="input-group mb-1">
          <label class="d-block d-md-none mt-2">Importe</label>
          <FormElement
            id="importe1"
            name="importe1"
            type="number"
            placeholder=""
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[0].importe"
            :value="formData.arr_conceptos[0].importe"
            @input="inputValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div id="div_iva_perc1" class="input-group mb-1">
          <label class="d-block d-md-none mt-2">IVA</label>
          <FormElement
            type="select"
            id="iva_perc1"
            name="iva_perc1"
            label=""
            :hideValidationMessage="true"
            :arrOptions="arrIVA"
            v-model="formData.arr_conceptos[0]['iva_perc']"
            :value="formData.arr_conceptos[0]['iva_perc']"
            @selectValueChanged="selectValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div class="input-group mb-1 position-relative">
          <label class="d-block d-md-none mt-2">Total</label>
          <FormElement
            id="total_concepto1"
            name="total_concepto1"
            type="text"
            :disabled="true"
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[0].total_concepto"
            :value="formData.arr_conceptos[0].total_concepto"
            @input="inputValueChanged"
          />
        </div>
      </div>
    </div>

    <div
      class="row fila_concepto detalle_concepto"
      v-show="
        formData.arr_conceptos[0].concepto != '' ||
        formData.arr_conceptos[0].importe != 0
      "
    >
      <div class="col-12 col-md-6">
        <div class="input-group mb-1">
          <label class="d-block d-md-none">Concepto</label>
          <FormElement
            id="concepto2"
            name="concepto2"
            type="text"
            placeholder="Introduce el concepto..."
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[1].concepto"
            :value="formData.arr_conceptos[1].concepto"
            @input="inputValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div class="input-group mb-1">
          <label class="d-block d-md-none">Importe</label>
          <FormElement
            id="importe2"
            name="importe2"
            type="number"
            placeholder=""
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[1].importe"
            :value="formData.arr_conceptos[1].importe"
            @input="inputValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div id="div_iva_perc2" class="input-group mb-1">
          <FormElement
            type="select"
            id="iva_perc2"
            name="iva_perc2"
            label=""
            :hideValidationMessage="true"
            :arrOptions="arrIVA"
            v-model="formData.arr_conceptos[1]['iva_perc']"
            :value="formData.arr_conceptos[1]['iva_perc']"
            @selectValueChanged="selectValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div class="input-group mb-1 position-relative">
          <label class="d-block d-md-none">Total</label>
          <!-- <input
            id="total_concepto2"
            name="total_concepto2"
            disabled
            type="text"
            class="form-control"
            aria-label="Total concepto"
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[1]['total_concepto']"
            @input="inputValueChanged"
          /> -->

          <FormElement
            id="total_concepto2"
            name="total_concepto2"
            type="text"
            :disabled="true"
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[1].total_concepto"
            :value="formData.arr_conceptos[1].total_concepto"
            @input="inputValueChanged"
          />
        </div>
      </div>
    </div>

    <div
      class="row fila_concepto detalle_concepto"
      v-show="
        formData.arr_conceptos[1].concepto != '' ||
        formData.arr_conceptos[1].importe != 0
      "
    >
      <div class="col-12 col-md-6">
        <div class="input-group mb-1">
          <label class="d-block d-md-none">Concepto</label>
          <FormElement
            id="concepto3"
            name="concepto3"
            type="text"
            placeholder="Introduce el concepto..."
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[2].concepto"
            :value="formData.arr_conceptos[2].concepto"
            @input="inputValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div class="input-group mb-1">
          <label class="d-block d-md-none">Importe</label>
          <FormElement
            id="importe3"
            name="importe3"
            type="number"
            placeholder=""
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[2].importe"
            :value="formData.arr_conceptos[2].importe"
            @input="inputValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div id="div_iva_perc3" class="input-group mb-1">
          <FormElement
            type="select"
            id="iva_perc3"
            name="iva_perc3"
            label=""
            :hideValidationMessage="true"
            :arrOptions="arrIVA"
            v-model="formData.arr_conceptos[2]['iva_perc']"
            :value="formData.arr_conceptos[2]['iva_perc']"
            @selectValueChanged="selectValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div class="input-group mb-1 position-relative">
          <label class="d-block d-md-none">Total</label>
          <!-- <input
            id="total_concepto3"
            name="total_concepto3"
            disabled
            type="text"
            class="form-control"
            aria-label="Total concepto"
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[2]['total_concepto']"
            @input="inputValueChanged"
          /> -->
          <FormElement
            id="total_concepto3"
            name="total_concepto3"
            type="text"
            :disabled="true"
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[2].total_concepto"
            :value="formData.arr_conceptos[2].total_concepto"
            @input="inputValueChanged"
          />
        </div>
      </div>
    </div>

    <div
      class="row fila_concepto detalle_concepto"
      v-show="
        formData.arr_conceptos[2].concepto != '' ||
        formData.arr_conceptos[2].importe != 0
      "
    >
      <div class="col-12 col-md-6">
        <div class="input-group mb-1">
          <label class="d-block d-md-none">Concepto</label>
          <FormElement
            id="concepto4"
            name="concepto4"
            type="text"
            placeholder="Introduce el concepto..."
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[3].concepto"
            :value="formData.arr_conceptos[3].concepto"
            @input="inputValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div class="input-group mb-1">
          <label class="d-block d-md-none">Importe</label>
          <FormElement
            id="importe4"
            name="importe4"
            type="number"
            placeholder=""
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[3].importe"
            :value="formData.arr_conceptos[3].importe"
            @input="inputValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div id="div_iva_perc4" class="input-group mb-1">
          <FormElement
            type="select"
            id="iva_perc4"
            name="iva_perc4"
            label=""
            :hideValidationMessage="true"
            :arrOptions="arrIVA"
            v-model="formData.arr_conceptos[3]['iva_perc']"
            :value="formData.arr_conceptos[3]['iva_perc']"
            @selectValueChanged="selectValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div class="input-group mb-1 position-relative">
          <label class="d-block d-md-none">Total</label>
          <!-- <input
            id="total_concepto4"
            name="total_concepto4"
            disabled
            type="text"
            class="form-control"
            aria-label="Total concepto"
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[3]['total_concepto']"
            @input="inputValueChanged"
          /> -->
          <FormElement
            id="total_concepto4"
            name="total_concepto4"
            type="text"
            :disabled="true"
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[3].total_concepto"
            :value="formData.arr_conceptos[3].total_concepto"
            @input="inputValueChanged"
          />
        </div>
      </div>
    </div>

    <div
      class="row fila_concepto detalle_concepto"
      v-show="
        formData.arr_conceptos[3].concepto != '' ||
        formData.arr_conceptos[3].importe != 0
      "
    >
      <div class="col-12 col-md-6">
        <div class="input-group mb-1">
          <label class="d-block d-md-none">Concepto</label>
          <FormElement
            id="concepto5"
            name="concepto5"
            type="text"
            placeholder="Introduce el concepto..."
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[4].concepto"
            :value="formData.arr_conceptos[4].concepto"
            @input="inputValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div class="input-group mb-1">
          <label class="d-block d-md-none">Importe</label>
          <FormElement
            id="importe5"
            name="importe5"
            type="number"
            placeholder=""
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[4].importe"
            :value="formData.arr_conceptos[4].importe"
            @input="inputValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div id="div_iva_perc5" class="input-group mb-1">
          <FormElement
            type="select"
            id="iva_perc5"
            name="iva_perc5"
            label=""
            :hideValidationMessage="true"
            :arrOptions="arrIVA"
            v-model="formData.arr_conceptos[4]['iva_perc']"
            :value="formData.arr_conceptos[4]['iva_perc']"
            @selectValueChanged="selectValueChanged"
          />
        </div>
      </div>

      <div class="col-12 col-md-2">
        <div class="input-group mb-1 position-relative">
          <label class="d-block d-md-none">Total</label>
          <!-- <input
            id="total_concepto5"
            name="total_concepto5"
            disabled
            type="text"
            class="form-control"
            aria-label="Total concepto"
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[4]['total_concepto']"
            @input="inputValueChanged"
          /> -->
          <FormElement
            id="total_concepto5"
            name="total_concepto5"
            type="text"
            :disabled="true"
            :hideValidationMessage="true"
            v-model="formData.arr_conceptos[4].total_concepto"
            :value="formData.arr_conceptos[4].total_concepto"
            @input="inputValueChanged"
          />
        </div>
      </div>
    </div>

    <div class="row fila_concepto mt-4">
      <div class="col-4 d-none d-md-block">&nbsp;</div>

      <div class="col-6 col-sm-4 d-none d-md-block">
        <p class="total text-end">Total conceptos:</p>
      </div>

      <div class="col-2 col-sm-4 d-none d-md-block">
        <div class="input-group">
          <input
            style="margin-top: -12px"
            id="total_conceptos"
            disabled
            type="text"
            class="form-control"
            aria-label="Total conceptos"
            v-model="formData.total_conceptos"
          />
        </div>
      </div>
    </div>

    <div class="row fila_concepto fila_total">
      <div class="col-4 d-none d-md-block">&nbsp;</div>

      <div class="col-6 col-sm-4 d-none d-md-block">
        <p class="total text-end">IRPF (%):</p>
      </div>

      <div class="col-2 col-sm-4 d-none d-md-block">
        <FormElement
          class=""
          type="select"
          id="irpf_perc"
          name="irpf_perc"
          label=""
          :hideValidationMessage="true"
          :arrOptions="arrIRPF"
          :multiple="false"
          v-model="formData.irpf_perc"
          :value="formData.irpf_perc"
          @update:modelValue="selectValueChanged"
          @selectValueChanged="selectValueChanged"
        />
      </div>
    </div>

    <div class="row total_factura">
      <div class="col-4 d-none d-md-block">&nbsp;</div>

      <div class="col-6 col-sm-4 d-none d-md-block">
        <p class="text-end"><b>Total Factura:</b></p>
      </div>

      <div class="col-2 col-sm-4 d-none d-md-block">
        <div class="input-group mb-1">
          <input
            style="margin-top: -12px"
            id="total_factura"
            disabled
            type="text"
            class="form-control"
            aria-label="Total factura"
            v-model="formData.total_factura"
          />
        </div>
      </div>
    </div>

    <div class="row observaciones mb-4">
      <div class="col-12">
        <div class="input-group mb-1">
          <p class="mb-1">Observaciones:</p>
          <textarea
            id="obs_factura"
            class="form-control w-100"
            v-model="formData.obs_factura"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <p class="mb-1 text-center">
          <FormButton
            v-if="formData.id_factura > 0"
            id="btn_descargar"
            type="button"
            clases="btn btn-secondary mb-1"
            label="Descargar factura"
            icon="download"
            @click="downloadInvoice"
            :disabled="
              formData.total_conceptos == '0,00€' ||
              formData.num_factura == '' ||
              !facturaOk
            "
          ></FormButton>

          &nbsp;

          <FormButton
            id="btn_guardar"
            type="submit"
            clases="btn btn-primary mb-1"
            label="Guardar factura"
            icon="save"
            :disabled="
              // !loggedIn ||
              formData.total_conceptos == '0,00€' ||
              formData.num_factura == '' ||
              !facturaOk
            "
          ></FormButton>

          &nbsp;

          <router-link to="/factura/0">
            <SimpleButton
              text="Nueva factura"
              icon="plus-circle"
              bsClass="btn-secondary mb-1"
            />
          </router-link>
        </p>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <BSAlert :hide="facturaOk" bsClass="info" icon="exclamation-circle">
          <span v-html="mensajeFaltanDatos"></span>
        </BSAlert>
      </div>
    </div>
  </form>
</template>

<script>
import { reactive, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { useForm } from "vee-validate";

import BSAlert from "@/components/BSAlert.vue";
import FormElement from "@/components/Forms/FormElement.vue";
import FormButton from "@/components/Forms/FormButton.vue";
import LogoEmpresa from "@/views/empresas/LogoEmpresa.vue";
import SimpleButton from "@/components/SimpleButton.vue";

import { arrPoblaciones } from "@/data/poblaciones";
import { arrProvincias } from "@/data/provincias";
import { arrIRPF } from "@/data/irpf";
import { arrIVA } from "@/data/iva";

import Fx from "@/lib/functions";
import Settings from "@/settings";
import { id_poblacion, id_provincia } from "@/lib/tunuveFx";

import { setLocale, object, string } from "yup";
import es from "yup-es";

export default {
  name: "FacturaForm",

  emits: ["formSubmitted", "downloadInvoice"],

  components: {
    FormElement,
    FormButton,
    BSAlert,
    LogoEmpresa,
    SimpleButton,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    id_contacto: {
      type: String,
      default: "0",
    },

    saveButtonLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const store = useStore(),
      loggedIn = store.state.loggedIn,
      formData = reactive(props.data),
      arrClientes = reactive([Settings.EMPTY_SELECT_OBJ]),
      // showContacto = ref(!loggedIn),
      showContacto = ref(false),
      facturaOk = ref(false),
      mensajeFaltanDatos = ref("");

    let id_contacto_inicial = ref(props.id_contacto).value;

    let arrPoblacionesFiltered = ref(arrPoblaciones),
      poblacionesKey = ref("poblacionesKey");

    // Using yup to generate a validation schema
    // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
    setLocale(es);

    const formSchema = object().shape({
      num_factura: string().required(),
      nif: string().min(6).required(),
    });

    const { meta, values } = useForm({
      validateOnValueUpdate: true,
      initialValues: props.formData,
      validationSchema: formSchema,
    });

    formData.f_factura =
      formData.id_factura == 0
        ? new Date()
        : Fx.dateStrToObj(formData.f_factura);

    if (formData.irpf_perc) {
      formData.irpf_perc = Fx.twoDecimals(formData.irpf_perc);
    }

    if (formData.id_contacto) {
      formData.id_contacto = parseInt(formData.id_contacto);
    }

    if (loggedIn) {
      loadClientes();
    }

    if (formData.id_factura == 0) {
      getNuevoNumFactura();
      generaMensajeFaltanDatos();
    }

    function onSubmit() {
      let poblacion = Fx.getAttr("#id_poblacion input", "placeholder"),
        provincia = Fx.getAttr("#id_provincia input", "placeholder");

      Object.keys(formData).map((id) => (values[id] = formData[id]));

      values.poblacion = poblacion;
      values.provincia =
        formData.id_provincia || id_provincia(provincia, values.cp);

      emit("formSubmitted", values);
    }

    function downloadInvoice() {
      if (facturaOk.value == true) {
        emit("downloadInvoice", formData);
      }
    }

    // carga clientes
    function populateClientesData(data) {
      if (
        !Fx.is_object(data) ||
        !data.data ||
        !data.status ||
        data.status == "ko"
      ) {
        return true;
      }

      const objContacto = Fx.is_array(data.data)
        ? data.data[0]
        : data.data || {};

      formData.id_contacto = objContacto.id_contacto;
      formData.nombre = objContacto.nombre || "";
      formData.nif = objContacto.nif || "";
      formData.direccion = objContacto.direccion || "";
      formData.cp = objContacto.cp || "";

      formData.id_poblacion = id_poblacion(objContacto.poblacion);
      formData.id_provincia = id_provincia(objContacto.provincia);
    }

    async function updateCliente(id_contacto) {
      formData.nombre = "";
      formData.nif = "";
      formData.direccion = "";
      formData.cp = "";

      if (id_contacto == 0) {
        formData.id_contacto = 0;
        return true;
      }

      const url = Settings.BASE_API + "contactos/",
        params = {
          hash: store.state.token,
          accion: "consultar",
          tipo_contacto: "cliente",
          id_empresa: store.state.id_empresa,
          id_contacto: id_contacto,
        };

      const data = await Fx.get(url, params, Fx.callback);

      populateClientesData(data);
    }

    function getClientesObj(data) {
      if (data.status && data.status == "ko") {
        return [Settings.EMPTY_SELECT_OBJ];
      }

      let val = data.data
        .filter((item) => {
          return (
            item.id_empresa == store.state.id_empresa &&
            item.id_tipo_contacto == "0"
          );
        })
        .map((elem) => {
          return {
            label: elem.nombre + " (" + elem.nif + ")",
            value: elem.id_contacto || 0,
          };
        });

      val.unshift(Settings.EMPTY_SELECT_OBJ);

      return val;
    }

    function assignClientesObj(newArrClientes) {
      return Object.assign(arrClientes, newArrClientes);
    }

    function loadClientes() {
      const url = Settings.BASE_API + "contactos/",
        params = {
          hash: store.state.token,
          accion: "consultar",
          opcion: "factura",
          id_empresa: store.state.id_empresa,
        };

      Fx.get(url, params, Fx.callback)
        .then(getClientesObj)
        .then(assignClientesObj)
        .then(() => {
          if (parseInt(id_contacto_inicial) > 0) {
            formData.id_contacto = id_contacto_inicial;
            updateCliente(id_contacto_inicial);
          }
        })
        .catch(function (error) {
          console.log("[Login] Problema en el fetch: \n", error);
        });
    }
    // fin carga clientes

    function getNuevoNumFactura() {
      const url = Settings.BASE_API + "facturas/num/",
        params = {
          hash: store.state.token,
          id_empresa: store.state.id_empresa,
          id_serie: 0,
        };

      Fx.get(url, params, Fx.callback)
        .then((resp) => {
          if (resp && resp.status == "ok" && resp.data && resp.data != null) {
            formData.num_factura = resp.data.toString();
          }
        })
        .catch(function (error) {
          console.log("[Login] Problema en el fetch: \n", error);
        });
    }

    function inputValueChanged(e) {
      let field = Fx.attr(e, "id");

      if (field == "f_factura") {
        return true;
      }

      if (field.indexOf("concepto") > -1 || field.indexOf("importe") > -1) {
        let index = parseInt(field.substr(field.length - 1));

        if (Fx.is_numeric(values[field])) {
          values[field] = Fx.twoDecimals(values[field]);
        }

        formData.arr_conceptos[index - 1][field.replace(index.toString(), "")] =
          values[field];
      } else {
        if (formData[field] && values[field]) {
          formData[field] = values[field];
        }
      }

      actualizarTotales();
    }

    function selectValueChanged(objValue) {
      let { id, value } = objValue;

      if (typeof value == "object" && value.value) {
        value = value.value;
      }

      if (formData[id] !== undefined) {
        formData[id] = value;
      }

      if (id.indexOf("iva_perc") == 0) {
        let i = parseInt(id.substr(-1));
        formData.arr_conceptos[i - 1].iva_perc = value;
      }

      if (id == "id_contacto") {
        updateCliente(value);
      }

      if (id == "id_provincia") {
        actualizarPoblaciones(objValue);
      }

      actualizarTotales();
      generaMensajeFaltanDatos();
    }

    function actualizarTotales() {
      let irpf_perc = Fx.twoDecimals(formData.irpf_perc),
        total_conceptos = 0,
        total_irpf = 0,
        total_factura = 0,
        total_bi = 0;

      formData.arr_conceptos.map((arr_concepto) => {
        let importe = Fx.twoDecimals(arr_concepto.importe),
          iva_perc = Fx.twoDecimals(arr_concepto.iva_perc),
          iva_concepto = Fx.twoDecimals(importe * (iva_perc / 100));

        arr_concepto.base_imponible = importe;
        arr_concepto.importe = importe; // porque viene como cadena
        arr_concepto.total_iva = iva_concepto;
        arr_concepto.total_concepto = Fx.twoDecimals(importe + iva_concepto);
      });

      total_conceptos = Fx.sumByKey(formData.arr_conceptos, "total_concepto");
      total_bi = Fx.sumByKey(formData.arr_conceptos, "importe");

      formData.total_conceptos = Fx.euros(total_conceptos);
      formData.importe = total_bi;

      if (isNaN(irpf_perc) || irpf_perc === null) {
        irpf_perc = 0;
      }

      total_irpf = Fx.twoDecimals(total_bi * (irpf_perc / 100));
      formData.total_irpf = total_irpf;

      total_factura = total_conceptos - total_irpf;

      formData.total_factura = Fx.euros(total_factura);
    }

    function generaMensajeFaltanDatos() {
      var mensaje = "";

      if (!formData || !formData.num_factura) {
        return true;
      }

      if (formData.num_factura.length == 0) {
        mensaje += "Falta número de factura.";
      }

      if (formData.f_factura.getTime() < 1000) {
        mensaje += "Falta fecha de factura.";
      }

      if (formData.nif == "" || formData.nif == undefined) {
        mensaje +=
          formData.id_contacto == 0
            ? "Falta seleccionar o añadir un cliente."
            : "Faltan datos obligatorios del cliente (NIF).";
      }

      if (
        formData.arr_conceptos[0].concepto == "" &&
        formData.arr_conceptos[0].importe == 0
      ) {
        mensaje += "Falta el concepto de la factura.";
      }

      if (mensaje != "") {
        var arr_mensaje = mensaje
          .slice(0, -1)
          .split(".")
          .map((item) => "<li>" + item + "</li>");

        mensaje = "<br><ul>" + arr_mensaje.join("") + "<ul>";
      }

      return mensaje;
    }

    function actualizarPoblaciones(objValue) {
      arrPoblacionesFiltered.value = arrPoblaciones.filter(
        (item) => item.id_provincia == objValue.value || item.value == 0
      );

      formData.id_poblacion = 0;

      poblacionesKey.value = "poblacionesKey" + Fx.getTime();
    }

    watchEffect(() => {
      if (
        formData == undefined ||
        formData.num_factura == undefined ||
        typeof formData.num_factura != "string"
      ) {
        return true;
      }

      facturaOk.value =
        formData.num_factura.length > 0 &&
        formData.f_factura.getTime() > 1000 &&
        formData.nif != "" &&
        (formData.arr_conceptos[0].concepto != "" ||
          formData.arr_conceptos[0].importe != 0);

      mensajeFaltanDatos.value = generaMensajeFaltanDatos();
    });

    return {
      formData,
      arrPoblacionesFiltered,
      arrProvincias,
      arrIVA,
      arrIRPF,
      meta,
      onSubmit,
      downloadInvoice,
      loggedIn,
      inputValueChanged,
      selectValueChanged,
      arrClientes,
      showContacto,
      facturaOk,
      mensajeFaltanDatos,
      poblacionesKey,
    };
  },
};
</script>

<style scoped>
.logo {
  max-width: 320px;
  max-height: 50px;
  width: auto;
}

#form_factura input,
#form_factura textarea {
  border: 1px solid #ced4da;
  border-radius: 0px;
  box-shadow: none;
  text-align: right;
  margin: 0;
  padding: 15px 30px;
}

#form_factura textarea {
  text-align: left;
  padding: 15px 40px;
  min-height: 100px;
}

#form_factura hr {
  border: 1px solid #ddd;
}

#form_factura .input-group span {
  min-width: 120px;
}

p.modificar_contacto {
  margin: -25px 0 20px 0;
  padding: 0;
  text-align: right;
}

p.modificar_contacto a {
  text-decoration: none;
  color: var(--tunuve);
}

p.modificar_contacto a:hover {
  color: orange;
}

.titulos_conceptos {
  background: var(--bg-lightgrey);
  font-weight: bold;
  padding: 17px 0 13px 0;
  text-align: center;
}

.titulos_conceptos p:eq(0) {
  text-align: left;
}

p.total {
  margin: 0;
  padding: 0;
}

#id_poblacion .vue-dropdown {
  position: relative;
}

@media screen and (max-width: 768px) {
  .detalle_concepto {
    display: block;
  }

  .detalle_concepto ~ .detalle_concepto {
    display: none;
  }

  #form_factura .detalle_concepto input {
    border: 1px solid #eee !important;
  }
}
</style>
