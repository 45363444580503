<template>
  <div class="main">
    <section-header
      title="tunuve Pro"
      icon="cloud"
      link_to="https://tunuve.com/alta-pymes"
      link_text="tunuve PRO"
    />

    <div class="container view_container">
      <div class="row">
        <div class="col-12 text-center">
          <h1>Tienes más dinero del que piensas</h1>
          <p>
            Las Cuentas Que Cuentan es un modelo que
            <b
              >te permitirá mejorar y organizar el uso del dinero de tu negocio
              sin que tengas que ser un experto financiero</b
            >.
          </p>
          <p>Es sencillo y <b>GRATIS</b> con tunuve PRO</p>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center mt-3 mb-3">
          
          <a
            :href="urlContratacion"
            class="btn btn-primary"
            target="_blank"
          >
            <i class="bi bi-check-square"></i>
            Probar tunuve PRO
          </a>
        </div>
      </div>

      <div class="row">
        <CuadroUpgrade
          imageUrl="upgrade1.png"
          title="Analizar tesorería"
          description="Controla en todo momento tu dinero."
        />

        <CuadroUpgrade
          imageUrl="upgrade2.png"
          title="Cobros pendientes"
          description="Conoce en tiempo real quién te ha pagado y quién no."
        />

        <CuadroUpgrade
          imageUrl="upgrade3.png"
          title="Pagos pendientes"
          description="Analiza cuándo debes pagar a tus proveedores."
        />

        <CuadroUpgrade
          imageUrl="upgrade4.png"
          title="Mis movimientos"
          description="Visualiza todos tus movimientos bancarios en un solo sitio."
        />

        <CuadroUpgrade
          imageUrl="upgrade5.png"
          title="Traspasos entre bancos"
          description="Implementa la metodología Las Cuentas que Cuentan en tu negocio."
        />

        <CuadroUpgrade
          imageUrl="upgrade6.png"
          title="Mis proyecciones"
          description="Introduce cobros y pagos previstos para saber si habrá dinero para pagar en el futuro."
        />

        <CuadroUpgrade
          imageUrl="upgrade7.png"
          title="Resumen del negocio"
          description="Visualiza rápidamente el estado financiero de tu negocio."
        />

        <CuadroUpgrade
          imageUrl="upgrade8.png"
          title="Ver formación gratis"
          description=""
        >
          <p>
            <a
              href="https://tunuve.com/soporte"
              target="_blank"
              class="btn btn-primary mt-2"
              >Ver formación</a
            >
          </p>
        </CuadroUpgrade>
      </div>

      <div class="row">
        <div class="col-12 text-center mt-6 mb-6">
          <br /><br />

          <a
            :href="urlContratacion"
            class="btn btn-primary"
            target="_blank"
          >
            <i class="bi bi-check-square"></i>
            Probar tunuve PRO
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/SectionHeader.vue";
import CuadroUpgrade from "@/views/upgrade/CuadroUpgrade.vue";

import Settings from "@/settings";
import { useStore } from "vuex";

export default {
  name: "Upgrade",
  components: {
    SectionHeader,
    CuadroUpgrade,
  },
  
  setup() {
    const store = useStore();

    let urlContratacion = Settings.BASE_CONTRATACION + 'index.php?hash=' + store.state.token;
    
    return {
      urlContratacion
    } 
  }
};
</script>
