<template>
  <div class="main">
    <form @submit.prevent="onSubmit" :validationSchema="schema" novalidate>
      <!-- <datepicker 
        id="f_nacimiento2"
        name="f_nacimiento2"
        :locale="datepickerEsLocale"
        v-model="formData.f_nacimiento2"
        inputFormat="dd/MM/yyyy"
      /> -->

      <FormElement
        id="name"
        name="name"
        type="text"
        label="Full Name"
        placeholder="Your Name"
        success-message="Nice to meet you!"
        v-model="formData.name"
        :value="formData.name"
      />
      <FormElement
        id="email2"
        name="email2"
        type="email2"
        label="E-mail"
        placeholder="Your email address"
        success-message="Got it, we won't spam you!"
        v-model="formData.email"
        :value="formData.email"
      />
      <FormElement
        id="password"
        name="password"
        type="password"
        label="Password"
        placeholder="Your password"
        success-message="Nice and secure!"
        v-model="formData.password"
        :value="formData.password"
      />

      <FormElement
        id="confirm_password"
        name="confirm_password"
        type="password"
        label="Confirm Password"
        placeholder="Type it again"
        success-message="Glad you remembered it!"
        v-model="formData.confirm_password"
        :value="formData.confirm_password"
      />

      <FormElement
        id="f_nacimiento"
        name="f_nacimiento"
        type="date"
        label="F. nacimiento"
        success-message="Nice date"
        v-model="formData.f_nacimiento"
        :value="formData.f_nacimiento"
      />

      <FormElement
        id="edad"
        name="edad"
        type="number"
        label="Edad"
        success-message="Nice edad"
        textoAyuda="texto de ayuda input"
        v-model="formData.edad"
        :value="formData.edad"
      />

      <FormElement
        type="select"
        id="irpf_perc"
        name="irpf_perc"
        label="IRPF"
        :arrOptions="arrIRPF"
        v-model="formData.irpf_perc"
        :value="formData.irpf_perc"
        :multiple="false"
        success-message="Nice selection 2"
        textoAyuda="texto de ayuda select 2"
      />

      <FormElement
        type="select"
        id="id_provincia"
        name="id_provincia"
        label="Provincia"
        :arrOptions="arrProvincias"
        v-model="formData.id_provincia"
        :value="formData.id_provincia"
        :multiple="false"
      />

      <FormButton
        clases="btn btn-primary submit-btn"
        icon="envelope"
        label="Enviar"
        @click="onSubmit"
      />
    </form>
  </div>
</template>

<script>
// import { Form } from "vee-validate";
import { setLocale, object, string, ref, date } from "yup";
import es from "yup-es";

import { addDays, subDays } from "date-fns";

import FormElement from "@/components/Forms/FormElement.vue";
import FormButton from "@/components/Forms/FormButton.vue";

// import Datepicker from 'vue3-datepicker';
// import { es as datepickerEsLocale } from 'date-fns/locale';

import { reactive } from "vue";

import { arrProvincias } from "@/data/provincias";

import Fx from "@/lib/functions";

export default {
  name: "FormTest",
  components: {
    FormElement,
    // Form,
    FormButton,
    // Datepicker
    // FormSelect
  },
  setup() {
    // function parseDateString(value, originalValue) {
    //   const parsedDate = isDate(originalValue)
    //     ? originalValue
    //     : parse(originalValue, "yyyy-MM-dd", new Date());

    //   return parsedDate;
    // }

    function onSubmit(values) {
      alert(JSON.stringify(values, null, 2));
    }

    const today = new Date();
    const maxDate = addDays(today, 366);
    const minDate = subDays(today, 366);

    const formData = reactive({
      name: "César",
      email: "miemail@me.es",
      password: "aaaaaaa",
      confirm_password: "bbbbbbb",
      f_nacimiento: Fx.dateStrToObj("2021-09-15"),
      // f_nacimiento2: Fx.dateStrToObj( "2021-09-15" ),
      edad: "45",
      irpf_perc: "19",
      id_provincia: 28, // madrid
    });

    // const irpf_perc = 19;

    const arrIRPF = [
      {
        label: "0%",
        value: "0",
      },
      {
        label: "1.5%",
        value: "1.5",
      },
      {
        label: "15%",
        value: "15",
      },
      {
        label: "19%",
        value: "19",
      },
    ];

    // Using yup to generate a validation schema
    // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
    setLocale(es);

    const schema = object().shape({
      name: string().required(),
      email: string().email().required(),
      f_nacimiento: date()
        .required()
        // .transform(parseDateString)
        .max(maxDate)
        .min(minDate),
      edad: string()
        // esta expresión regular no funciona en Safari
        // .matches(/^(?<=\s|^)\d+(?=\s|$)/, "Sólo enteros")
        .min(0)
        .max(120),
      irpf_perc: string().required("Campo requerido"),
      password: string().min(6).required(),
      confirm_password: string()
        .required()
        .oneOf([ref("password")], "Los campos no coinciden"),
    });

    return {
      formData,
      onSubmit,
      schema,
      arrIRPF,
      arrProvincias,
      // datepickerEsLocale
    };
  },
};
</script>

<style scoped>
form {
  width: 300px;
  margin: 0px auto;
  padding-bottom: 60px;
}

.submit-btn {
  background: var(--alert-info);
  outline: none;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  display: block;
  width: 100%;
  border-radius: 7px;
  margin-top: 40px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.submit-btn:hover {
  transform: scale(1.1);
}
</style>
