import { Response } from "miragejs";
import Fx from "@/lib/functions";

var ServerUtil = {
  updateSingleData(schema, request, optionsObj) {
    const { fieldId, fieldName, fieldValue, tableName, arrKey } = optionsObj,
      CRUDCollection = schema.db[tableName],
      dataObj = {};

    let matchObj = {},
      dbId = 0,
      objCRUD = ServerUtil.getCRUDObj(request, arrKey);

    dataObj[fieldName] = fieldValue;
    matchObj[fieldId] = objCRUD[fieldId];

    dbId = schema[tableName].findBy(matchObj).id;

    CRUDCollection.update(dbId, dataObj);

    return dataObj;
  },

  updateImg(schema, request, optionsObj) {
    optionsObj.fieldName = optionsObj.imgFieldName;
    optionsObj.fieldValue = optionsObj.imgUrl;

    return ServerUtil.updateSingleData(schema, request, optionsObj);
  },

  getCRUDObj: (request, arrKey) => {
    let objCRUD = {},
      userKey = "";

    if (
      typeof request.requestBody.forEach != "undefined" ||
      typeof request.requestBody == "object"
    ) {
      request.requestBody.forEach((value, index) => {
        userKey = index.replace(arrKey + "[", "").replace("]", "");
        objCRUD[userKey] = value;
      });
    } else if (Fx.isValidJson(request.requestBody)) {
      objCRUD = JSON.parse(request.requestBody);

      if (objCRUD[arrKey]) {
        objCRUD = objCRUD[arrKey];
      }
    } else {
      console.log("[serverUtil.getCRUDObj]: Respuesta no válida");
    }

    if (objCRUD.hash) {
      delete objCRUD.hash;
    }

    return objCRUD;
  },

  validateCRUD: (objCRUD, arrCheckKeys) => {
    // let arrCheckKeys = ['id_perfil','nombre','apellidos','email','telefono','idioma_sele','perfil'];
    return ServerUtil.validateObj(objCRUD, arrCheckKeys);
  },

  validateObj: (obj, arrKeys) => {
    let errorText = "";

    Object.keys(obj)
      .filter((item) => {
        return item.toString();
      })
      .forEach((key) => {
        if (
          arrKeys.indexOf(key) > -1 &&
          (!obj[key] || obj[key] == "0" || obj[key].toString().length < 1)
        ) {
          errorText += "Campo " + key + " no válido. ";
        }
      });

    return errorText;
  },

  getNewCRUDObj: (method, schema, request, server, optionsObj) => {
    /*
      optionsObj: {
        fieldId: 'id_usuario',
        tableName: 'usuarios',
        arrKey: 'arr_usuario',
        arrCheckKeys: ['id_perfil','nombre','apellidos','email','telefono','idioma_sele','perfil']
      }

    */

    const fieldId = optionsObj.keyFieldId || optionsObj.fieldId,
      CRUDCollection = schema.db[optionsObj.tableName];

    // CRUD validations
    let objCRUD = ServerUtil.getCRUDObj(request, optionsObj.arrKey),
      errorText = ServerUtil.validateCRUD(objCRUD, optionsObj.arrCheckKeys),
      id = objCRUD[fieldId],
      matchObj = {};

    if (errorText != "") {
      return {
        status: "ko",
        error: errorText,
      };
    }

    if (method == "post") {
      let newCRUDId = Fx.maxArrObjValue(CRUDCollection, fieldId) + 1;
      objCRUD[fieldId] = newCRUDId;

      CRUDCollection.insert(objCRUD);
    }

    if (method == "put") {
      matchObj = {};
      matchObj[fieldId] = id;

      let dbId = schema[optionsObj.tableName].findBy(matchObj).id;

      CRUDCollection.update(dbId, objCRUD);
    }

    if (method == "delete") {
      matchObj = {};
      matchObj[fieldId] = id;

      CRUDCollection.remove(matchObj);
      objCRUD = {};
    }

    return objCRUD;
  },

  requestToObject: (request) => {
    let respObj = {};

    if (
      Array.isArray(request.requestBody) ||
      typeof request.requestBody == "object"
    ) {
      request.requestBody.forEach((value, index) => {
        respObj[index] = value;
      });
    } else if (Fx.isValidJson(request.requestBody)) {
      respObj = JSON.parse(request.requestBody);
    } else {
      console.log("[serverUtil.requestToObject]: Respuesta no válida");
    }

    return respObj;
  },

  exitWithError: (errorText = "", status = 409) => {
    let body = {
      status: "ko",
      error: errorText,
    };

    return new Response(status, {}, body);
  },

  exitWithSuccess: (mensaje = "", status = 200) => {
    let body = {
      status: "ok",
      mensaje: mensaje,
    };

    return new Response(status, {}, body);
  },

  exitWithData: (dataObj = {}, status = 200) => {
    let body = {
      status: "ok",
      data: dataObj,
    };

    return new Response(status, {}, body);
  },

  makeRespObj: (respObj) => {
    if (respObj.status == "ko") {
      return ServerUtil.exitWithError(respObj.error);
    }

    if (!Array.isArray(respObj)) {
      respObj = [respObj];
    }

    return {
      status: "ok",
      data: respObj,
    };
  },
};

export default ServerUtil;
