export default {
  // 'PWA_MODE': true,

  BASE_API: process.env.VUE_APP_BASE_API,
  BASE_API_TEST: "http://localhost:8080/sanjuan2/api/v2/",
  // BASE_API_TEST: process.env.VUE_APP_BASE_API,
  BASE_APP: process.env.VUE_APP_BASE_APP,
  BASE_CONTRATACION: process.env.VUE_APP_BASE_CONTRATACION,

  USE_MIRAGEJS: false,
  USE_HTTP_VERBS: true, // set to false if your API does not support PUT, DELETE and PATCH, for exmaple if you are using CORS
  MIRAGEJS_TIMING: 400,

  APP_NAME: "Tunuve Facturas",
  APP_CODE: "tunuve_facturas",

  EMPTY_SELECT_OBJ: { label: "---", value: 0 },

  // APP_BG_COLOR: "#333",
  // APP_PRIMARY_COLOR: "#1B9B11",
  // APP_SECONDARY_COLOR: "#616161",

  // SWITCH_COLOR: {
  //   checked: "#1B9B11",
  //   unchecked: "#999"
  // },

  // APP_BG_IMAGE: process.env.VUE_APP_BASE_APP + "/assets/app_bg.jpg",

  // ARR_LANGS: [
  //   {
  //     id: "es",
  //     text: "Español"
  //   },
  //   {
  //     id: "en",
  //     text: "English"
  //   }
  // ],

  // DEFAULT_LANG: "es",

  // DATE_FORMAT: {
  //   es: "DD/MM/YYYY",
  //   en: "MM/DD/YYYY"
  // },

  // PROFILES: ["---", "Admin", "User"],

  // DATATABLES_DEFAULT_OPTIONS: langObj => {
  //   return {
  //     responsive: true,
  //     sPaginationType: "full_numbers",
  //     order: [], // Previene sorting inicial
  //     bDestroy: true,
  //     lengthMenu: [
  //       [10, 25, 50, 100, -1],
  //       [10, 25, 50, 100, langObj.allLabel]
  //     ],
  //     pageLength: 10

  //     // stateSave: true
  //   };
  // },

  CRYPTO_JS_PASSPHRASE: "EI$(6&53#5j",
};
