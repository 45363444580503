import Settings from "@/settings";
import Fx from "@/lib/functions";

import { createStore } from "vuex";

export const store = createStore({
  state() {
    return {
      loggedIn: false,
      token: "",
      id_asesor: 0,
      id_empresa: 0,
      logo: "",

      // valores para evitar el login
      // loggedIn: true,
      // token: '85asfd54a8rqew',
      // profileId: 1,
      // userId: 1,
      // profile: 'Admin'
    };
  },

  mutations: {
    logIn: (state, objStorage) => {
      state.loggedIn = true;
      state.token = objStorage.token;
      state.id_asesor = objStorage.id_asesor;
      state.id_empresa = objStorage.id_empresa;
      state.logo = objStorage.logo;

      let newObjStorage = {
        loggedIn: true,
        token: state.token,
        id_asesor: state.id_asesor,
        id_empresa: state.id_empresa,
        logo: state.logo,
      };

      localStorage.setItem(
        Settings.APP_CODE,
        Fx.encryptStr(JSON.stringify(newObjStorage))
      );
    },

    logout: (state) => {
      var logoutUrl = Settings.BASE_API + "logout/",
        dataObj = {
          hash: state.hash,
        };

      Fx.post(logoutUrl, dataObj, "POST", Fx.noop)
        .then(function () {
          localStorage.removeItem(Settings.APP_CODE);
          state.loggedIn = false;
          state.token = "";
          state.id_asesor = 0;
          state.id_empresa = 0;
          state.logo = "";
        })
        .catch(function (error) {
          console.log("[Login] Problema en el fetch: \n", error);
        });
    },

    changeStateValue(state, objVal) {
      Object.keys(objVal).forEach((varName) => {
        state[varName] = objVal[varName];
      });

      const localStorageString = localStorage.getItem(Settings.APP_CODE);

      if (localStorageString != null) {
        const objStorage = JSON.parse(Fx.decryptStr(localStorageString));

        Object.keys(objVal).forEach((varName) => {
          objStorage[varName] = objVal[varName];
        });

        localStorage.setItem(
          Settings.APP_CODE,
          Fx.encryptStr(JSON.stringify(objStorage))
        );
      }
    },
  },

  actions: {
    logIn({ commit }, payload) {
      console.log("[Store:] LogIn", payload);
      commit("logIn", payload);
    },
    logout({ commit }) {
      console.log("[Store:] Logout");
      commit("logout");
    },
    // changeLang({ commit }, payload) {
    //   console.log("[Store:] ChangeLang", payload);
    //   commit("changeStateValue", { locale: payload });

    //   formGeneratorUpdateLocale(payload);
    // },
    changeLogo({ commit }, payload) {
      console.log("[Store:] ChangeLogo", payload);
      commit("changeStateValue", { logo: payload });
    },
  },
});
