import { Model } from "miragejs";
import Settings from "@/settings";

import ServerUtil from "@/server/inc/serverUtil";
import contactosGetAllSeed from "@/server/seeds/contactosGetAllSeed";

const contactosServer = {
  getDbObj(schema) {
    let dbObj = schema.contactos.where({ id_empresa: "69" }).models;
    return dbObj;
  },

  getSingleObj(schema, fieldName, fieldValue) {
    let searchObj = {};

    searchObj[fieldName] = fieldValue;

    let dbObj = schema.contactos.where(searchObj).models;

    return dbObj;
  },

  addRoutes(server) {
    var esto = this;

    this.addSeeds(server);

    const endpoints = {
      contactosUrl: Settings.BASE_API + "contactos/",
    };

    server.get(
      endpoints.contactosUrl,
      function (schema, request) {
        let id_contacto = request.queryParams.id_contacto;

        const dbObj = id_contacto
          ? esto.getSingleObj(schema, "id_contacto", id_contacto)
          : esto.getDbObj(schema);

        if (dbObj && dbObj.length && dbObj.length > 0) {
          return ServerUtil.exitWithData(dbObj);
        }

        return ServerUtil.exitWithError();
      },
      { timing: Settings.MIRAGEJS_TIMING }
    );

    server.put(
      endpoints.contactosUrl,
      function (schema, request) {
        let requestBodyObj = JSON.parse(request.requestBody);

        let id_contacto = requestBodyObj["obj_contacto"]["id_contacto"];

        const dbObj = esto.getSingleObj(schema, "id_contacto", id_contacto);

        if (dbObj && dbObj.length && dbObj.length > 0) {
          return {
            status: "ok",
            data: {
              ...requestBodyObj.data,
              obj_contacto: { ...requestBodyObj["obj_contacto"] },
            },
          };

          // return ServerUtil.exitWithData( respObj );
        }

        return ServerUtil.exitWithError();
      },
      { timing: Settings.MIRAGEJS_TIMING }
    );
  },

  addSeeds(server) {
    // usamos los mismos seeds de contactos

    contactosGetAllSeed.forEach((contacto) => {
      server.create("contacto", contacto);
    });
  },

  models: {
    contacto: Model,
  },
};

export default contactosServer;
