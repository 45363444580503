<!-- https://www.youtube.com/watch?v=VqnJwh6E9ak -->

<template>
  <div class="div_image_upload text-center">
    <div class="div_image_upload_inner">
      <input
        id="file_input"
        type="file"
        @change="onFileSelected"
        class="d-none"
      />

      <BSAlert :hide="!serverError" bsClass="danger" icon="exclamation-diamond">
        Error en el servidor.
      </BSAlert>

      <BSAlert
        :hide="!fileTypeError"
        bsClass="danger"
        icon="exclamation-diamond"
      >
        Tipo de archivo no válido.
      </BSAlert>

      <img
        class="preview_img"
        @click.prevent="triggerFileSelect"
        v-if="previewImg != ''"
        :src="previewImg"
      />

      <div v-if="previewImg == ''">
        <p class="icono_subida">
          <i class="bi bi-upload"></i>
        </p>
        <h4>Sube ahora tu archivo</h4>

        <p>
          Solo archivos<br />
          <b>JPG, JPEG, PNG</b>
        </p>
      </div>

      <div class="w-100">
        <button
          v-if="previewImg == ''"
          class="mt-1 btn btn-primary"
          @click.prevent="triggerFileSelect"
        >
          <i class="bi bi-upload"></i>
          Subir archivo desde el ordenador
        </button>
      </div>

      <div class="w-100 mt-3">
        <button
          v-show="deleteModalVisible == false"
          id="delete_image"
          v-if="previewImg != ''"
          @click.prevent="deleteModalVisible = true"
        >
          <i class="bi bi-x-circle"></i>
        </button>
      </div>

      <div v-if="deleteModalVisible">
        <Modal
          id="logout"
          @modalClosed="deleteModalVisible = false"
          effect="zoomIn"
        >
          <h3>¿Estás seguro de que deseas eliminar el logo?</h3>
          <p>
            <button
              @click.prevent="deleteImage"
              class="btn btn-secondary w-100 mt-3"
            >
              Sí, eliminar
            </button>

            <button
              @click.prevent="deleteModalVisible = false"
              class="btn btn-primary w-100 mt-1"
              id="no-cancel"
            >
              No, cancelar
            </button>
          </p>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import { ruta_archivo } from "@/lib/tunuveFx";

import BSAlert from "@/components/BSAlert.vue";

export default {
  props: {
    uploadUrl: {
      type: String,
      required: true,
    },
    uploadData: {
      type: Object,
    },
    defaultImgSrc: {
      type: String,
      default: "",
    },
  },

  emits: ["imageUploaded", "imageDeleted"],

  components: {
    BSAlert,
  },

  setup(props, { emit }) {
    let selectedFile = {};

    const previewImg = ref(props.defaultImgSrc),
      serverError = ref(false),
      fileTypeError = ref(false),
      deleteModalVisible = ref(false);

    function onFileSelected(e) {
      selectedFile = e.target.files[0];
      uploadImage();
    }

    function triggerFileSelect() {
      var el = document.getElementById("file_input");
      el.click();
    }

    function uploadImage() {
      if (selectedFile == undefined) {
        return true;
      }

      fileTypeError.value = false;

      let arr_extensiones = ["png", "jpg", "jpeg"],
        extension = selectedFile.name.split(".")[1].toLowerCase();

      if (arr_extensiones.indexOf(extension) == -1) {
        fileTypeError.value = true;
        return true;
      }

      serverError.value = false;

      let data = new FormData();
      data.append("upl", selectedFile);

      Object.keys(props.uploadData).map((key) => {
        data.append(key, props.uploadData[key]);
      });

      fetch(props.uploadUrl, {
        method: "POST",
        headers: {
          // Content-Type may need to be completely **omitted**
          // or you may need something
          // "Content-Type": "You will perhaps need to define a content-type here"
        },
        body: data, // This is your file object
      })
        .then(
          (response) => response.json() // if the response is a JSON object
        )
        .then((success) => {
          // console.log(success); // Handle the success response object
          emit("imageUploaded", success);

          previewImg.value = ruta_archivo(success.nombre_archivo);
        })
        .catch(
          // (error) => console.log(error) // Handle the error response object
          () => (serverError.value = true) // Handle the error response object
        );
    } // end uploadImage

    function deleteImage() {
      previewImg.value = "";
      deleteModalVisible.value = false;
      emit("imageDeleted");
    }

    return {
      previewImg,
      onFileSelected,
      uploadImage,
      deleteImage,
      triggerFileSelect,
      serverError,
      fileTypeError,
      deleteModalVisible,
    };
  },
};
</script>

<style scoped>
.div_image_upload {
  border: 1px solid var(--text-disable);
  padding: 15px;
  position: relative;
}

.div_image_upload_inner {
  border: 3px dashed var(--text-disable);
  padding: 2rem;
}

.div_image_upload h4 {
  font-weight: bold;
  color: var(--tunuve);
}

.preview_img {
  max-width: 100%;
  max-height: 91px;
}

.icono_subida i {
  font-size: 2em;
}

#delete_image {
  background: var(--alert-danger-strong);
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 2px 6px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

#delete_image:hover {
  background: var(--alert-info-strong);
}

#delete_image i {
  font-size: 16px;
}
</style>
