<template>
  <div v-if="!hide">
    <div
      class="alert alert-dismissible show d-flex align-items-center"
      role="alert"
      :class="'alert-' + bsClass + ' ' + animation"
    >
      <i v-if="icon != ''" :class="'bi bi-' + icon"></i>

      <slot></slot>

      <button
        v-if="!not_dismissable"
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Cerrar"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BSAlert",

  props: {
    hide: {
      type: Boolean,
      default: false,
    },

    bsClass: {
      type: String,
      required: true,
      validator: (val) => {
        const arrValues = [
          "info",
          "success",
          "warning",
          "danger",
          "primary",
          "secondary",
          "light",
          "dark",
        ];
        return arrValues.indexOf(val) > -1;
      },
    },

    not_dismissable: {
      type: Boolean,
      default: false,
    },

    animation: {
      type: String,
      default: "fade",
    },

    icon: {
      type: String,
      default: "",
    },

    // link_to: {
    //   type: String,
    //   default: "",
    // },

    // link_text: {
    //   type: String,
    //   default: "",
    // },
  },

  // setup(props) {
  //   const iconClass = props.icon == "" ? "" : "bi bi-" + props.icon;

  //   return {
  //     iconClass,
  //   };
  // },
};
</script>

<style>
div.alert i {
  font-size: 24px;
  margin-right: 10px;
}

div.alert.alert-dismissible .btn-close {
  top: auto;
}

div.alert h1,
div.alert h2,
div.alert h3,
div.alert h4,
div.alert h5,
div.alert h6 {
  color: var(--text-dark);
}
</style>
