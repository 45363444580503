import { Model } from "miragejs";
import Settings from "@/settings";

import ServerUtil from "@/server/inc/serverUtil";
import facturasGetAllSeed from "@/server/seeds/facturasGetAllSeed";

import singleFacturaSeed from "@/server/seeds/facturasSingleSeed";

const facturasServer = {
  getDbObj(schema) {
    let dbObj = schema.facturas.where({ archivo_usuario: "" }).models;

    return dbObj;
  },

  getSingleObj() {
    return singleFacturaSeed;
  },

  addRoutes(server) {
    var esto = this;

    this.addSeeds(server);

    const endpoints = {
      facturasUrl: Settings.BASE_API + "facturas/",
    };

    server.get(
      endpoints.facturasUrl,
      function (schema, request) {
        let id_factura = request.queryParams.id_factura;

        const dbObj = id_factura
          ? esto.getSingleObj(schema, "id_factura", id_factura)
          : esto.getDbObj(schema);

        if (dbObj && dbObj.length && dbObj.length > 0) {
          return ServerUtil.exitWithData(dbObj);
        }

        return ServerUtil.exitWithError();
      },
      { timing: Settings.MIRAGEJS_TIMING }
    );

    server.put(
      endpoints.facturasUrl,
      function (schema, request) {
        let requestBodyObj = JSON.parse(request.requestBody);

        let id_factura = requestBodyObj["obj_factura"]["id_factura"];

        const dbObj = esto.getSingleObj(schema, "id_factura", id_factura);

        if (dbObj && dbObj.length && dbObj.length > 0) {
          return {
            status: "ok",
            data: {
              ...requestBodyObj.data,
              obj_factura: { ...requestBodyObj["obj_factura"] },
            },
          };

          // return ServerUtil.exitWithData( respObj );
        }

        return ServerUtil.exitWithError();
      },
      { timing: Settings.MIRAGEJS_TIMING }
    );
  },

  addSeeds(server) {
    // usamos los mismos seeds de facturas

    facturasGetAllSeed.forEach((factura) => {
      server.create("factura", factura);
    });
  },

  models: {
    factura: Model,
  },
};

export default facturasServer;
