import Fx from "@/lib/functions";
import { id_poblacion, id_provincia } from "@/lib/tunuveFx";

const actualizarFormDataFactura = function (formData, objNewData) {
  formData.num_factura = objNewData.num_factura;
  formData.nif = objNewData.nif;
  formData.nombre = objNewData.nombre;

  // campos raíz
  Object.keys(objNewData).map((item) => {
    if (
      formData[item] != undefined &&
      (typeof objNewData[item] == "string" ||
        typeof objNewData[item] == "number")
    ) {
      if (item.indexOf("f_") == 0) {
        objNewData[item] = objNewData[item].substr(0, 10);
      }
      formData[item] = objNewData[item];
    }
  });

  formData.id_contacto = 0; // tiene que ser 0
  formData.poblacion = objNewData.poblacion;
  formData.provincia = objNewData.provincia;

  formData.id_poblacion =
    objNewData.id_poblacion > 0
      ? objNewData.id_poblacion
      : id_poblacion(objNewData.poblacion);

  formData.id_provincia =
    objNewData.id_provincia > 0
      ? objNewData.id_provincia
      : id_provincia(objNewData.id_provincia, objNewData.cp);

  // Añadir conceptos
  if (Fx.is_array(objNewData.arr_conceptos)) {
    objNewData.arr_conceptos.map((objConcepto, index) => {
      let importe = Fx.twoDecimals(objConcepto.base_imponible),
        iva_perc = Fx.twoDecimals(objConcepto.iva_perc),
        total_iva = Fx.twoDecimals(importe * (iva_perc / 100));

      formData.arr_conceptos[index] = {
        concepto: objConcepto.concepto,
        cantidad: 1,
        importe: importe,
        iva_perc: iva_perc,
        total_iva: total_iva,
        total_concepto: Fx.twoDecimals(importe + total_iva),
        base_imponible: importe,
      };
    });
  }

  // Añadir totales
  formData.arr_totales.total_bases_imponibles = Fx.sumByKey(
    formData.arr_conceptos,
    "importe"
  );
  formData.arr_totales.total_conceptos = Fx.sumByKey(
    formData.arr_conceptos,
    "total_concepto"
  );

  formData.importe = formData.arr_totales.total_bases_imponibles;
  formData.total_conceptos = Fx.euros(formData.arr_totales.total_conceptos);
  formData.total_irpf =
    formData.arr_totales.total_bases_imponibles * (formData.irpf_perc / 100);

  formData.total_factura = Fx.euros(
    formData.arr_totales.total_conceptos - formData.total_irpf
  );
};

function resetData(formData) {
  Object.keys(formData).map((key) => {
    if (typeof formData[key] == "number") {
      formData[key] = 0;
    }

    if (typeof formData[key] == "string") {
      formData[key] = "";
    }

    formData.arr_conceptos.map((objConcepto, index) => {
      formData.arr_conceptos[index] = {
        concepto: "",
        cantidad: 1,
        importe: 0,
        iva_perc: 0,
        total_iva: 0,
        total_concepto: 0,
        base_imponible: 0,
      };
    });
  });
}

export { actualizarFormDataFactura, resetData };
