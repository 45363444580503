export const objFacturaDefault = {
  error: "",
  opcion: "factura",
  id_factura: 0,
  id_gasto: 0,
  id_ppto: 0,
  contabilizado: 0,
  num_asiento: "",
  id_empresa: 0,
  id_contacto: 0,
  id_serie: 0,
  id_cod_analitica: 0,
  ids_etiquetas: "",
  num_factura: "",
  f_factura: "0000-00-00",
  // "f_factura": "2021-09-01",
  importe: 0,
  irpf_perc: 0,
  total_iva: 0,
  total_irpf: 0,
  total_rec_equiv: 0,
  total_pte: 0,
  total_factura: 0,
  etiquetas: "",
  obs_factura: "",
  modo_pago: "",
  num_cuenta: "",
  archivo: "",
  archivo_usuario: "",
  inmovilizado: 0,
  archivo_conta: "",
  f_contable: "0000-00-00",
  contrapartida: "",
  enviado: 0,
  exportado: 0,
  nombre: "",
  nif: "",
  email: "",
  direccion: "",
  poblacion: "",
  provincia: "",
  id_poblacion: "",
  id_provincia: "",
  cp: "",
  obs_factura_contacto: "",
  contacto_revisado: 0,
  arr_conceptos: [
    {
      concepto: "",
      importe: 0,
      iva_perc: 0,
      total_concepto: 0,
    },
    {
      concepto: "",
      importe: 0,
      iva_perc: 0,
      total_concepto: 0,
    },
    {
      concepto: "",
      importe: 0,
      iva_perc: 0,
      total_concepto: 0,
    },
    {
      concepto: "",
      importe: 0,
      iva_perc: 0,
      total_concepto: 0,
    },
    {
      concepto: "",
      importe: 0,
      iva_perc: 0,
      total_concepto: 0,
    },
  ],
  arr_totales: {
    total_bases_imponibles: 0,
    total_iva: 0,
    total_rec_equiv: 0,
    total_suplidos: 0,
    total_conceptos: 0,
  },
  arr_bases_imponibles: [],
  arr_rec_equiv: [],
  arr_vtos: [],
  arr_pagos: [],
  id_asesoria: "3",
  empresa: "",
  nif_empresa: "",
  direccion_empresa: "",
  poblacion_empresa: "",
  provincia_empresa: "",
  id_poblacion_empresa: "",
  id_provincia_empresa: "",
  cp_empresa: "",
  prefijo_serie: "",
  cod_analitica: "",
  cod_analitica_ws: "",
  descuadre: 0,
};
