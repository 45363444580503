<template>
  <div class="row">
    <div class="col-12 text-left text-dark">
      <br /><br /><br />
      <iframe
        id="iframe_juanjo"
        src="https://www.videoask.com/f9qkk9gnk"
        allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

import Fx from "@/lib/functions";

export default {
  name: "AltaOk",

  setup() {
    const store = useStore();

    function getToken() {
      if (window.location.href.indexOf("alta-ok") == -1) {
        return "";
      }

      let url = window.location.href
        .replace("https://facturas.tunuve.com/alta-ok/", "")
        .replace("https://respirainternet.com/demo/tunuve-facturas/alta-ok/", "")
        .replace("http://localhost:8080/alta-ok/", "")
        .replace("http://localhost:3000/alta-ok/", "");

      let token = "",
        id_asesor = getIdAsesor(),
        id_empresa = getIdEmpresa();

      token = url.replace("/" + id_empresa, "").replace("/" + id_asesor, "");

      return token;
    }

    function getIdEmpresa() {
      let url = window.location.href,
        arr_url = url.split("/");

      let id_empresa = arr_url.pop();

      if (!Fx.is_integer(id_empresa)) {
        return 0;
      }

      return id_empresa;
    }

    function getIdAsesor() {
      let url = window.location.href,
        arr_url = url.split("/");

      let id_asesor = arr_url[arr_url.length - 2];

      if (!Fx.is_integer(id_asesor)) {
        return 0;
      }

      return id_asesor;
    }

    function abrirAcceso() {
      let token = getToken();

      if (token && token.length > 5) {
        var objStorage = {
          token,
          id_asesor: getIdAsesor(),
          id_empresa: getIdEmpresa(),
        };
        store.dispatch("logIn", objStorage);
      } else {
        Fx.simulateClick("#btn_acceso");
      }
    }

    abrirAcceso();

    return {};
  },
};
</script>

<style scoped>
#iframe_juanjo {
  border: none;
  border-radius: 24px;
  padding: 40px;
  background: #fff;
  margin-bottom: 60px;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  min-height: 800px;
}
</style>
