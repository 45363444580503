const arrIVA = [
  {
    label: "0%",
    value: 0,
  },
  {
    label: "4%",
    value: 4,
  },
  {
    label: "10%",
    value: 10,
  },
  {
    label: "21%",
    value: 21,
  },
];

export { arrIVA };
