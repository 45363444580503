import Settings from "../settings";
// import Moment from "moment";
import { format, parseISO } from "date-fns";

import { serialize } from "object-to-formdata";
// import { encode } from 'html-entities';

import CryptoJS from "crypto-js";

const Fx = {
  sumByKey: function (arr, key) {
    if (!Fx.is_array(arr) || arr.length == 0) {
      return 0;
    }

    return arr.reduce((a, b) => a + (parseFloat(b[key]) || 0), 0);
  },

  clickSelectorAfter(selector, miliseconds = 2000) {
    return window.setTimeout(function () {
      Fx.simulateClick(selector);
    }, miliseconds);
  },

  simulateClick(selector) {
    var elem = document.querySelector(selector);

    if (!elem) {
      console.log("No se encontró el elemento");
      return false;
    }

    // Create our event (with options)
    var evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    // If cancelled, don't dispatch our event
    var canceled = !elem.dispatchEvent(evt);

    return !canceled;
  },

  callback() {
    return function (res) {
      if (res && res.json) {
        return res.json();
      }

      return function () {};
    };
  },

  create_UUID() {
    // https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
    var dt = new Date().getTime();

    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  },

  encryptStr(str) {
    if (typeof str !== "string") {
      return "";
    }

    const passphrase = Settings.CRYPTO_JS_PASSPHRASE;
    return CryptoJS.AES.encrypt(str, passphrase).toString();
  },

  decryptStr(str) {
    if (typeof str !== "string") {
      return "";
    }

    const passphrase = Settings.CRYPTO_JS_PASSPHRASE;

    const bytes = CryptoJS.AES.decrypt(str, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);

    return originalText;
  },

  // ARRAY FUNCTIONS
  deleteKeysFromArray(arrData, arrKeys) {
    return arrData.map(function (item) {
      arrKeys.forEach(function (key) {
        delete item[key];
      });

      return item;
    });
  },

  arrToSelect2Obj(arr) {
    // We use this format for vue-select too because
    // if your id are strings, they are taken as labels by default
    // even if you set the label attribute when instantiating the object
    return arr.map((elem, index) => {
      return {
        id: index,
        text: elem,
      };
    });
  },

  /*
  arrToSelectVueObj(arr) {
    return arr.map((elem, index) => {
      return {
        value: index,
        label: elem
      };
    });
  },
  */

  maxArrObjValue(arrObj, key) {
    // returns the max value of an attribute in an array of objects
    // https://stackoverflow.com/questions/4020796/finding-the-max-value-of-an-attribute-in-an-array-of-objects

    return Math.max.apply(
      Math,
      arrObj.map(function (o) {
        return o[key];
      })
    );
  },

  getListText(arr, idValue) {
    // returns the text corresponding to an array from a list in lists.js

    return arr.filter((filteredArr) => {
      return filteredArr.id == idValue;
    })[0].text;
  },

  // DATE FUNCTIONS
  dateFormat(dateString, locale = "es") {
    // var format = Settings.DATE_FORMAT[locale];
    // return Moment(dateString).locale(locale).format(format);

    let date = parseISO(dateString);

    let localizedFormat = locale == "es" ? "dd/MM/yyyy" : "MM/dd/yyyy";
    return format(date, localizedFormat);
  },

  getTime() {
    var d = new Date(),
      time = d.getTime();

    return time;
  },

  today(dateFormat = "dd/MM/yyyy") {
    return format(new Date(), dateFormat);
  },

  dateStrToObj(dateString) {
    if (typeof dateString != "string") {
      return {};
    }

    if (dateString.indexOf("/") > -1) {
      let arr = dateString.split("/");
      dateString = arr[2] + "-" + arr[1] + "-" + arr[0];
    }

    if (dateString.length == 10) {
      dateString += " 00:00:00";
    }

    const date = new Date(dateString),
      isoDate = date.toISOString();

    // convierte fecha tipo date
    return parseISO(isoDate);
  },

  // IMAGE FUNCTIONS
  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  },

  // DOM utility functions
  idExists(id) {
    return document.querySelector("#" + id);
  },

  hideEl(selector) {
    document.querySelector(selector).style.display = "none";
  },

  val(selector) {
    const el = document.querySelector(selector);

    if (!el || !el.value || el.value == "" || typeof el.value == "undefined") {
      return "";
    }

    return el.value;
  },

  hasClass(selector, className) {
    const el = document.querySelector(selector);

    if (!el) {
      return false;
    }

    return el.classList.contains(className);
  },

  addClass(className, selector) {
    var elems = document.querySelectorAll(selector);

    [].forEach.call(elems, function (el) {
      el.classList.add(className);
    });
  },

  removeClass(className, selector) {
    var elems = document.querySelectorAll(selector);

    [].forEach.call(elems, function (el) {
      el.classList.remove(className);
    });
  },

  hasAttr(selector, attrName) {
    const el = document.querySelector(selector);

    if (!el) {
      return false;
    }

    return el.hasAttribute(attrName);
  },

  attrContains(selector, attrName, attrValue) {
    const el = document.querySelector(selector);

    if (!el || !this.hasAttr(selector, attrName)) {
      return false;
    }

    return el.getAttribute(attrName).indexOf(attrValue) > -1;
  },

  getAttr(selector, attrName) {
    return document.querySelector(selector).getAttribute(attrName) || "";
  },

  attr(e, attrName) {
    if (typeof e != "object") {
      return "";
    }
    if (typeof e.target.attributes[attrName] == "undefined") {
      return "";
    }

    return e.target.attributes[attrName].value;
  },

  htmlToElement(html) {
    // https://stackoverflow.com/questions/494143/creating-a-new-dom-element-from-an-html-string-using-built-in-dom-methods-or-pro/35385518#35385518
    var template = document.createElement("template");
    html = html.trim();
    template.innerHTML = html;
    return template.content.firstChild;
  },

  setFocus(id) {
    window.setTimeout(() => {
      let el = document.getElementById(id);
      if (el) {
        el.focus();
      }
    }, 1000);
  },

  // NUMBER CONVERSION FUNCTIONS
  removeEuro(value) {
    // devuelve un número que corresponede al value de una cadena en euros

    if (typeof value != "string") {
      return 0;
    }

    value = value
      .replace("€", "")
      .replace(".", "")
      .replace(".", "")
      .replace(".", "")
      .replace(",", ".");

    return this.twoDecimals(value);
  },

  euros(value, decimals) {
    if (typeof decimals === "undefined") {
      decimals = 2;
    }
    return this.numberFormat(value, decimals, ",", ".") + "€";
  },

  noDecimals(value) {
    return this.round(value, 0);
  },

  twoDecimals(value) {
    return this.round(value, 2);
  },

  round(value, decimals) {
    if (isNaN(value)) {
      value = 0;
    }
    return Number(
      parseFloat(Math.round(value + "e" + decimals) + "e-" + decimals)
    );
  },

  perc(value, decimals = 0) {
    // devuelve un value tipo float como formato porcentaje
    value = value * 100;
    value = Fx.round(value, decimals);

    return value + "%";
  },

  numberFormat(number, decimals, dec_point, thousands_sep) {
    //  discuss at: http://phpjs.org/functions/numberFormat/

    number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
      dec = typeof dec_point === "undefined" ? "." : dec_point,
      s = "",
      toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec);
        return "" + (Math.round(n * k) / k).toFixed(prec);
      };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return s.join(dec);
  },

  // DATA TYPE CHECK FUNCTIONS
  is_array: function (obj) {
    return obj && obj.length !== undefined;
  },

  is_object: function (obj) {
    return typeof obj === "object" && obj !== null;
  },

  is_numeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  },

  is_integer(val) {
    if (val == null) {
      return false;
    }
    if (val.length == 0) {
      return false;
    }
    for (var i = 0; i < val.length; i++) {
      var ch = val.charAt(i);
      if (i == 0 && ch == "-") {
        continue;
      }
      if (ch < "0" || ch > "9") {
        return false;
      }
    }
    return true;
  },

  isValidJson(jsonString) {
    try {
      JSON.parse(jsonString);
    } catch (exception) {
      return false;
    }

    return true;
  },

  prettyJson(obj) {
    return JSON.stringify(obj, null, 2);
  },

  // LOGGING FUNCTIONS
  logFormData(model) {
    for (var key of model.entries()) {
      console.info("FormData: " + key[0] + ": " + key[1]);
    }
  },

  // FETCH FUNCTIONS
  get: function (url, params, callback) {
    // console.log("[GET] URL: " + url);
    // console.log(params, "[GET] params: ");

    const urlObj = new URL(url);

    if (params) {
      urlObj.search = new URLSearchParams(params).toString();
    }

    return fetch(urlObj)
      .then((resp) => Fx.checkError(resp))
      .then(callback());
  },

  post(url, dataObj, method = "POST", callback) {
    if (!Settings.USE_HTTP_VERBS && (method == "PUT" || method == "DELETE")) {
      dataObj.method = method;
      method = "POST";
    }

    var body =
      method == "POST"
        ? serialize(dataObj, { indices: true })
        : JSON.stringify(dataObj);

    return fetch(url, {
      cache: "no-cache",
      method,
      credentials: "omit",
      // mode:
      //   process.env.NODE_ENV == "production" ||
      //   process.env.NODE_ENV == "staging" ||
      //   Settings.USE_MIRAGEJS === false
      //     ? "cors"
      //     : "same-origin",
      // mode: "cors",
      mode: "cors",
      body: body,
    })
      .then((resp) => Fx.checkError(resp))
      .then(callback());
  },

  put(url, dataObj, callback) {
    return Fx.post(url, dataObj, "PUT", callback);
  },

  delete(url, dataObj, callback) {
    return Fx.post(url, dataObj, "DELETE", callback);
  },

  postFile(url, dataObj, fileObj, callback) {
    var inputFileName = fileObj.inputFileName,
      fileName = fileObj.fileName,
      fileContent = fileObj.fileContent;

    var model = new FormData();

    model.append(inputFileName, fileContent, fileName);

    if (typeof dataObj == "object") {
      Object.keys(dataObj).forEach((key) => {
        model.append(key, dataObj[key]);
      });
    }

    return fetch(url, {
      method: "post",
      body: model,
    }).then(callback());
  },

  checkError(resp) {
    if (resp == undefined) {
      return resp;
    }

    // if (resp.status == "401" || resp.status == "409") {
    if (resp.status == "401") {
      localStorage.removeItem(Settings.APP_CODE);
      window.location.href = "/logout";
    }

    return resp;
  },

  noop() {
    return null;
  },
};

export default Fx;
