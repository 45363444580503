<template>
  <a class="btn" :class="bsClass" :href="href">
    <i v-if="icon != ''" :class="'bi bi-' + icon"></i>
    {{ text }}
  </a>
</template>

<script>
export default {
  name: "SimpleButton",

  props: {
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    href: {
      type: String,
      default: "#",
    },
    bsClass: {
      type: String,
      default: "btn-primary",
    },
    bsSize: {
      type: String,
      default: "",
    },
  },
};
</script>
