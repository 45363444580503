export default [
  {
    bic: "",
    contrapartida: "",
    cp: "08340",
    cuenta: "",
    cuenta_contable: "",
    cuenta_contable_geyce: "",
    direccion: "C/Del Rosari 71 Bis",
    dto_perc: "0",
    email: "info@aixhomebarcelona.com",
    etiquetas: "",
    f_alta: "2016-04-19 03:13:59",
    fax: "",
    honorarios_mes: "0",
    iban: "",
    id_contacto: "10533",
    id_empresa: "69",
    id_mandato_sepa: "",
    id_pf_cuenta_bancaria: "0",
    id_tipo_contacto: "0",
    id_tipo_gasto_pf: "0",
    id_tipo_transaccion: "0",
    id_tipo_vto: "0",
    ids_etiquetas: "2",
    irpf_perc: "0",
    iva_perc: "0",
    nif: "38833911K",
    nombre: "Adriana Aixemeno Mostajo",
    nombre_comercial: "",
    nombre_contrapartida: "",
    num_dias_vto_contacto: "0",
    obs_contacto: "",
    obs_factura_contacto: "",
    pais: "10",
    poblacion: "VILASSAR DE MAR",
    poblacion_subida: "Vilassar",
    precio_hora: "0",
    provincia: "8",
    revisado: "0",
    telefono1: "",
    telefono2: "",
  },
  {
    bic: "",
    contrapartida: "",
    cp: "38004",
    cuenta: "",
    cuenta_contable: "",
    cuenta_contable_geyce: "",
    direccion: "",
    dto_perc: "0",
    email: "",
    etiquetas: "",
    f_alta: "2017-07-12 22:25:40",
    fax: "",
    honorarios_mes: "0",
    iban: "",
    id_contacto: "260255",
    id_empresa: "69",
    id_mandato_sepa: "",
    id_pf_cuenta_bancaria: "0",
    id_tipo_contacto: "0",
    id_tipo_gasto_pf: "0",
    id_tipo_transaccion: "0",
    id_tipo_vto: "0",
    ids_etiquetas: "",
    irpf_perc: "0",
    iva_perc: "0",
    nif: "B76632793",
    nombre: "ADVENTUREROS PLATAFORMA",
    nombre_comercial: "",
    nombre_contrapartida: "",
    num_dias_vto_contacto: "0",
    obs_contacto: "",
    obs_factura_contacto: "",
    pais: "10",
    poblacion: "Barcelona",
    poblacion_subida: "",
    precio_hora: "0",
    provincia: "",
    revisado: "0",
    telefono1: "",
    telefono2: "",
  },
  {
    bic: "",
    contrapartida: "7000000",
    cp: "29620",
    cuenta: "",
    cuenta_contable: "4300175",
    cuenta_contable_geyce: "",
    direccion: "",
    dto_perc: "0",
    email: "",
    etiquetas: "",
    f_alta: "2015-12-15 05:27:07",
    fax: "",
    honorarios_mes: "0",
    iban: "",
    id_contacto: "1988",
    id_empresa: "69",
    id_mandato_sepa: "",
    id_pf_cuenta_bancaria: "0",
    id_tipo_contacto: "0",
    id_tipo_gasto_pf: "0",
    id_tipo_transaccion: "0",
    id_tipo_vto: "0",
    ids_etiquetas: "",
    irpf_perc: "0",
    iva_perc: "0",
    nif: "B93123933",
    nombre: "AGUA DE CEPAS, S.L.L.",
    nombre_comercial: "",
    nombre_contrapartida: "",
    num_dias_vto_contacto: "0",
    obs_contacto: "",
    obs_factura_contacto: "",
    pais: "10",
    poblacion: "Málaga",
    poblacion_subida: "",
    precio_hora: "0",
    provincia: "29",
    revisado: "1",
    telefono1: "",
    telefono2: "",
  },
  {
    bic: "",
    contrapartida: "",
    cp: "28037",
    cuenta: "",
    cuenta_contable: "",
    cuenta_contable_geyce: "",
    direccion: "",
    dto_perc: "0",
    email: "",
    etiquetas: "",
    f_alta: "2017-07-12 22:20:08",
    fax: "",
    honorarios_mes: "0",
    iban: "",
    id_contacto: "260253",
    id_empresa: "69",
    id_mandato_sepa: "",
    id_pf_cuenta_bancaria: "0",
    id_tipo_contacto: "0",
    id_tipo_gasto_pf: "0",
    id_tipo_transaccion: "0",
    id_tipo_vto: "0",
    ids_etiquetas: "",
    irpf_perc: "0",
    iva_perc: "0",
    nif: "B85820868",
    nombre: "AHORRAMOTO 2010, SL",
    nombre_comercial: "",
    nombre_contrapartida: "",
    num_dias_vto_contacto: "0",
    obs_contacto: "",
    obs_factura_contacto: "",
    pais: "10",
    poblacion: "Madrid",
    poblacion_subida: "",
    precio_hora: "0",
    provincia: "",
    revisado: "0",
    telefono1: "",
    telefono2: "",
  },
  {
    bic: "",
    contrapartida: "",
    cp: "28001",
    cuenta: "",
    cuenta_contable: "",
    cuenta_contable_geyce: "",
    direccion: "desconocida",
    dto_perc: "0",
    email: "",
    etiquetas: "",
    f_alta: "2018-04-18 20:06:01",
    fax: "",
    honorarios_mes: "0",
    iban: "",
    id_contacto: "354838",
    id_empresa: "69",
    id_mandato_sepa: "",
    id_pf_cuenta_bancaria: "0",
    id_tipo_contacto: "0",
    id_tipo_gasto_pf: "0",
    id_tipo_transaccion: "0",
    id_tipo_vto: "0",
    ids_etiquetas: "",
    irpf_perc: "0",
    iva_perc: "0",
    nif: "11111111H",
    nombre: "Alejandro Blanco",
    nombre_comercial: "",
    nombre_contrapartida: "",
    num_dias_vto_contacto: "0",
    obs_contacto: "",
    obs_factura_contacto: "",
    pais: "10",
    poblacion: "Madrid",
    poblacion_subida: "",
    precio_hora: "0",
    provincia: "28",
    revisado: "0",
    telefono1: "",
    telefono2: "",
  },
  {
    bic: "",
    contrapartida: "",
    cp: "28001",
    cuenta: "",
    cuenta_contable: "",
    cuenta_contable_geyce: "",
    direccion: "",
    dto_perc: "0",
    email: "",
    etiquetas: "",
    f_alta: "2016-06-04 16:05:36",
    fax: "",
    honorarios_mes: "0",
    iban: "",
    id_contacto: "12263",
    id_empresa: "69",
    id_mandato_sepa: "",
    id_pf_cuenta_bancaria: "0",
    id_tipo_contacto: "0",
    id_tipo_gasto_pf: "0",
    id_tipo_transaccion: "0",
    id_tipo_vto: "0",
    ids_etiquetas: "",
    irpf_perc: "0",
    iva_perc: "0",
    nif: "B87312724",
    nombre: "ALIANZA ABOGADOS SL",
    nombre_comercial: "",
    nombre_contrapartida: "",
    num_dias_vto_contacto: "0",
    obs_contacto: "",
    obs_factura_contacto: "",
    pais: "10",
    poblacion: "Madrid",
    poblacion_subida: "",
    precio_hora: "0",
    provincia: "",
    revisado: "0",
    telefono1: "",
    telefono2: "",
  },
  {
    bic: "",
    contrapartida: "7000000",
    cp: "46004",
    cuenta: "",
    cuenta_contable: "4300177",
    cuenta_contable_geyce: "",
    direccion: "C/Cirilo Amoros 51.1.1",
    dto_perc: "0",
    email: "aliciagallegom@gmail.com",
    etiquetas: "housinginmobiliaria.com",
    f_alta: "2016-01-15 04:08:19",
    fax: "",
    honorarios_mes: "0",
    iban: "",
    id_contacto: "3058",
    id_empresa: "69",
    id_mandato_sepa: "",
    id_pf_cuenta_bancaria: "0",
    id_tipo_contacto: "0",
    id_tipo_gasto_pf: "0",
    id_tipo_transaccion: "0",
    id_tipo_vto: "0",
    ids_etiquetas: "395",
    irpf_perc: "0",
    iva_perc: "0",
    nif: "24370712G",
    nombre: "ALICIA GALLEGO MARTINEZ",
    nombre_comercial: "",
    nombre_contrapartida: "",
    num_dias_vto_contacto: "0",
    obs_contacto: "",
    obs_factura_contacto: "",
    pais: "10",
    poblacion: "Valencia",
    poblacion_subida: "",
    precio_hora: "0",
    provincia: "46",
    revisado: "1",
    telefono1: "",
    telefono2: "",
  },
  {
    bic: "",
    contrapartida: "",
    cp: "46004",
    cuenta: "",
    cuenta_contable: "",
    cuenta_contable_geyce: "",
    direccion: "C/Cirilo Amoros 51.1.1",
    dto_perc: "0",
    email: "aliciagallegom@gmail.com",
    etiquetas: "housinginmobiliaria.com",
    f_alta: "2018-06-04 12:21:06",
    fax: "",
    honorarios_mes: "0",
    iban: "ES60 1465 0300 61 1900556428",
    id_contacto: "379772",
    id_empresa: "69",
    id_mandato_sepa: "",
    id_pf_cuenta_bancaria: "0",
    id_tipo_contacto: "0",
    id_tipo_gasto_pf: "0",
    id_tipo_transaccion: "0",
    id_tipo_vto: "0",
    ids_etiquetas: "395",
    irpf_perc: "0",
    iva_perc: "21",
    nif: "B98915507",
    nombre: "Alicia Gallego Martínez, S.L.",
    nombre_comercial: "",
    nombre_contrapartida: "",
    num_dias_vto_contacto: "0",
    obs_contacto: "",
    obs_factura_contacto: "",
    pais: "10",
    poblacion: "Valencia",
    poblacion_subida: "",
    precio_hora: "0",
    provincia: "46",
    revisado: "1",
    telefono1: "",
    telefono2: "",
  },
  {
    bic: "",
    contrapartida: "",
    cp: "46007",
    cuenta: "",
    cuenta_contable: "",
    cuenta_contable_geyce: "",
    direccion: "",
    dto_perc: "0",
    email: "",
    etiquetas: "",
    f_alta: "2016-03-15 17:50:20",
    fax: "",
    honorarios_mes: "0",
    iban: "",
    id_contacto: "6652",
    id_empresa: "69",
    id_mandato_sepa: "",
    id_pf_cuenta_bancaria: "0",
    id_tipo_contacto: "0",
    id_tipo_gasto_pf: "0",
    id_tipo_transaccion: "0",
    id_tipo_vto: "0",
    ids_etiquetas: "",
    irpf_perc: "0",
    iva_perc: "0",
    nif: "48016183A",
    nombre: "ANAIS GOMEZ CABALLER",
    nombre_comercial: "",
    nombre_contrapartida: "",
    num_dias_vto_contacto: "0",
    obs_contacto: "",
    obs_factura_contacto: "",
    pais: "10",
    poblacion: "Valencia",
    poblacion_subida: "",
    precio_hora: "0",
    provincia: "",
    revisado: "0",
    telefono1: "",
    telefono2: "",
  },
  {
    bic: "",
    contrapartida: "",
    cp: "08397",
    cuenta: "",
    cuenta_contable: "",
    cuenta_contable_geyce: "",
    direccion: "C/11 Setembre 4 local",
    dto_perc: "0",
    email: "toni@intermaresme.com",
    etiquetas: "intermaresme.com, Toni, Antonio Domingo",
    f_alta: "2016-04-19 03:23:36",
    fax: "",
    honorarios_mes: "0",
    iban: "",
    id_contacto: "10534",
    id_empresa: "69",
    id_mandato_sepa: "",
    id_pf_cuenta_bancaria: "0",
    id_tipo_contacto: "0",
    id_tipo_gasto_pf: "0",
    id_tipo_transaccion: "0",
    id_tipo_vto: "0",
    ids_etiquetas: "420,421,422",
    irpf_perc: "0",
    iva_perc: "0",
    nif: "46142002T",
    nombre: "Antonio Domingo Selfa",
    nombre_comercial: "INTERMARESME",
    nombre_contrapartida: "",
    num_dias_vto_contacto: "0",
    obs_contacto: "",
    obs_factura_contacto: "",
    pais: "10",
    poblacion: "Pineda de Mar",
    poblacion_subida: "",
    precio_hora: "0",
    provincia: "8",
    revisado: "0",
    telefono1: "",
    telefono2: "",
  },
];
