export default [
  {
    error: "",
    opcion: "factura",
    id_factura: "1709003",
    id_gasto: 1709003,
    id_ppto: 1709003,
    contabilizado: "1",
    num_asiento: "10",
    id_empresa: "69",
    id_contacto: "10533",
    id_serie: "0",
    id_cod_analitica: "3",
    ids_etiquetas: "",
    num_factura: "32242",
    f_factura: "2020-01-01 00:00:00",
    f_contable: "2020-01-01",
    contrapartida: "",
    importe: "159.95",
    irpf_perc: "0",
    total_iva: "33.59",
    total_irpf: "0",
    total_rec_equiv: "0",
    total_pte: "0",
    total_factura: "193.54",
    etiquetas: "",
    obs_factura:
      "Si no tiene su recibo domiciliado, puede pagar en la cuenta: ES24 0182 4022 1802 0165 5351",
    modo_pago: "Recibo",
    num_cuenta: "",
    archivo: "",
    archivo_usuario: "",
    inmovilizado: "0",
    f_alta: "2020-01-01 00:04:16",
    f_contabilizacion: "2020-01-15 12:52:28",
    archivo_conta: "",
    enviado: null,
    exportado: "1",
    nombre: "Adriana Aixemeno Mostajo",
    nif: "38833911K",
    email: "info@aixhomebarcelona.com",
    direccion: "C/Del Rosari 71 Bis",
    poblacion: "Vilassar de Mar",
    cp: "08340",
    obs_factura_contacto: "",
    contacto_revisado: "0",
    arr_conceptos: [
      {
        concepto: "Dominio + gesti\u00f3n de dominio 1 a\u00f1o",
        importe: "20",
        cantidad: "1",
        dto: "0",
        iva_perc: "21",
        irpf_perc: "0",
        rec_equiv: "0",
        cod_iva: "RSN",
        tiene_recargo: "0",
        es_suplido: "0",
        base_imponible: 20,
        total_iva: 4.2,
      },
      {
        concepto: "Renovaci\u00f3n Hosting Web Administrable 1 a\u00f1o",
        importe: "139.95",
        cantidad: "1",
        dto: "0",
        iva_perc: "21",
        irpf_perc: "0",
        rec_equiv: "0",
        cod_iva: "RSN",
        tiene_recargo: "0",
        es_suplido: "0",
        base_imponible: 139.95,
        total_iva: 29.39,
      },
    ],
    arr_totales: {
      total_bases_imponibles: 159.95,
      total_iva: 33.59,
      total_rec_equiv: 0,
      total_suplidos: 0,
      total_conceptos: 193.54,
    },
    arr_bases_imponibles: { 21: 159.95 },
    arr_rec_equiv: [],
    arr_vtos: [
      {
        f_vto: "2020-01-01 00:00:00",
        importe: "193.54",
        id_pf_cuenta_bancaria: "0",
      },
    ],
    arr_pagos: [
      {
        id_factura_pago: "406904",
        f_pago: "2020-01-01 00:00:00",
        canal: "Caja",
        importe: "193.54",
        rec_equiv_perc: null,
        contabilizado: "1",
        num_asiento: null,
        id_mvto_checkit: "0",
        id_mvto_dividido: "0",
        id_movimiento: "",
      },
    ],
    id_asesoria: "3",
    empresa: "Respira Internet, SLU",
    nif_empresa: "B54657259",
    prefijo_serie: "",
    cod_analitica: "INMOLITE",
    cod_analitica_ws: "INMOLITE",
  },
];
