<template>
  <Mensaje msg="404. No encontrado" image="not_found.png">
    <p>Lo sentimos. No hemos encontrado la página solicitada.</p>
    <p>
      <router-link class="btn btn-primary" to="/">Ir a inicio</router-link>
      &nbsp;
      <router-link class="btn btn-primary" to="/factura">Facturar</router-link>
    </p>
  </Mensaje>
</template>

<script>
import Mensaje from "@/components/Mensaje.vue";

export default {
  name: "Bienvenido",
  components: {
    Mensaje,
  },
};
</script>
