<template>
  <div class="main">
    <section-header
      title="Mis contactos"
      icon="people"
      link_to="/"
      link_text="Ir a inicio"
    />

    <div class="container view_container">
      <CRUDTable
        :url="url"
        :params="params"
        :columns="columns"
        :dataMapFx="dataMapFx"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

import Settings from "@/settings";
import { dateSpan, tableBtn } from "@/lib/datatables-fx";

import SectionHeader from "@/components/SectionHeader.vue";
import CRUDTable from "@/components/CRUDTable.vue";

export default {
  name: "Contactos",

  components: {
    SectionHeader,
    CRUDTable,
  },

  setup() {
    const store = useStore();

    const url = Settings.BASE_API + "contactos/",
      params = {
        hash: store.state.token,
        accion: "consultar",
        opcion: "factura",
        id_empresa: store.state.id_empresa,
      };

    const columns = [
      { title: "Id." },
      { title: "Nif" },
      { title: "Nombre" },
      { title: "Población" },
      { title: "F. creación" },
      {
        data: null,
        title: "Acciones",
        class: "acciones",

        render: function (elem) {
          let id_contacto = elem[0],
            href = process.env.VUE_APP_BASE_APP + "contacto/" + id_contacto;

          let btnEditar = tableBtn({
            href: href,
            icon: "pen",
            label: "Editar",
            btnClass: "btn-primary",
          });

          return btnEditar;
        },
      },
    ];

    function dataMapFx(item) {
      let arr_campos = [
          "id_contacto",
          "nif",
          "nombre",
          "poblacion",
          // "nombre_comercial",
          // "email",
        ],
        arr_aux = [];

      arr_aux = arr_campos.map(function (campo) {
        return item[campo];
      });

      arr_aux.push(dateSpan(item.f_alta));

      return arr_aux;
    }

    return {
      url,
      params,
      columns,
      dataMapFx,
    };
  },
};
</script>
