<template>
  <div class="main">
    <section-header
      title="Modificar contacto"
      icon="people"
      link_to="/"
      link_text="Ir a inicio"
    />

    <div class="container view_container cuerpo_factura">
      <div v-if="loading">
        <Spinner />
      </div>

      <BSAlert
        :hide="!serverError || loading"
        bsClass="danger"
        icon="exclamation-diamond"
      >
        Error en el servidor. {{ serverErrorTxt }}
      </BSAlert>

      <div class="row">
        <div class="col-12 col-md-6">
          <ContactoForm
            v-if="!loading && !serverError"
            :formData="formData"
            @formSubmitted="saveFormData"
            :saveButtonLoading="saveButtonLoading"
          />

          <BSAlert
            class="mt-3"
            :hide="serverError || loading || !dataSaved"
            bsClass="success"
            icon="check-circle"
          >
            Los datos se guardaron con éxito.
          </BSAlert>
        </div>

        <div class="col-6 d-none d-md-block text-center">
          <div style="padding: 100px; margin-top: 100px">
            <img
              src="@/assets/img/waiting.png"
              class="w-100"
              style="max-height: 500px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import SectionHeader from "@/components/SectionHeader.vue";
import Spinner from "@/components/Spinner.vue";
import BSAlert from "@/components/BSAlert.vue";

import Settings from "@/settings";
import Fx from "@/lib/functions";
import { id_poblacion, id_provincia } from "@/lib/tunuveFx";

import ContactoForm from "@/views/contactos/ContactoForm.vue";

export default {
  name: "Contacto",

  components: {
    Spinner,
    BSAlert,
    ContactoForm,
    SectionHeader,
  },

  setup() {
    const store = useStore();

    const loading = ref(true),
      serverError = ref(false),
      serverErrorTxt = ref(""),
      saveButtonLoading = ref(false),
      dataSaved = ref(false);

    const formData = {
      nombre: "",
      nif: "",
      direccion: "",
      id_poblacion: "",
      id_provincia: "0",
      cp: "",
    };

    const id_contacto = window.location.href.split("/").pop();

    async function loadData() {
      loading.value = true;
      setError(false);

      const url = Settings.BASE_API + "contactos/",
        params = {
          hash: store.state.token,
          accion: "consultar",
          tipo_contacto: "cliente",
          id_empresa: store.state.id_empresa,
          id_contacto: id_contacto,
        };

      const data = await Fx.get(url, params, Fx.callback);

      resolveLoadData(data);
    }

    function setError(value, error = "Error en el servidor") {
      serverError.value = value;
      serverErrorTxt.value = error;
    }

    function resolveLoadData(data) {
      loading.value = false;

      if (data.status == "ok") {
        let objContacto =
          Array.isArray(data.data) && data.data.length == 1
            ? data.data[0]
            : data.data;

        formData.nombre = objContacto.nombre;
        formData.nif = objContacto.nif;
        formData.direccion = objContacto.direccion;
        formData.cp = objContacto.cp;

        formData.id_poblacion = id_poblacion(objContacto.poblacion);
        formData.id_provincia = id_provincia(
          objContacto.provincia,
          objContacto.cp
        );
      } else {
        setError(true, data.error);
      }
    }

    async function saveFormData(values) {
      saveButtonLoading.value = true;
      dataSaved.value = false;

      setError(false);

      let objContacto = {
        ...values,
        id_contacto: id_contacto,
        id_empresa: store.state.id_empresa,
        pais: 10,
      };

      const url = Settings.BASE_API + "contactos/",
        params = {
          hash: store.state.token,
          accion: "guardar",
          id_empresa: store.state.id_empresa,
          id_contacto: id_contacto,
          obj_contacto: objContacto,
        };

      const data = await Fx.put(url, params, Fx.callback);

      resolveSaveData(data);
    }

    function resolveSaveData(data) {
      if (data.status != "ok") {
        setError(true, data.error);
      }

      saveButtonLoading.value = false;
      dataSaved.value = true;
    }

    onMounted(() => {
      loadData();
    });

    return {
      formData,
      saveFormData,
      loading,
      serverError,
      serverErrorTxt,
      saveButtonLoading,
      dataSaved,
    };
  },
};
</script>
