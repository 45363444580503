import Fx from "@/lib/functions";

function dateSpan(dateString) {
  if (
    dateString == "00/00/0000" ||
    dateString == "0000-00-00" ||
    dateString == "" ||
    dateString == null
  ) {
    return "---";
  }

  return [
    '<span class="d-none">',
    dateString,
    "</span>",
    Fx.dateFormat(dateString),
  ].join("");
}

function tableBtn(objOpciones) {
  const { href, icon, label, btnClass } = objOpciones;

  let arrHtml = [];

  arrHtml.push(
    "<a ",
    ' class="btn ',
    btnClass,
    ' btn-sm" ',
    ' href="',
    href,
    '">'
  );

  if (icon) {
    arrHtml.push('<i class="bi bi-' + icon + '"></i>');
  }

  if (label) {
    arrHtml.push(label);
  }

  arrHtml.push("</a>");
  return arrHtml.join("");
}

export { dateSpan, tableBtn };
