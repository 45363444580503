<template>
  <header
    :class="headerToggle && loggedIn ? 'body-pd' : ''"
    class="header"
    id="header"
  >
    <div class="header_toggle" @click="showNavbar">
      <i
        v-if="loggedIn"
        :class="headerToggle ? 'bi-x-circle' : ''"
        class="bi bi-list"
        id="header-toggle"
      ></i>
    </div>

    <div
      class="header_img d-none d-lg-block 2-100"
      :class="!loggedIn ? 'margin-perc' : ''"
    >
      <img src="../assets/img/tunuve.png" alt="" />
    </div>
    <Topbar />
  </header>

  <div
    v-if="loggedIn"
    :class="headerToggle ? 'show' : ''"
    class="l-navbar d-none d-lg-block"
    id="nav-bar"
  >
    <nav class="nav">
      <div>
        <!-- <router-link to="/" class="nav_logo position">
          <img src="../assets/img/logo_invertido.png" width="18" />
          <span class="nav_logo-name">tunuve</span>
        </router-link> -->

        <div class="nav_list">
          <router-link to="/" class="nav_link">
            <i class="bi bi-house-door nav_icon"></i>
            <span class="nav_name">Inicio</span>
          </router-link>

          <router-link to="/factura/0" class="nav_link">
            <i class="bi bi-file-earmark-text nav_icon"></i>
            <span class="nav_name">Facturar</span>
          </router-link>

          <router-link to="/facturas" class="nav_link">
            <i class="bi bi-card-list nav_icon"></i>
            <span class="nav_name">Mis Facturas</span>
          </router-link>

          <router-link to="/contactos" class="nav_link">
            <i class="bi bi-people nav_icon"></i>
            <span class="nav_name">Contactos</span>
          </router-link>

          <router-link to="/upgrade" class="nav_link">
            <i class="bi bi-cloud nav_icon"></i>
            <span class="nav_name">Tunuve pro</span>
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

import Topbar from "./Topbar.vue";

export default {
  name: "MainFrame",

  components: {
    Topbar,
  },

  setup() {
    const store = useStore();

    const headerToggle = ref(false);

    const showNavbar = function () {
      headerToggle.value = !headerToggle.value;
    };

    return {
      headerToggle,
      showNavbar,
      loggedIn: computed(() => store.state.loggedIn),
      // logout: () => store.dispatch('logout')
    };
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.header {
  width: 100%;
  height: var(--header-height);
  min-height: 65px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: var(--bg-white);
  z-index: var(--z-fixed);
  transition: 0.5s;
}

.header i {
  font-size: 24px;
}

.header_toggle {
  color: var(--tunuve);
  font-size: 1.5rem;
  cursor: pointer;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: var(--nav-width);
  height: 100%;
  background-color: var(--secundario);
  padding: 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}

.l-navbar.show {
  display: block !important;
  background: #fff;
  box-shadow: 13px 29px 30px rgba(175, 174, 174, 0.5);
  padding: 0;
}

.l-navbar.show nav.nav a {
  color: var(--text-dark);
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.8rem 0 0.8rem 1rem;
}

.nav_link i {
  color: var(--tunuve);
}

.nav_logo {
  margin-bottom: 2rem;
}

.nav_logo img {
  width: 20px;
}

.nav_link {
  position: relative;
  margin-bottom: 0;
  transition: 0.3s;
}

.nav_link span.nav_name,
.nav_link i {
  color: var(--tunuve);
  font-weight: bold;
}

.l-navbar.show nav.nav a.active {
  background-color: var(--secundario);
}

.nav_link.active span.nav_name,
.nav_link.active i {
  color: var(--text-dark);
}

.l-navbar.show .nav_link.active i {
  color: var(--text-dark);
}

.nav_link:hover {
  background: var(--secundario);
}

.nav_icon {
  font-size: 1.5rem;
}

.show {
  left: 0;
}

.body-pd {
  /*padding-left: calc(var(--nav-width) + 1rem);*/
}

.nav_list .active {
  color: var(--tunuve) !important;
}

.nav_list a:hover::before,
.nav_list .active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--bg-white);
}

.nav_link.salir {
  border-left: 2px solid var(--tunuve);
}

.nav_link.salir:hover {
  border-left: 2px solid #fff;
}

.height-100 {
  height: 100vh;
}

.margin-perc {
  margin-left: 3%;
}

.header_img img {
  margin-top: 0.25rem;
  height: calc(var(--header-height) - 0.5rem);
}

@media screen and (min-width: 1024px) {
  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
  }
}

@media screen and (min-width: 768px) {
  body {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding-left: calc(var(--nav-width) + 2rem);
  }

  /*.header_img {
        width: 40px;
        height: 40px
    }

    .header_img img {
        width: 45px
    }*/

  .l-navbar {
    left: 0;
    padding: 1rem 0 0 0;
  }

  .show {
    width: calc(var(--nav-width) + 156px);
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 167px);
  }
}
</style>
