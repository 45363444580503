<template>
  <form method="post" @submit.prevent="onSubmit" novalidate>
    <FormElement
      id="email"
      name="email"
      type="email"
      label="E-mail"
      placeholder="Tu email"
      success-message="Nunca lo compartiremos con nadie."
      v-model="formData.email"
    />

    <FormElement
      v-if="!recuperarIsVisible"
      id="password"
      name="password"
      type="password"
      label="Password"
      placeholder="Tu password"
      success-message="Formato correcto."
      v-model="formData.password"
    />

    <FormButton
      id="btn_login"
      :disabled="recuperarIsVisible ? !emailValid : !meta.valid"
      type="submit"
      clases="btn btn-primary"
      :label="txtBoton"
      icon="lock"
      :loading="loading"
    ></FormButton>

    <div id="resp_login" class="mt-3">
      <BSAlert :hide="!formError" bsClass="warning" icon="exclamation-diamond">
        Su usuario y contraseña no son correctos. Por favor, revíselo.
      </BSAlert>

      <BSAlert :hide="!serverError" bsClass="danger" icon="exclamation-diamond">
        Error en el servidor.
      </BSAlert>

      <BSAlert :hide="!mensajeRecuperar" bsClass="info" icon="info-circle">
        <div class="d-block">
          <h4 class="w-100">El proceso se ejecutó con éxito.</h4>
          <p>
            Si existe su dirección de correo en nuestra base de datos, recibirá
            un email con su nuevo password. Por favor, revise su carpeta de SPAM
            si no lo recibe.
          </p>
        </div>
      </BSAlert>
    </div>

    <p class="mt-3" id="p_recuperar">
      <a id="link_recuperar" href="" @click.prevent="toggleRecuperar">
        {{ txtEnlace }}
      </a>
    </p>
  </form>
</template>

<script>
import { ref, reactive } from "vue";
import { useStore } from "vuex";
import { useForm, useIsFieldValid } from "vee-validate";
import { useRouter } from "vue-router";

import { setLocale, object, string } from "yup";
import es from "yup-es";

import FormElement from "@/components/Forms/FormElement.vue";
import FormButton from "@/components/Forms/FormButton.vue";
import BSAlert from "@/components/BSAlert.vue";

import Settings from "@/settings";
import Fx from "@/lib/functions";
import { ruta_archivo } from "@/lib/tunuveFx";

export default {
  name: "LoginForm",
  components: {
    FormElement,
    FormButton,
    BSAlert,
  },
  setup() {
    const store = useStore(),
      router = useRouter();

    const recuperarIsVisible = ref(false),
      loading = ref(false),
      formError = ref(false),
      serverError = ref(false),
      mensajeRecuperar = ref(false),
      txtBoton = ref("Acceder"),
      txtEnlace = ref("¿Olvidaste tu contraseña?");

    const endpoints = {
      loginUrl: Settings.BASE_API + "login/",
      recoverUrl: Settings.BASE_API + "login/recuperar/",
      // registerUrl: Settings.BASE_API + "login/registro/index.php"
    };

    const initialValues = {
      email: "",
      password: "",
    };

    const formData = reactive(initialValues);

    setLocale(es);
    const schema = object().shape({
      email: string().email().required("Se requiere un email válido"),
      password: string()
        .min(6, "El password debe tener 6 caracteres o más")
        .required("Se requiere un password"),
    });

    // Using yup to generate a validation schema
    // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
    const { meta, values } = useForm({
      validationSchema: schema,
      initialValues,
    });

    const emailValid = useIsFieldValid("email");

    // métodos
    function resetAlerts() {
      mensajeRecuperar.value = false;
      serverError.value = false;
      formError.value = false;
    }

    function onSubmit() {
      const formUrl = recuperarIsVisible.value
        ? endpoints.recoverUrl
        : endpoints.loginUrl;

      resetAlerts();
      loading.value = true;

      values.usuario = values.email;

      Fx.post(formUrl, values, "POST", Fx.callback)
        .then(function (data) {
          if (!recuperarIsVisible.value && data["ids_perfiles"] != "4") {
            loading.value = false;
            formError.value = true;
            return true;
          }

          if (data.id_asesor && data.id_asesor > 0) {
            data = {
              status: "ok",
              data: data,
            };
          }

          formError.value = data.status == "ko";
          loading.value = false;

          if (data.status == "ok") {
            !recuperarIsVisible.value
              ? checkIn(data)
              : (mensajeRecuperar.value = true);
            // window.location.reload();
          }
        })
        .catch(function (error) {
          serverError.value = true;
          loading.value = false;

          // eslint-disable-next-line
          console.log("[Login] Problema en el fetch: \n", error);
        });
    } // fin onSubmit

    function checkIn(data) {
      serverError.value = false;

      var logo =
        data.data.logo_empresa != ""
          ? ruta_archivo(data.data.logo_empresa)
          : "";

      var status = data.status,
        objStorage = {
          token: data.data.hash,
          id_asesor: data.data.id_asesor,
          id_empresa: data.data.id_empresa,
          logo,
        };

      status == "ok"
        ? store.dispatch("logIn", objStorage)
        : (serverError.value = true);

      router.push("/factura/0");

      Fx.clickSelectorAfter("#btn_acceso", 2000);
    } // fin checkIn

    function toggleRecuperar() {
      resetAlerts();

      recuperarIsVisible.value = !recuperarIsVisible.value;

      txtBoton.value = recuperarIsVisible.value ? "Recuperar" : "Acceder";

      txtEnlace.value = recuperarIsVisible.value
        ? "Ir a acceder"
        : "¿Olvidaste tu contraseña?";

      // console.log(recuperarIsVisible.value, 'value');
    } // fin toggleRecuperar

    return {
      formData,
      onSubmit,
      schema,
      meta,
      recuperarIsVisible,
      txtBoton,
      txtEnlace,
      toggleRecuperar,
      loading,
      formError,
      serverError,
      mensajeRecuperar,
      emailValid,
    };
  },
};
</script>

<style scoped>
#resp_login {
  max-width: 320px;
}
</style>
