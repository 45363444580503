export default [
  {
    error: "",
    id_asesor: "197",
    id_asesoria: "3",
    ids_perfiles: "2,5",
    es_admin_asesoria: "1",
    activo: "1",
    hash: "$2a$08$4U7y3fzpm9MeGnE.8aw3TOnuTbnm6krSVx3bXIPGVXIFmmYAGSkSG",
    usuario: "cesar@respirainternet.com",
    password: "Harpo1978j$",
    nombre: "César Chas",
    empresas_contabilidad: "todas",
    empresas_laboral: "todas",
    admin: "1",
    anadir_empresas: "1",
    es_empresa: "0",
    tareas_laboral: "",
    todas_empresas_tareas_laboral: "",
    admin_laboral: "1",
    todas_empresas_laboral: "1",
    asignar_tareas_laboral: "1",
    todas_solicitudes_laboral: "0",
    datos_locales: "",
    ultimo_acceso: "2021-09-22 09:17:54",
    id_empresa: "69",
    ids_modulos_activos:
      "Facturación,Facturacion,Inicio,Contabilidad,subir_archivos,factura,gastos,vencimientos,pptos,producto,contacto,caja_diaria,validador,descargas,Bancos,cuentaBancarias/configurar,movimientos/conciliar,movimientos/conciliar/conciliado:0,listadoRemesas,despachos/configurarEmpresa,Asesores,revisiones,sele_empresa,cuentas,asesor,empresa,asesoria,contabilizacion,config_tareas,configuracion,Configuración,configuracion,Laboral,trabajador,solicitud,tarea,nomina,centros,Documental,anadir_documento,lista_documentos,videos,Soporte,G. Asistencia,ficha_asistencia,lista_trabajadores,gestion_asistencia,asistencia,editar_ccc,editar_ct,LCQC,profit_first,tesoreria,conciliacion,reglas,proyecciones,traspasos,configuracion_pf,importacion_pf,analitica,cli_prov,cobros_pagos,Análisis,Analítica",
    soporte: "1",
    alta_web: "0",
    id_plan_web: "0",
    f_inicio_prueba: "0000-00-00",
    f_fin_prueba: "0000-00-00",
    num_archivos_restantes: "0",
    ocultar_saldos: "0",
    es_cliente_avanzado: "0",
    nombre_corto: "sanjuan",
    color: "",
    logo: "http://localhost:8080/sanjuan2/api/uploads/logos/tunuve_invertido.png",
    id_erp: "0",
    export_txt_facturas: "",
    export_txt_cobros: "",
    export_txt_otros: "",
    id_empresa_asesor: "27",
    arr_vacaciones: {
      dias_festivos:
        "2019-01-01,2019-01-07,2019-04-18,2019-04-19,2019-05-01,2019-05-02,2019-05-15,2019-08-15,2019-10-12,2019-11-01,2019-12-06,2019-12-09,2019-12-20,2019-12-24,2019-12-25,2019-12-31,2020-01-01,2020-01-06,2020-04-09,2020-04-10,2020-05-01,2020-05-15,2020-10-12,2020-11-02,2020-11-09,2020-12-07,2020-12-08,2020-12-20,2020-12-24,2020-12-25,2020-12-31,2021-01-01,2021-01-06,2021-03-19,2021-04-01,2021-04-02,2021-05-01,2021-05-03,2021-05-15,2021-10-12,2021-11-01,2021-12-06,2021-12-08,2021-12-20,2021-12-24,2021-12-25,2021-12-31",
      dias_semana_laborables: "L,M,X,J,V",
      etiquetas_trabajadores_asesoria:
        "Fiscal,Laboral,Mercantil,Auditoria,Comun,Juridico",
    },
    arr_trabajador: {
      id_trabajador: "7623",
      email: "cchas78@gmail.com",
      id_trabajador_responsable: "0",
      ids_trabajadores_subordinados: "0",
    },
  },
];
