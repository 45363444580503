<template>
  <div class="botones_derecha">
    <div class="d-flex">
      <a
        v-if="!loggedIn"
        @click="toggleDivRegistro"
        id="btn_registro"
        :class="divRegistroIsVisible ? 'active' : ''"
        class="mr-auto p-2"
        aria-current="page"
        href="#"
      >
        <i class="bi bi-person-plus"></i>
        <span>Registro</span>
      </a>

      <a
        v-if="!loggedIn"
        @click="toggleDivAcceso"
        id="btn_acceso"
        :class="divAccesoIsVisible ? 'active' : ''"
        class="mr-auto p-2"
        href="#"
      >
        <i class="bi bi-lock"></i>
        <span>Acceder</span>
      </a>

      <router-link
        to="/upgrade"
        tag="a"
        v-if="loggedIn"
        id="btn_tunuve_pro"
        class="mr-auto p-2"
        aria-current="page"
        href="#"
      >
        <i class="bi bi-cloud"></i>
        <span>tunuve Pro</span>
      </router-link>

      <div
        v-if="!loggedIn"
        v-show="divRegistroIsVisible"
        id="div_registro"
        class="div_panel animate__animated animate__flipInY"
      >
        <p><b>Regístrate</b> para:</p>
        <ul>
          <li>Guardar tu logo</li>
          <li>Guardar tus datos de facturación</li>
          <li>Guardar tus facturas</li>
          <li>Guardar tus clientes</li>
        </ul>

        <p>
          Si no te registras, tendrás que meter esos datos <br />
          cada vez que quieras emitir una factura.
        </p>

        <p>
          <a class="btn btn-primary" href="https://tunuve.com/alta-pymes">
            Registrarme ahora
          </a>
        </p>
      </div>

      <div
        v-if="!loggedIn"
        v-show="divAccesoIsVisible"
        id="div_acceso"
        class="div_panel animate__animated animate__flipInY"
      >
        <LoginForm />
      </div>

      <router-link
        to="/mis-datos"
        tag="a"
        v-if="loggedIn"
        id="btn_mis_datos"
        class="mr-auto p-2"
        aria-current="page"
        href="#"
      >
        <i class="bi bi-person-circle"></i>
        <span>Mis datos</span>
      </router-link>

      <a
        v-if="loggedIn"
        @click.prevent="logoutVisible = true"
        id="btn_salir"
        class="mr-auto p-2"
        aria-current="page"
        href="#"
      >
        <i class="bi bi-power"></i>
        <span>Salir</span>
      </a>
    </div>

    <div v-if="logoutVisible">
      <Modal id="logout" @modalClosed="logoutVisible = false" effect="zoomIn">
        <h3>¿Estás seguro de que deseas salir?</h3>
        <p><br />Pincha salir para tu sesión.<br /><br /></p>
        <p>
          <button @click="logout()" class="btn btn-primary" id="yes-logout">
            Sí, salir
          </button>
          &nbsp;
          <button
            @click="logoutVisible = false"
            class="btn btn-secondary"
            id="no-cancel"
          >
            No, cancelar
          </button>
        </p>
      </Modal>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import LoginForm from "./LoginForm.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "Topbar",

  components: {
    LoginForm,
    Modal,
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const divRegistroIsVisible = ref(false),
      divAccesoIsVisible = ref(false),
      logoutVisible = ref(false);

    function toggleDivRegistro() {
      divAccesoIsVisible.value = false;
      divRegistroIsVisible.value = !divRegistroIsVisible.value;
    }

    function toggleDivAcceso() {
      divRegistroIsVisible.value = false;
      divAccesoIsVisible.value = !divAccesoIsVisible.value;
    }

    function logout() {
      store.dispatch("logout");
      logoutVisible.value = false;

      router.push("/logout");
    }

    return {
      divRegistroIsVisible,
      divAccesoIsVisible,
      toggleDivRegistro,
      toggleDivAcceso,
      logoutVisible,
      logout,
      loggedIn: computed(() => store.state.loggedIn),
    };
  },
};
</script>

<style scoped>
.navbar {
  background: #fff;
  box-shadow: 1px 1px 2px #f0f0f0;
}

.navbar-brand img {
  height: 30px;
  margin: 10px 0 5px 15px;
}

.navbar-collapse {
  background: #fff;
}

.navbar-collapse.show ul.navbar-nav {
  border-bottom: 1px solid #eee;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0.05rem;
  color: var(--tunuve);
}

.navbar-toggler span {
  color: var(--tunuve) !important;
}

.router-link-active {
  font-weight: bold;
  color: #222 !important;
}

.div_panel {
  background: #fff;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 10px;
  position: absolute;
  min-width: 300px;
  text-align: left;
  border-top-right-radius: 0;
  top: calc(var(--header-height) + 1rem);
  right: 1rem;
}

.div_panel p,
.div_panel ul {
  margin-bottom: 15px;
}

.div_panel a:hover {
  text-decoration: underline;
}

.botones_derecha a {
  text-decoration: none;
  color: var(--text-light);
}

.botones_derecha a i {
  color: var(--tunuve);
  font-size: 18px;
}

.botones_derecha a span {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 10px;
}

.botones_derecha a.active span {
  color: var(--tunuve) !important;
  font-weight: bold !important;
}

.botones_derecha a.active i {
  color: var(--icon-red);
}

@media (max-width: 992px) {
  .navbar-collapse.show ul.navbar-nav li {
    text-align: center;
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
  }
}
</style>
