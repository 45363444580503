import { Model } from "miragejs";
import Settings from "@/settings";

import ServerUtil from "@/server/inc/serverUtil";
import usuariosGetAllSeed from "@/server/seeds/usuariosGetAllSeed";

const loginServer = {
  getDbObj(schema, request) {
    const objReq = ServerUtil.requestToObject(request),
      email = objReq.email;

    let dbObj = schema.logins.findBy({ usuario: email });

    return dbObj;
  },

  addRoutes(server) {
    var esto = this;

    this.addSeeds(server);

    const endpoints = {
      loginUrl: Settings.BASE_API + "login/",
      recoverUrl: Settings.BASE_API + "login/recuperar/",
      // registerUrl: Settings.BASE_API + "login/registro/index.php",
      logoutUrl: Settings.BASE_API + "logout/",
    };

    server.post(
      endpoints.loginUrl,
      function (schema, request) {
        const dbObj = esto.getDbObj(schema, request);

        // if (dbObj && dbObj.id_usuario) {
        if (dbObj && dbObj.id_asesor) {
          return ServerUtil.exitWithData(dbObj);
        }

        return ServerUtil.exitWithError();
      },
      { timing: Settings.MIRAGEJS_TIMING }
    );

    server.post(
      endpoints.recoverUrl,
      function () {
        let dbObj = {
          status: "ok",
          data: "Password recuperado con éxito",
        };

        return ServerUtil.exitWithData(dbObj);
      },
      { timing: Settings.MIRAGEJS_TIMING }
    );

    // server.post(
    //   endpoints.registerUrl,
    //   function() {

    //     let dbObj = {
    //       status: 'ok',
    //       data: 'Registro procesado con éxito'
    //     }

    //     return ServerUtil.exitWithData(dbObj);

    //   },
    //   { timing: Settings.MIRAGEJS_TIMING }
    // );

    server.post(
      endpoints.logoutUrl,
      function () {
        return ServerUtil.exitWithSuccess();
      },
      { timing: Settings.MIRAGEJS_TIMING }
    );
  },

  addSeeds(server) {
    // usamos los mismos seeds de usuarios

    usuariosGetAllSeed.forEach((usuario) => {
      server.create("login", usuario);
    });
  },

  models: {
    login: Model,
  },
};

export default loginServer;
