<template>
  <span class="button" :class="'div_' + id">
    <button
      :id="id"
      :type="type || 'button'"
      :class="clases"
      :disabled="disabled"
      :errorText="
        showBalloon && saveKo && errorText && errorText != ''
          ? errorText
          : false
      "
      @click="buttonClicked"
      :data-balloon-visible="
        showBalloon && saveKo && errorText && errorText != ''
      "
      :aria-label="'Error: ' + errorText"
      :data-balloon-pos="
        showBalloon && saveKo && errorText && errorText != '' ? 'up' : false
      "
    >
      <i v-if="icon" :class="bi_icon"></i>{{ label }}
      <span v-if="loading" class="loading spinner-border"></span>
      <i v-if="saveOk" class="saveOk bi bi-check-circle"></i>
      <i v-if="saveKo" class="saveKo bi bi-times"></i>
    </button>
  </span>
</template>

<script>
export default {
  name: "form-button",

  props: [
    "id",
    "type",
    "clases",
    "label",
    "icon",
    "disabled",
    "loading",
    "errorText",
    "saveOk",
    "saveKo",
  ],

  data() {
    return {
      showBalloon: this.errorText && this.errorText != "",
      idTimeout: 0,
    };
  },

  computed: {
    bi_icon() {
      return "bi bi-" + this.icon;
    },
  },

  methods: {
    buttonClicked(e) {
      var esto = this;

      this.$emit("buttonClicked", this.id, e);

      window.clearTimeout(this.idTimeout);

      this.showBalloon = true;

      this.idTimeout = window.setTimeout(function () {
        esto.showBalloon = false;
      }, 5000);
    },
  },
};
</script>

<style scoped>
.bi {
  margin-right: 5px;
}

.button button {
  --balloon-color: red;
}

.loading {
  width: 1rem;
  height: 1rem;
}
</style>
