<template>
  <!-- <Mensaje msg="Bienvenido a tunuve" image="welcome.png"> -->
  <Mensaje msg="Bienvenido a tunuve" image="welcome.png">
    <div v-if="!loggedIn">
      <p>Con tunuve puedes <b>crear facturas gratis</b> y mucho más.</p>
      <p>
        <a class="btn btn-secondary" @click.prevent="abrirAcceso" href="#">
          <i class="bi bi-lock"></i>
          Acceder
        </a>
        &nbsp;

        <a class="btn btn-primary" href="https://tunuve.com/alta-pymes">
          <i class="bi bi-person-plus"></i>
          Registrarme
        </a>
      </p>

      <p>
        <a target="_blank" href="https://tunuve.com" class="btn btn-secondary">
          <i class="bi bi-info-circle"></i>
          Saber más de tunuve
        </a>
      </p>
    </div>
    <!-- fin !loggedIn -->

    <div v-if="loggedIn">
      <p>Haz click para comenzar:</p>
      <p>
        <router-link class="btn btn-primary" to="/factura/0">
          Crear Factura
        </router-link>
      </p>
      <p>
        <router-link class="btn btn-secondary" to="/facturas">
          Ver Facturas
        </router-link>

        &nbsp;
        <router-link class="btn btn-secondary" to="/contactos">
          Ver Contactos
        </router-link>
      </p>
    </div>
  </Mensaje>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

import Mensaje from "@/components/Mensaje.vue";

import Fx from "@/lib/functions";
import { setDisplayVideoaskAntonio } from "@/lib/tunuveFx";

export default {
  name: "Bienvenido",
  components: {
    Mensaje,
  },

  setup() {
    const store = useStore(),
      loggedIn = ref(store.state.loggedIn);

    if (loggedIn.value) {
      setDisplayVideoaskAntonio("block");
    }

    function abrirAcceso() {
      Fx.simulateClick("#btn_acceso");
    }

    return {
      loggedIn,
      abrirAcceso,
    };
  },
};
</script>
