import { Model } from "miragejs";
import Settings from "@/settings";

import ServerUtil from "@/server/inc/serverUtil";
import empresasGetAllSeed from "@/server/seeds/empresasGetAllSeed";

const empresasServer = {
  getDbObj(schema) {
    let dbObj = schema.empresas.where({ id_empresa: "69" }).models;
    return dbObj;
  },

  getSingleObj(schema, fieldName, fieldValue) {
    let searchObj = {};

    searchObj[fieldName] = fieldValue;

    let dbObj = schema.empresas.where(searchObj).models;

    return dbObj;
  },

  addRoutes(server) {
    var esto = this;

    this.addSeeds(server);

    const endpoints = {
      empresasUrl: Settings.BASE_API + "empresas/",
    };

    server.get(
      endpoints.empresasUrl,
      function (schema, request) {
        let id_empresa = request.queryParams.id_empresa;

        const dbObj = id_empresa
          ? esto.getSingleObj(schema, "id_empresa", id_empresa)
          : esto.getDbObj(schema);

        if (dbObj && dbObj.length && dbObj.length > 0) {
          return ServerUtil.exitWithData(dbObj);
        }

        return ServerUtil.exitWithError();
      },
      { timing: Settings.MIRAGEJS_TIMING }
    );

    server.put(
      endpoints.empresasUrl,
      function (schema, request) {
        let requestBodyObj = JSON.parse(request.requestBody);

        let id_empresa = requestBodyObj["obj_configuracion"]["id_empresa"];

        const dbObj = esto.getSingleObj(schema, "id_empresa", id_empresa);

        if (dbObj && dbObj.length && dbObj.length > 0) {
          return {
            status: "ok",
            data: {
              ...requestBodyObj.data,
              obj_configuracion: { ...requestBodyObj["obj_configuracion"] },
            },
          };

          // return ServerUtil.exitWithData( respObj );
        }

        return ServerUtil.exitWithError();
      },
      { timing: Settings.MIRAGEJS_TIMING }
    );
  },

  addSeeds(server) {
    // usamos los mismos seeds de empresas

    empresasGetAllSeed.forEach((empresa) => {
      server.create("empresa", empresa);
    });
  },

  models: {
    empresa: Model,
  },
};

export default empresasServer;
