export default [
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "ZENDESK",
    contabilizado: "0",
    email: "administracion@estilobano.es",
    enviado: "0",
    f_factura: "2021-09-03 00:00:00",
    id_contacto: "804258",
    id_factura: "1807681",
    ids_etiquetas: "",
    importe: "169",
    nombre: "Bathroom Style, S.L.",
    num_asiento: "",
    num_factura: "32562",
    prefijo_serie: null,
    total_factura: "204.49",
    total_pte: "204.49",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "SANJUAN",
    contabilizado: "0",
    email: "soporte@tunuve.com",
    enviado: "0",
    f_factura: "2021-09-01 00:00:00",
    id_contacto: "8583",
    id_factura: "1804825",
    ids_etiquetas: "",
    importe: "1789",
    nombre: "TUNUVE SOLUCIONES PARA INQUIETOS SL",
    num_asiento: "",
    num_factura: "32561",
    prefijo_serie: null,
    total_factura: "2164.69",
    total_pte: "2164.69",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "0",
    email: "veluterna@gmail.com",
    enviado: "0",
    f_factura: "2021-09-01 00:00:00",
    id_contacto: "639450",
    id_factura: "1804355",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "Graciela Oliveira Viera",
    num_asiento: "",
    num_factura: "32560",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "36.24",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "0",
    email: "inmobiliariarquisur@gmail.com",
    enviado: "0",
    f_factura: "2021-09-01 00:00:00",
    id_contacto: "367780",
    id_factura: "1803931",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "María del Carmen Maldonado Fernández",
    num_asiento: "",
    num_factura: "32559",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "36.24",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "0",
    email: "info@eigentum-mallorca.es",
    enviado: "0",
    f_factura: "2021-09-01 00:00:00",
    id_contacto: "8582",
    id_factura: "1803930",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "METACIMA SL",
    num_asiento: "",
    num_factura: "32558",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "36.24",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "0",
    email: "info@inmomarto.com",
    enviado: "0",
    f_factura: "2021-09-01 00:00:00",
    id_contacto: "6668",
    id_factura: "1803929",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "INMOMARTO,S.L.",
    num_asiento: "",
    num_factura: "32557",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "36.24",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "0",
    email: "info@ipropietarios.com",
    enviado: "0",
    f_factura: "2021-09-01 00:00:00",
    id_contacto: "8579",
    id_factura: "1803928",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "PMAS INMOGESOR,S.L",
    num_asiento: "",
    num_factura: "32556",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "36.24",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "0",
    email: "daniel878pg@gmail.com",
    enviado: "0",
    f_factura: "2021-09-01 00:00:00",
    id_contacto: "1982",
    id_factura: "1803927",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "PRIETO GARCIA, DANIEL",
    num_asiento: "",
    num_factura: "32555",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "36.24",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "0",
    email: "ortegalaestaca@gmail.com",
    enviado: "0",
    f_factura: "2021-09-01 00:00:00",
    id_contacto: "1974",
    id_factura: "1803926",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "ORTEGAL A ESTACA, S.L.",
    num_asiento: "",
    num_factura: "32554",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "36.24",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "0",
    email: "info@adacastrovil.com",
    enviado: "0",
    f_factura: "2021-09-01 00:00:00",
    id_contacto: "1964",
    id_factura: "1803925",
    ids_etiquetas: "1537",
    importe: "24.95",
    nombre: "VALADOURO GES INMO, S.L.",
    num_asiento: "",
    num_factura: "32553",
    prefijo_serie: null,
    total_factura: "30.19",
    total_pte: "30.19",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "0",
    email: "info@greasingnet.net",
    enviado: "0",
    f_factura: "2021-09-01 00:00:00",
    id_contacto: "1961",
    id_factura: "1803924",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "FERRERA TABARE, JONATHAN",
    num_asiento: "",
    num_factura: "32552",
    prefijo_serie: null,
    total_factura: "29.95",
    total_pte: "29.95",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "ZENDESK",
    contabilizado: "0",
    email: "",
    enviado: "0",
    f_factura: "2021-08-30 00:00:00",
    id_contacto: "684480",
    id_factura: "1802313",
    ids_etiquetas: "1700",
    importe: "562.5",
    nombre: "RIGHT DECISIONS ASESORES INFORMATICOS SL",
    num_asiento: "",
    num_factura: "32551",
    prefijo_serie: null,
    total_factura: "680.62",
    total_pte: "680.62",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "ZENDESK",
    contabilizado: "0",
    email: "",
    enviado: "0",
    f_factura: "2021-08-18 00:00:00",
    id_contacto: "830662",
    id_factura: "1798822",
    ids_etiquetas: "1700",
    importe: "2495",
    nombre: "MARCANDITA S.L.",
    num_asiento: "",
    num_factura: "32550",
    prefijo_serie: null,
    total_factura: "3018.95",
    total_pte: "3018.95",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "ZENDESK",
    contabilizado: "1",
    email: "admin@olalahomes.com",
    enviado: "0",
    f_factura: "2021-08-10 00:00:00",
    id_contacto: "828353",
    id_factura: "1796579",
    ids_etiquetas: "",
    importe: "799",
    nombre: "RGMP Properties Barcelona SL",
    num_asiento: "447",
    num_factura: "32549",
    prefijo_serie: null,
    total_factura: "966.79",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "veluterna@gmail.com",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "639450",
    id_factura: "1788998",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "Graciela Oliveira Viera",
    num_asiento: "407",
    num_factura: "32548",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "inmobiliariarquisur@gmail.com",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "367780",
    id_factura: "1788580",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "María del Carmen Maldonado Fernández",
    num_asiento: "406",
    num_factura: "32547",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "info@eigentum-mallorca.es",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "8582",
    id_factura: "1788579",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "METACIMA SL",
    num_asiento: "408",
    num_factura: "32546",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "monteagudoballesterossl@gmail.com",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "380491",
    id_factura: "1788578",
    ids_etiquetas: "1537",
    importe: "159.95",
    nombre: "Pedro Antonio Monteagudo Luján",
    num_asiento: "446",
    num_factura: "32545",
    prefijo_serie: null,
    total_factura: "193.54",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "fernan_talavera@hotmail.com",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "16864",
    id_factura: "1788577",
    ids_etiquetas: "1537",
    importe: "159.95",
    nombre: "MAGERIT HOMES SL",
    num_asiento: "417",
    num_factura: "32544",
    prefijo_serie: null,
    total_factura: "193.54",
    total_pte: "193.54",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "info@iberfutur.com",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "3072",
    id_factura: "1788576",
    ids_etiquetas: "1537",
    importe: "139.95",
    nombre: "IBER COSTA 61 SL",
    num_asiento: "424",
    num_factura: "32543",
    prefijo_serie: null,
    total_factura: "169.34",
    total_pte: "169.34",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "info@inmomarto.com",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "6668",
    id_factura: "1788575",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "INMOMARTO,S.L.",
    num_asiento: "413",
    num_factura: "32542",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "info@ipropietarios.com",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "8579",
    id_factura: "1788574",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "PMAS INMOGESOR,S.L",
    num_asiento: "411",
    num_factura: "32541",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "daniel878pg@gmail.com",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "1982",
    id_factura: "1788573",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "PRIETO GARCIA, DANIEL",
    num_asiento: "405",
    num_factura: "32540",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "ortegalaestaca@gmail.com",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "1974",
    id_factura: "1788572",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "ORTEGAL A ESTACA, S.L.",
    num_asiento: "416",
    num_factura: "32539",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "info@adacastrovil.com",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "1964",
    id_factura: "1788571",
    ids_etiquetas: "1537",
    importe: "24.95",
    nombre: "VALADOURO GES INMO, S.L.",
    num_asiento: "412",
    num_factura: "32538",
    prefijo_serie: null,
    total_factura: "30.19",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "info@greasingnet.net",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "1961",
    id_factura: "1788570",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "FERRERA TABARE, JONATHAN",
    num_asiento: "425",
    num_factura: "32537",
    prefijo_serie: null,
    total_factura: "29.95",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "OCLEM",
    contabilizado: "1",
    email: "",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "16866",
    id_factura: "1788427",
    ids_etiquetas: "",
    importe: "135",
    nombre: "GRUPO OCLEM, S.L.",
    num_asiento: "414",
    num_factura: "32536",
    prefijo_serie: null,
    total_factura: "163.35",
    total_pte: "163.35",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "SANJUAN",
    contabilizado: "1",
    email: "soporte@tunuve.com",
    enviado: "0",
    f_factura: "2021-08-01 00:00:00",
    id_contacto: "8583",
    id_factura: "1788426",
    ids_etiquetas: "",
    importe: "1243",
    nombre: "TUNUVE SOLUCIONES PARA INQUIETOS SL",
    num_asiento: "418",
    num_factura: "32535",
    prefijo_serie: null,
    total_factura: "1504.03",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "ZENDESK",
    contabilizado: "1",
    email: "",
    enviado: "0",
    f_factura: "2021-07-29 00:00:00",
    id_contacto: "684480",
    id_factura: "1786788",
    ids_etiquetas: "1700",
    importe: "742.2",
    nombre: "RIGHT DECISIONS ASESORES INFORMATICOS SL",
    num_asiento: "394",
    num_factura: "32534",
    prefijo_serie: null,
    total_factura: "898.05",
    total_pte: "898.05",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "ZENDESK",
    contabilizado: "1",
    email: "",
    enviado: "0",
    f_factura: "2021-07-07 00:00:00",
    id_contacto: "754997",
    id_factura: "1732872",
    ids_etiquetas: "1495",
    importe: "-15.6",
    nombre: "Ballenoil Sl",
    num_asiento: "363",
    num_factura: "32533",
    prefijo_serie: null,
    total_factura: "-18.88",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "ZENDESK",
    contabilizado: "1",
    email: "",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "754997",
    id_factura: "1717695",
    ids_etiquetas: "1495",
    importe: "859.69",
    nombre: "Ballenoil Sl",
    num_asiento: "347",
    num_factura: "32532",
    prefijo_serie: null,
    total_factura: "1040.22",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "OCLEM",
    contabilizado: "1",
    email: "",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "16866",
    id_factura: "1717573",
    ids_etiquetas: "",
    importe: "810",
    nombre: "GRUPO OCLEM, S.L.",
    num_asiento: "352",
    num_factura: "32531",
    prefijo_serie: null,
    total_factura: "980.1",
    total_pte: "980.1",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "SANJUAN",
    contabilizado: "1",
    email: "soporte@tunuve.com",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "8583",
    id_factura: "1717569",
    ids_etiquetas: "",
    importe: "3131",
    nombre: "TUNUVE SOLUCIONES PARA INQUIETOS SL",
    num_asiento: "351",
    num_factura: "32530",
    prefijo_serie: null,
    total_factura: "3788.51",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "veluterna@gmail.com",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "639450",
    id_factura: "1717085",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "Graciela Oliveira Viera",
    num_asiento: "333",
    num_factura: "32529",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "rubenbernardosg@gmail.com",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "633349",
    id_factura: "1717083",
    ids_etiquetas: "1537",
    importe: "195",
    nombre: "Rubén Bernardos Gómez",
    num_asiento: "332",
    num_factura: "32528",
    prefijo_serie: null,
    total_factura: "235.95",
    total_pte: "235.95",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "infospanishome@gmail.com",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "241194",
    id_factura: "1716729",
    ids_etiquetas: "1537",
    importe: "147.5",
    nombre: "SANDRA RUEDA LLAMAS",
    num_asiento: "339",
    num_factura: "32527",
    prefijo_serie: null,
    total_factura: "178.48",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "inmobiliariarquisur@gmail.com",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "367780",
    id_factura: "1716662",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "María del Carmen Maldonado Fernández",
    num_asiento: "331",
    num_factura: "32526",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "info@eigentum-mallorca.es",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "8582",
    id_factura: "1716661",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "METACIMA SL",
    num_asiento: "342",
    num_factura: "32525",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "info@inmomarto.com",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "6668",
    id_factura: "1716660",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "INMOMARTO,S.L.",
    num_asiento: "344",
    num_factura: "32524",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "info@ipropietarios.com",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "8579",
    id_factura: "1716659",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "PMAS INMOGESOR,S.L",
    num_asiento: "343",
    num_factura: "32523",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "daniel878pg@gmail.com",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "1982",
    id_factura: "1716658",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "PRIETO GARCIA, DANIEL",
    num_asiento: "335",
    num_factura: "32522",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "ortegalaestaca@gmail.com",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "1974",
    id_factura: "1716657",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "ORTEGAL A ESTACA, S.L.",
    num_asiento: "350",
    num_factura: "32521",
    prefijo_serie: null,
    total_factura: "36.24",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "info@adacastrovil.com",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "1964",
    id_factura: "1716656",
    ids_etiquetas: "1537",
    importe: "24.95",
    nombre: "VALADOURO GES INMO, S.L.",
    num_asiento: "349",
    num_factura: "32520",
    prefijo_serie: null,
    total_factura: "30.19",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "INMOLITE",
    contabilizado: "1",
    email: "info@greasingnet.net",
    enviado: "0",
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "1961",
    id_factura: "1716655",
    ids_etiquetas: "1537",
    importe: "29.95",
    nombre: "FERRERA TABARE, JONATHAN",
    num_asiento: "360",
    num_factura: "32519",
    prefijo_serie: null,
    total_factura: "29.95",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "ZENDESK",
    contabilizado: "1",
    email: "administracion@estilobano.es",
    enviado: "0",
    f_factura: "2021-06-30 00:00:00",
    id_contacto: "804258",
    id_factura: "1716535",
    ids_etiquetas: "1495",
    importe: "99",
    nombre: "Bathroom Style, S.L.",
    num_asiento: "353",
    num_factura: "32518",
    prefijo_serie: null,
    total_factura: "119.79",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "ZENDESK",
    contabilizado: "1",
    email: "administracion@estilobano.es",
    enviado: "0",
    f_factura: "2021-06-30 00:00:00",
    id_contacto: "804258",
    id_factura: "1716533",
    ids_etiquetas: "1700",
    importe: "983.5",
    nombre: "Bathroom Style, S.L.",
    num_asiento: "357",
    num_factura: "32517",
    prefijo_serie: null,
    total_factura: "1190.04",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "ZENDESK",
    contabilizado: "1",
    email: "",
    enviado: "0",
    f_factura: "2021-06-24 00:00:00",
    id_contacto: "684480",
    id_factura: "1712017",
    ids_etiquetas: "1700",
    importe: "625.01",
    nombre: "RIGHT DECISIONS ASESORES INFORMATICOS SL",
    num_asiento: "359",
    num_factura: "32516",
    prefijo_serie: null,
    total_factura: "756.25",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "ZENDESK",
    contabilizado: "1",
    email: "",
    enviado: "0",
    f_factura: "2021-06-23 00:00:00",
    id_contacto: "810540",
    id_factura: "1711601",
    ids_etiquetas: "1700",
    importe: "3000",
    nombre: "Astroline VoiP Services SL",
    num_asiento: "329",
    num_factura: "32515",
    prefijo_serie: null,
    total_factura: "3630",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: null,
    contabilizado: "0",
    email: "",
    enviado: null,
    f_factura: "2021-07-01 00:00:00",
    id_contacto: "663138372",
    id_factura: "1709012",
    ids_etiquetas: "",
    importe: "100",
    nombre: "Empresa de prueba",
    num_asiento: "",
    num_factura: "F1",
    prefijo_serie: null,
    total_factura: "121",
    total_pte: "121",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: null,
    contabilizado: "0",
    email: "info@aixhomebarcelona.com",
    enviado: null,
    f_factura: "2021-06-21 00:00:00",
    id_contacto: "10533",
    id_factura: "1709003",
    ids_etiquetas: "",
    importe: "100",
    nombre: "Adriana Aixemeno Mostajo",
    num_asiento: "",
    num_factura: "32481",
    prefijo_serie: null,
    total_factura: "121",
    total_pte: "121",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "ZENDESK",
    contabilizado: "1",
    email: "bsmerkin@edenjardin.com",
    enviado: "0",
    f_factura: "2021-06-15 00:00:00",
    id_contacto: "670481",
    id_factura: "1706664",
    ids_etiquetas: "1495",
    importe: "250",
    nombre: "Jaume Ramon Matas",
    num_asiento: "311",
    num_factura: "32514",
    prefijo_serie: null,
    total_factura: "302.5",
    total_pte: "0",
  },
  {
    archivo: "",
    archivo_usuario: "",
    carpeta: "",
    cod_analitica: "ZENDESK",
    contabilizado: "1",
    email: "",
    enviado: "0",
    f_factura: "2021-06-15 00:00:00",
    id_contacto: "725964",
    id_factura: "1706647",
    ids_etiquetas: "1495",
    importe: "891",
    nombre: "Cdad de Prop del Conjunto Inmobiliario Centro Comercial los Arcos",
    num_asiento: "310",
    num_factura: "32513",
    prefijo_serie: null,
    total_factura: "1078.11",
    total_pte: "0",
  },
];
