<template>
  <BSAlert
    :hide="!serverError || loading"
    bsClass="danger"
    icon="exclamation-diamond"
  >
    Error en el servidor. {{ serverErrorTxt }}
  </BSAlert>

  <div v-if="loading">
    <Spinner />
  </div>

  <DatatablesVue3
    v-if="!serverError && !loading"
    :loading="loading"
    :columns="columns"
    :data="data"
  />
</template>

<script>
import Fx from "@/lib/functions";

import DatatablesVue3 from "@/components/Forms/DatatablesVue3.vue";

import Spinner from "@/components/Spinner.vue";
import BSAlert from "@/components/BSAlert.vue";

import { ref, onMounted, computed } from "vue";

export default {
  name: "CRUDTable",

  props: {
    url: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    dataMapFx: {
      type: Function,
      default: () => 1,
    },
  },

  components: {
    DatatablesVue3,
    BSAlert,
    Spinner,
  },

  setup(props) {
    const serverError = ref(false),
      serverErrorTxt = ref(""),
      loading = ref(true),
      tableData = ref([]);

    function displayError(errorTxt = "") {
      serverError.value = true;
      serverErrorTxt.value = errorTxt || "";
    }

    function getTableDataObj(data) {
      if (data.status && data.status == "ko") {
        displayError(data.error);
        return false;
      }

      let val = data.data.map(props.dataMapFx);
      return val;
    }

    function loadTable() {
      return Fx.get(props.url, props.params, Fx.callback)

        .then(getTableDataObj)
        .catch(function (error) {
          displayError(error);
          console.log("[Login] Problema en el fetch: \n", error);
        });
    }

    onMounted(() => {
      loadTable().then((data) => {
        loading.value = false;
        tableData.value = data;
      });
    });

    return {
      serverError,
      loading,
      data: computed(() => {
        return tableData.value;
      }),
    };
  },
};
</script>
