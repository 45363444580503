<template>
  <!-- <Topbar /> -->
  <section class="app">
    <MainFrame />
    <!--Container Main start-->
    <div class="height-100">
      <!-- <router-view class="animate__animated animate__zoomIn" v-slot="slotProps"> -->
      <router-view v-slot="slotProps">
        <!-- <transition name="fade"> -->
        <component :is="slotProps.Component"></component>
        <!-- </transition> -->
      </router-view>
    </div>
    <!--Container Main end-->
  </section>
</template>

<script>
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "bootstrap-icons/font/bootstrap-icons.css";

import "animate.css";

import "./assets/css/tunuve.css";
import "./assets/css/ajustes.css";

(async () => {
  if (!window.Cypress) {
    await import("animate.css");
  }
})();

import { useStore } from "vuex";

import MainFrame from "./components/MainFrame.vue";

import Fx from "@/lib/functions";
import Settings from "@/settings";

export default {
  setup() {
    const store = useStore();

    let jsonStorage =
      Fx.decryptStr(localStorage.getItem(Settings.APP_CODE || "")) || null;

    if (jsonStorage && jsonStorage.indexOf("{") > -1) {
      var objStorage = JSON.parse(jsonStorage),
        token = objStorage.token;

      if (token && token.length > 5) {
        store.dispatch("logIn", objStorage);
      }
    }

    return {};
  },

  name: "App",
  components: {
    MainFrame,
  },
};
</script>

<style>
body {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /*centers items on the line (the x-axis by default)*/
  align-items: center; /*centers items on the cross-axis (y by default)*/
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: auto;
}

button[class^="videoask-embed__button"] {
  display: none;
}

/*.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}*/
</style>
