import { createWebHistory, createRouter } from "vue-router";

import Bienvenido from "@/views/Bienvenido.vue";
import Factura from "@/views/facturas/Factura.vue";
import Facturas from "@/views/facturas/Facturas.vue";
import Contacto from "@/views/contactos/Contacto.vue";
import Contactos from "@/views/contactos/Contactos.vue";
import Empresa from "@/views/empresas/Empresa.vue";
import Upgrade from "@/views/upgrade/Upgrade.vue";

import Logout from "@/views/Logout.vue";
import NotFound from "@/views/NotFound.vue";
import AltaOk from "@/views/AltaOk.vue";

import FormTest from "@/views/FormTest.vue";
// import SuspenseTest from "@/views/SuspenseTest.vue";

import { store } from "@/store/store.js";

import { setDisplayVideoaskAntonio } from "@/lib/tunuveFx";

const routes = [
  {
    path: "/",
    name: "Bienvenido",
    component: Bienvenido,
    // children: [
    //   {
    //     path: "/alta-ok/:query*",
    //     component: Bienvenido,
    //     name: "altaOk",
    //   },
    // ],
  },
  {
    path: "/alta-ok/:query*",
    component: AltaOk,
    name: "altaOk",
  },

  // {
  //   path: "/suspense",
  //   name: "Suspense",
  //   component: SuspenseTest,
  // },

  {
    path: "/factura/:id_factura(\\d+)",
    component: Factura,
    name: "facturaEdit",
  },

  {
    path: "/facturas",
    name: "Facturas",
    component: Facturas,
  },

  {
    path: "/contactos",
    name: "Contactos",
    component: Contactos,
  },

  {
    path: "/contacto",
    component: Contacto,
    name: "contactoEdit",
    children: [
      {
        path: "/contacto/:id_contacto(\\d+)",
        component: Contacto,
        name: "contactoEdit",
      },
    ],
  },
  {
    path: "/mis-datos",
    name: "Empresa",
    component: Empresa,
  },

  {
    path: "/upgrade",
    name: "Upgrade",
    component: Upgrade,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },

  {
    path: "/form-test",
    name: "FormTest",
    component: FormTest,
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
    name: "defaultComponent",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_APP),
  routes,
  linkExactActiveClass: "active",
});

router.beforeResolve((to) => {
  setDisplayVideoaskAntonio("none");

  const arrAuth = ["facturas", "contactos", "mis-datos", "upgrade"];

  if (
    to.path != "/" &&
    !store.state.loggedIn &&
    arrAuth.indexOf(to.path.replace("/", "")) > -1
  ) {
    router.push("/not-found");
  }
});

export default router;
