<template>
  <teleport to="section.app">
    <section
      class="animatedmodal animate__animated"
      :class="'animate__' + effect"
    >
      <div class="modal-content">
        <div v-if="!hideClose" class="closeBtnBox">
          <a :title="Cerrar" href="#" @click.prevent="closeModal">
            <i class="bi bi-x-circle-fill" style="font-size: 48px"></i>
          </a>
        </div>

        <div class="content">
          <slot></slot>
        </div>
      </div>
    </section>
  </teleport>
</template>

<script>
export default {
  name: "Modal",

  props: {
    hideClose: {
      type: Boolean,
      default: false,
    },

    effect: {
      type: String,
      default: "flipInY",
    },
  },

  // props: ["hideClose", "animated"],

  data() {
    return {};
  },

  methods: {
    closeModal() {
      this.$emit("modalClosed");
    },
  },
};
</script>

<style scoped>
.animatedmodal {
  position: fixed;
  top: 0;
  left: 0;
  color: var(--text-dark);
  width: 100%;
  height: 100%;
  z-index: 100;
}

.modal-content {
  background: #fafafa url("../assets/img/fondo2.png") no-repeat center center;
  padding: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}

.modal-content div.content h3 {
  color: var(--text-dark);
}

.closeBtnBox {
  margin: 30px 0;
}

.closeBtnBox a {
  display: block;
  color: var(--text-dark);
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Safari and Chrome */
  -o-transform: rotate(0deg); /* Opera */
  -moz-transform: rotate(0deg); /* Firefox */
  transform: rotate(0deg);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.closeBtnBox a:hover {
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
  -o-transform: rotate(90deg); /* Opera */
  -moz-transform: rotate(90deg); /* Firefox */
  transform: rotate(90deg);
}
</style>
