<template>
  <div class="col-12 col-lg-3 cuadro_upgrade">
    <div class="cuadro_upgrade_container">
      <div class="text-center" style="max-height: 250px">
        <img class="" :src="getImgUrl()" alt="update" />
      </div>
      <h4 class="w-100 text-center mt-3">{{ title }}</h4>
      <p v-if="description != ''">{{ description }}</p>

      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "CuadroUpgrade",
  props: {
    imageUrl: {
      type: String,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    let getImgUrl = function () {
      return require("../../assets/img/upgrade/" + props.imageUrl);
    };

    return {
      getImgUrl,
    };
  },
};
</script>
<style>
.cuadro_upgrade {
  padding: 10px;
  text-align: center;
}

.cuadro_upgrade_container {
  border: 1px solid #eee;
  padding: 20px;
}

.cuadro_upgrade img {
  padding: 20px 40px;
  height: auto;
}

.cuadro_upgrade h4 {
  color: var(--text-dark);
  margin-top: 20px;
}

.cuadro_upgrade p {
  min-height: 60px;
}
</style>
