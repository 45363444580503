import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

function createTooltip(id) {
  var el = document.getElementById("tooltip_" + id);

  if (el) {
    new bootstrap.Tooltip(el, {});
  }
}

export { createTooltip };
