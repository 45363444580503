<template>
  <div class="main">
    <section-header
      :title="modo == 'editar' ? 'Editar factura' : 'Crear factura'"
      image="facturacion.png"
      link_to="/"
      link_text="Ir a inicio"
    />

    <div class="container view_container cuerpo_factura">
      <div v-if="loading">
        <Spinner />
      </div>

      <BSAlert
        :hide="!serverError || loading"
        bsClass="danger"
        icon="exclamation-diamond"
      >
        {{ serverErrorTxt }}
      </BSAlert>

      <FacturaForm
        v-if="!loading && !serverError"
        :data="formData"
        :id_contacto="id_contacto"
        @formSubmitted="saveFormData"
        @downloadInvoice="downloadInvoice"
        :saveButtonLoading="saveButtonLoading"
      />

      <BSAlert
        class="mt-3"
        :hide="serverError || loading || !dataSaved"
        :bsClass="dataSavedStatus == 'ok' ? 'success' : 'danger'"
        :icon="dataSavedStatus == 'ok' ? 'check-circle' : 'exclamation-diamond'"
      >
        {{ dataSavedText }}
      </BSAlert>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import SectionHeader from "@/components/SectionHeader.vue";
import Spinner from "@/components/Spinner.vue";
import BSAlert from "@/components/BSAlert.vue";

import Settings from "@/settings";
import Fx from "@/lib/functions";
import {
  id_poblacion,
  id_provincia,
  id_provincia_cp,
  provincia_txt,
} from "@/lib/tunuveFx";

import FacturaForm from "@/views/facturas/FacturaForm.vue";

import { objFacturaDefault } from "@/views/facturas/inc/objFactura";
import {
  actualizarFormDataFactura,
  resetData,
} from "@/views/facturas/inc/facturasUtil";

export default {
  name: "Factura",

  components: {
    Spinner,
    BSAlert,
    FacturaForm,
    SectionHeader,
  },

  setup() {
    const store = useStore(),
      route = useRoute(),
      router = useRouter();

    const loading = ref(true),
      serverError = ref(false),
      serverErrorTxt = ref(""),
      saveButtonLoading = ref(false),
      dataSaved = ref(false),
      dataSavedStatus = ref("ok"),
      dataSavedText = ref(""),
      id_contacto = ref(0);

    const loggedIn = store.state.loggedIn,
      modo = computed(() =>
        !isNaN(formData.id_factura) && formData.id_factura > 0 && loggedIn
          ? "editar"
          : "anadir"
      );

    let id_factura = parseInt(window.location.href.split("/").pop());

    // const today = Fx.today("yyyy-MM-dd"),
    const today = new Date().toISOString().substr(0, 10),
      formData = reactive(objFacturaDefault);

    formData.f_factura = today;
    formData.id_empresa = store.state.id_empresa;

    // funciones asíncronas
    async function loadFactura() {
      if (id_factura == 0) {
        return {
          status: "ok",
          data: [objFacturaDefault],
        };
      }

      const url = Settings.BASE_API + "facturas/",
        params = {
          hash: store.state.token,
          accion: "consultar",
          tipo_factura: "factura",
          id_empresa: store.state.id_empresa,
          id_factura: id_factura,
        };

      return Fx.get(url, params, Fx.callback);
    }

    async function loadEmpresa() {
      const url = Settings.BASE_API + "empresas/",
        params = {
          hash: store.state.token,
          accion: "consultar_empresas",
          id_empresa: store.state.id_empresa,
        };

      return Fx.get(url, params, Fx.callback);
    }

    async function loadData() {
      resetData(formData);

      loading.value = true;
      saveButtonLoading.value = false;
      dataSaved.value = false;
      dataSavedStatus.value = "";
      dataSavedText.value = "";

      setError(false);

      let dataFactura = {
          status: "ok",
          data: [formData],
        },
        dataEmpresa = {};

      dataFactura = await loadFactura();
      dataEmpresa = await loadEmpresa();

      resolveLoadData(dataFactura, dataEmpresa);
    }

    async function saveFormData(values) {
      saveButtonLoading.value = true;
      dataSaved.value = false;
      dataSavedStatus.value = false;
      dataSavedText.value = "";

      setError(false);

      if (!loggedIn) {
        setError(true, "Error de autenticación");
        return true;
      }

      let objFactura = {
        ...values,
        id_factura: id_factura,
        id_empresa: store.state.id_empresa,
      };

      let f_factura = values.f_factura.toISOString().substr(0, 10);

      objFactura.f_factura = f_factura;
      objFactura.f_vto = f_factura;
      objFactura.total_conceptos = Fx.removeEuro(objFactura.total_conceptos);
      objFactura.total_factura = Fx.removeEuro(objFactura.total_factura);
      objFactura.total_iva = Fx.sumByKey(objFactura.arr_conceptos, "total_iva");
      objFactura.importe = Fx.sumByKey(objFactura.arr_conceptos, "importe");
      objFactura.base_imponible = objFactura.importe; // lo requiere el guardado

      objFactura.arr_vtos = [
        {
          f_vto: f_factura,
          importe: objFactura.total_factura,
          id_pf_cuenta_bancaria: 0,
        },
      ];

      objFactura.arr_totales.total_bases_imponibles = objFactura.importe;
      objFactura.arr_totales.total_iva = objFactura.total_iva;
      objFactura.arr_totales.total_conceptos = objFactura.total_conceptos;
      objFactura.arr_totales.total_irpf = objFactura.total_irpf;

      objFactura.origen = "tunuve-facturas";
      objFactura.id_asesoria = 3;
      objFactura.id_factura = id_factura;

      objFactura.id_poblacion = id_poblacion(formData.poblacion);
      objFactura.id_provincia = id_provincia(formData.provincia, formData.cp);
      objFactura.provincia = objFactura.id_provincia;

      const url = Settings.BASE_API + "facturas/",
        params = {
          hash: store.state.token,
          accion: "guardar",
          opcion: "factura",
          tipo_factura: "factura",
          id_empresa: store.state.id_empresa,
          id_factura: id_factura,
          obj_factura: objFactura,
        };

      const data =
        id_factura == 0
          ? await Fx.post(url, params, "POST", Fx.callback)
          : await Fx.put(url, params, Fx.callback);

      resolveSaveData(data);
    }

    // proceso funciones asíncronas
    function resolveLoadData(dataFactura, dataEmpresa) {
      loading.value = false;

      if (dataFactura.status == "ok") {
        let objFactura =
          Array.isArray(dataFactura.data) && dataFactura.data.length == 1
            ? dataFactura.data[0]
            : dataFactura.data;

        if (objFactura == null) {
          return true;
        }

        if (
          objFactura.id_provincia == undefined ||
          objFactura.id_provincia == 0
        ) {
          if (objFactura.cp != "") {
            objFactura.id_provincia = id_provincia_cp(objFactura.cp);
            objFactura.provincia = provincia_txt(objFactura.id_provincia);
          }
        }

        if (
          objFactura.id_poblacion == undefined ||
          objFactura.id_poblacion == "0"
        ) {
          objFactura.id_poblacion = 0;
          objFactura.poblacion = "";
        }

        id_contacto.value = objFactura.id_contacto;

        if (Fx.is_array(objFactura.arr_conceptos)) {
          objFactura.arr_conceptos.map((objConcepto) => {
            objConcepto.base_imponible = objConcepto.importe;

            if (id_factura == 0) {
              objConcepto.iva_perc =
                dataEmpresa.data[0].iva_asumido_perc_clientes || 21;
            }

            let arr_numerico = [
              "base_imponible",
              "importe",
              "iva_perc",
              "total_iva",
            ];

            arr_numerico.map(
              (campo) =>
                (objConcepto[campo] = Fx.twoDecimals(objConcepto[campo]))
            );
          });
        }

        objFactura.total_irpf = Fx.twoDecimals(objFactura.total_irpf);
        objFactura.total_iva = Fx.twoDecimals(objFactura.total_iva);
        objFactura.total_conceptos = Fx.removeEuro(objFactura.total_conceptos);
        objFactura.total_factura = Fx.removeEuro(objFactura.total_factura);

        resolveFactura(objFactura);

        let objEmpresa =
          Array.isArray(dataEmpresa.data) && dataEmpresa.data.length == 1
            ? dataEmpresa.data[0]
            : dataEmpresa.data;

        resolveEmpresa(objEmpresa);
      } else {
        // setError(true, dataFactura.error);
      }
    }

    function resolveFactura(obj) {
      actualizarFormDataFactura(formData, obj);
    }

    function resolveEmpresa(objEmpresa) {
      if (objEmpresa != undefined) {
        if (objEmpresa.nif == "" || objEmpresa.nif == null) {
          router.push("/mis-datos");
          return true;
        }

        formData.empresa = objEmpresa.nombre;
        formData.nif_empresa = objEmpresa.nif;
        formData.direccion_empresa = objEmpresa.direccion;
        formData.cp_empresa = objEmpresa.cp;
        formData.id_poblacion_empresa = id_poblacion(objEmpresa.poblacion);
        formData.id_provincia_empresa = id_provincia(objEmpresa.provincia);
      }
    }

    function resolveSaveData(data) {
      saveButtonLoading.value = false;
      dataSaved.value = true;
      dataSavedStatus.value = data.status;
      dataSavedText.value =
        data.error != undefined
          ? data.error.replace("<br>", "")
          : "Datos guardados con éxito";

      if (!data.data) {
        return true;
      }

      id_factura = data.data.id_factura;

      formData.id_factura = id_factura;

      router.push(id_factura);
    }

    function downloadInvoice(data) {
      let url = Settings.BASE_API.replace("/v2", "");

      url += "pdf_facturas/pdf2.php?";

      url +=
        data.id_factura > 0
          ? "id_factura=" + data.id_factura
          : "datos_factura=" + encodeURIComponent(JSON.stringify(data));

      url += "&hash=" + store.state.token;

      window.open(url, "_blank");
    }

    // funciones auxiliares
    function setError(value, error = "Error desconocido.") {
      serverError.value = value;
      serverErrorTxt.value = error;
    }

    onMounted(() => {
      loadData();
      Fx.setFocus("num_factura");
    });

    watch(
      () => route.params,
      () => {
        id_factura = parseInt(window.location.href.split("/").pop());
        loadData();
      }
    );

    return {
      formData,
      saveFormData,
      downloadInvoice,
      loading,
      serverError,
      serverErrorTxt,
      saveButtonLoading,
      dataSaved,
      dataSavedStatus,
      dataSavedText,
      modo,
      id_contacto,
    };
  },
};
</script>

<style scoped></style>
