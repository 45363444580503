// prettier-ignore

const arrProvincias = [
  { label: "---", value: 0 },
  { label: "Álava", value: 1 },
  { label: "Albacete", value: 2 },
  { label: "Alicante", value: 3 },
  { label: "Almería", value: 4 },
  { label: "Ávila", value: 5 },
  { label: "Badajoz", value: 6 },
  { label: "Baleares", value: 7 },
  { label: "Barcelona", value: 8 },
  { label: "Burgos", value: 9 },
  { label: "Cáceres", value: 10 },
  { label: "Cádiz", value: 11 },
  { label: "Castellón", value: 12 },
  { label: "Ciudad Real", value: 13 },
  { label: "Córdoba", value: 14 },
  { label: "La Coruña", value: 15 },
  { label: "Cuenca", value: 16 },
  { label: "Gerona/Girona", value: 17 },
  { label: "Granada", value: 18 },
  { label: "Guadalajara", value: 19 },
  { label: "Guipúzcoa", value: 20 },
  { label: "Huelva", value: 21 },
  { label: "Huesca", value: 22 },
  { label: "Jaén", value: 23 },
  { label: "León", value: 24 },
  { label: "Lérida/Lleida", value: 25 },
  { label: "La Rioja", value: 26 },
  { label: "Lugo", value: 27 },
  { label: "Madrid", value: 28 },
  { label: "Málaga", value: 29 },
  { label: "Murcia", value: 30 },
  { label: "Navarra", value: 31 },
  { label: "Orense", value: 32 },
  { label: "Asturias", value: 33 },
  { label: "Palencia", value: 34 },
  { label: "Las Palmas", value: 35 },
  { label: "Pontevedra", value: 36 },
  { label: "Salamanca", value: 37 },
  { label: "Santa Cruz de Tenerife", value: 38 },
  { label: "Cantabria", value: 39 },
  { label: "Segovia", value: 40 },
  { label: "Sevilla", value: 41 },
  { label: "Soria", value: 42 },
  { label: "Tarragona", value: 43 },
  { label: "Teruel", value: 44 },
  { label: "Toledo", value: 45 },
  { label: "Valencia", value: 46 },
  { label: "Valladolid", value: 47 },
  { label: "Vizcaya", value: 48 },
  { label: "Zamora", value: 49 },
  { label: "Zaragoza", value: 50 },
  { label: "Ceuta", value: 51 },
  { label: "Melilla", value: 52 },
];

export { arrProvincias };
