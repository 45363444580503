<template>
  <div class="mensaje container">
    <div class="row">
      <div class="col-12">
        <img
          v-if="image != ''"
          alt="Welcome"
          :src="getImgUrl()"
          class="img_mensaje"
        />
      </div>
    </div>

    <h1 v-if="message != ''">{{ msg }}</h1>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Mensaje",

  props: {
    msg: String,
    image: String,
  },

  setup(props) {
    let getImgUrl = function () {
      if (props.image == "") {
        return "";
      }

      return require("../assets/img/" + props.image);
    };

    return {
      getImgUrl,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.mensaje h1 {
  margin-top: 30px;
}

img.img_mensaje {
  max-height: 300px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
