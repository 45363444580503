<template>
  <div class="main">
    <section-header
      title="Mis facturas"
      image="facturacion.png"
      link_to="/"
      link_text="Ir a inicio"
    />

    <div class="container view_container">
      <div class="col-12 text-end mb-4">
        <router-link to="/factura/0">
          <SimpleButton
            text="Añadir factura"
            icon="plus-circle"
            bsClass="btn-primary btn-sm"
          />
        </router-link>
      </div>

      <CRUDTable
        :url="url"
        :params="params"
        :columns="columns"
        :dataMapFx="dataMapFx"
      />
    </div>
  </div>
</template>

<script>
// import { defineComponent } from 'vue';
import { useStore } from "vuex";

import Fx from "@/lib/functions";
import Settings from "@/settings";
import { dateSpan, tableBtn } from "@/lib/datatables-fx";

import SectionHeader from "@/components/SectionHeader.vue";
import CRUDTable from "@/components/CRUDTable.vue";
import SimpleButton from "@/components/SimpleButton.vue";

export default {
  name: "Facturas",

  components: {
    SectionHeader,
    CRUDTable,
    SimpleButton,
  },

  setup() {
    const store = useStore();

    const url = Settings.BASE_API + "facturas/",
      params = {
        hash: store.state.token,
        accion: "consultar",
        tipo_factura: "factura",
        id_empresa: store.state.id_empresa,
      };

    const columns = [
      { title: "Id." },
      { title: "Num. Factura" },
      { title: "Cliente" },
      {
        title: "Pte.",
        type: "numeric-comma",
        class: "text-end",
      },
      { title: "F. factura" },

      {
        data: null,
        title: "Acciones",
        class: "acciones",

        render: function (elem) {
          let id_factura = elem[0],
            href = process.env.VUE_APP_BASE_APP + "factura/" + id_factura;

          let btnEditar = tableBtn({
            href: href,
            icon: "pen",
            label: "Editar",
            btnClass: "btn-primary",
          });

          return btnEditar;
        },
      },
    ];

    function dataMapFx(item) {
      let arr_campos = ["id_factura", "num_factura", "nombre"],
        arr_aux = [];

      arr_aux = arr_campos.map(function (campo) {
        return item[campo];
      });

      arr_aux.push(Fx.euros(item.total_factura));
      arr_aux.push(dateSpan(item.f_factura));

      return arr_aux;
    }

    return {
      url,
      params,
      columns,
      dataMapFx,
    };
  },
};
</script>
